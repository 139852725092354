import React, { useRef } from "react";
import Navigation from "../components_tailwindcss/Navigation";
import "../assets/css/bg-animation.scss";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchSingleProfileRequested } from "../store/actions";
import Loader from "../common/loader";
import EmptyState from "../common/emptyState";
import ProfileDetails from "../components_tailwindcss/Reels/RemotionProfileReel";
import ReelsPageHeader from "../components_tailwindcss/Reels/ReelsPageHeader";
import { useParams } from "react-router-dom";

export default function SingleProfile() {
  const { id } = useParams();
  const loopRef = useRef(null);
  const dispatch = useDispatch();
  const { currentProfile, isSingleProfileLoading } = useSelector(
    (state) => state.profile
  );

  useEffect(() => {
    if (id) {
      dispatch(fetchSingleProfileRequested(id));
    }
  }, [id, dispatch]);

  return (
    <>
      <div className="bg-animation flex-col bg-white w-full w-full h-screen h-max">
        <ReelsPageHeader type={"all"} />

        {isSingleProfileLoading ? (
          <div
            style={{ height: "calc(100vh - 140px)" }}
            className={`flex flex-1 overflow-y-scroll py-3 flex-col justify-center items-center gap-y-5`}
          >
            <Loader color="#e8649a" />
          </div>
        ) : currentProfile ? (
          <>
            <div
              ref={loopRef}
              style={{ height: "calc(100vh - 140px)" }}
              id="Reels"
              className={`flex flex-1 overflow-y-scroll flex-col gap-y-5`}
            >
              <ProfileDetails
                profile={currentProfile}
                profileIndex={0}
                advertisements={[]}
                active={0}
                isRatingDisabled={true}
              />
            </div>
          </>
        ) : (
          <>
            <div
              className="flex items-center"
              style={{ height: "calc(100vh - 140px)" }}
            >
              <EmptyState label={"Stack is empty ☹️"} />
            </div>
          </>
        )}
        <Navigation />
      </div>
    </>
  );
}
