import {
    FETCH_APPEVENT_REQUESTED,
    FETCH_APPEVENT_SUCCESS,
    FETCH_APPEVENT_FAILURE,
    FETCH_APPEVENT_DETAILS_REQUESTED,
    FETCH_APPEVENT_DETAILS_SUCCESS,
    FETCH_APPEVENT_DETAILS_FAILURE,
    FETCH_APPEVENTS_REQUESTED,
    FETCH_APPEVENTS_SUCCESS,
    FETCH_APPEVENTS_FAILURE,
    SUBSCRIBE_APPEVENT_REQUESTED,
    SUBSCRIBE_APPEVENT_SUCCESS,
    SUBSCRIBE_APPEVENT_FAILURE,
    CLEAR_APPEVENTS,
    SET_APP_EVENT_MODAL
} from "../actionTypes";

export const updateAppeventModal = (data) => {
    return {
        type : SET_APP_EVENT_MODAL,
        payload : data
    }
}

export const fetchAppeventRequested = (data) => {
    return {
        type: FETCH_APPEVENT_REQUESTED,
        payload: data,
    };
}

export const fetchAppeventSuccess = (data) => {
    return {
        type: FETCH_APPEVENT_SUCCESS,
        payload: data,
    };
};

export const fetchAppeventFailure = (data) => {
    return {
        type: FETCH_APPEVENT_FAILURE,
        payload: data,
    };
};

export const fetchAppeventDetailsRequested = (data) => {
    return {
        type: FETCH_APPEVENT_DETAILS_REQUESTED,
        payload: data,
    };
}

export const fetchAppeventDetailsSuccess = (data) => {
    return {
        type: FETCH_APPEVENT_DETAILS_SUCCESS,
        payload: data,
    };
};

export const fetchAppeventDetailsFailure = (data) => {
    return {
        type: FETCH_APPEVENT_DETAILS_FAILURE,
        payload: data,
    };
};

export const fetchAppeventsRequested = (data) => {
    return {
        type: FETCH_APPEVENTS_REQUESTED,
        payload: data,
    };
}

export const fetchAppeventsSuccess = (data) => {
    return {
        type: FETCH_APPEVENTS_SUCCESS,
        payload: data,
    };
};

export const fetchAppeventsFailure = (data) => {
    return {
        type: FETCH_APPEVENTS_FAILURE,
        payload: data,
    };
};

export const subscribeAppeventRequested = (data) => {
    return {
        type: SUBSCRIBE_APPEVENT_REQUESTED,
        payload: data,
    };
}

export const subscribeAppeventSuccess = (data) => {
    return {
        type: SUBSCRIBE_APPEVENT_SUCCESS,
        payload: data,
    };
};

export const subscribeAppeventFailure = (data) => {
    return {
        type: SUBSCRIBE_APPEVENT_FAILURE,
        payload: data,
    };
};

export const clearAppevents = () => {
    return {
        type : CLEAR_APPEVENTS,
        payload : {}
    }
}