import React from "react";
import "../../assets/css/details.scss";
import heightIcon from "../../assets/images/icons/random-profiles/height.png"
import drinkingIcon from "../../assets/images/icons/random-profiles/drinking.png"
import smokingIcon from "../../assets/images/icons/random-profiles/smoking.png"
import whatToFindIcon from "../../assets/images/icons/random-profiles/what_to_find.png"
import maleIcon from "../../assets/images/icons/random-profiles/male.png"
import femaleIcon from "../../assets/images/icons/random-profiles/female.png"
import nonBinaryIcon from "../../assets/images/icons/random-profiles/non-binary.png"
import Tag from "../AllProfiles/Tag";
import INTERESTS from "../../assets/data/interests.json"
import { useState } from "react";
import Modal from "../General/Modals/Modal";
import ViewPhotoModal from "../General/Modals/ViewPhotoModal";
import { useSearchParams } from "react-router-dom";

const Details = ({ profile, setReportModalActive }) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [currentPicture, setCurrentPicture] = useState(null)

  const genderDetails = (gender) => {
    const genderIconMapping = {
      "M": { icon: maleIcon, title: "Male" },
      "F": { icon: femaleIcon, title: "Female" },
      "NB": { icon: nonBinaryIcon, title: "Non-Binary" }
    }
    return genderIconMapping[gender]
  }

  const whatToFindDetails = (data) => {
    const mapping = {
      "R": "Relationship",
      "C": "Casual",
      "NS": "Not Sure",
      "F": "Friendship",
      "CON": "Connections"
    }
    return mapping[data]
  }

  const drinkSmokeDetails = (data) => {
    const mapping = {
      "N": "Never",
      "S": "Socially",
      "O": "Often",
    }
    return mapping[data]
  }

  return (
    <div className="details">
      <ViewPhotoModal show={currentPicture} onHide={() => setCurrentPicture(null)}>
        <div className="photo-container">
          <img src={currentPicture} />
        </div>
      </ViewPhotoModal>

      {
        (profile.bio && profile.bio.length) ? <div className="detail-row about">
          <div className="title">About</div>
          <div className="subtitle">
            {profile.bio}
          </div>
        </div> : null
      }

      {!profile.is_advertisement ? <div className="detail-row my-basis">
        <div className="title">My Basics</div>
        <div className="tags">
          {profile.height && (
            <Tag icon={heightIcon} title={`${profile.height}cm`} />
          )}

          {profile.is_habit_drink && (
            <Tag icon={drinkingIcon} title={`${drinkSmokeDetails(profile.is_habit_drink)}`} />
          )}

          {profile.is_habit_smoke && (
            <Tag icon={smokingIcon} title={`${drinkSmokeDetails(profile.is_habit_smoke)}`} />
          )}

          {profile.what_to_find && (
            <Tag icon={whatToFindIcon} title={`${whatToFindDetails(profile.what_to_find)}`} />
          )}

          {profile.gender && (
            <Tag icon={genderDetails(profile.gender).icon} title={genderDetails(profile.gender).title} />
          )}


        </div>
      </div> : null}

      {
        !profile.is_advertisement && profile.interests && (
          <div className="detail-row my-interest">
            <div className="title">My Interest</div>
            <div className="tags">
              {INTERESTS.filter(interest => profile.interests.includes(interest.name)).map((interest, interestIndex) => {
                return (<Tag key={`${profile._id}-interest-${interestIndex}`} icon={interest.url} title={interest.name} />)
              })}
            </div>
          </div>
        )
      }

      {
        !profile.is_advertisement ? <div className="detail-row gallery">
          <div className="text">
            <div className="title">Gallery</div>
          </div>
          <div className="images">
            {
              profile.photos.map((pic, picIndex) => {
                return (<div onClick={() => setCurrentPicture(`${process.env.REACT_APP_FILE_BASE_URL}${pic}`)} key={`${profile._id}-pic${picIndex}`} style={{ backgroundImage: `url(${process.env.REACT_APP_FILE_BASE_URL}${pic})` }} className="img"></div>)
              })
            }
          </div>
        </div> : null
      }


      {
        (searchParams.get("hideReportProfile") || profile.is_advertisement) ? null : <div className="detail-row report-row">
          <span className="report-tag" onClick={() => setReportModalActive(true)} >Report Profile</span>
        </div>
      }

    </div>
  );
};

export default Details;
