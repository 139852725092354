import React, { useRef, useState } from 'react'
import toast from 'react-hot-toast';
// import TinderCard from 'react-tinder-card'
import DotLoader from "../../components/General/Loaders/DotLoader"
import { CheckBadgeIcon } from '@heroicons/react/24/solid'
import like from "../../assets/images/icons/random-profiles/like-heart.svg";
import dislike from "../../assets/images/icons/random-profiles/dislike-cross.svg";
import star from "../../assets/images/icons/random-profiles/compliment-star.svg";
import educationIcon from "../../assets/images/education.png";
import ArrowRight from "../../assets/images/icons/random-profiles/arrow-right.png"
import Details from "./Details";
import { useDispatch, useSelector } from 'react-redux';
import { markAdvertisementActionRequested, updateProfileDecisionRequested, toggleUpgradePlanModal } from '../../store/actions';
import { showImage } from '../../utils/profile';
import Modal from "../General/Modals/Modal"
import { useNavigate, useSearchParams } from 'react-router-dom';
import Pricing from "../Pricing"
import { useMyRolesAndResponsibilities } from '../../react-query/profile/useProfile';

function ProfileDetails({ profile, isRedirect = false }) {
    // eslint-disable-next-line
    const [searchParams, setSearchParams] = useSearchParams()
    const dispatch = useDispatch()
    const [complimentModalActive, setComplimentModalActive] = useState(false)
    const [reportModalActive, setReportModalActive] = useState(false)
    const [reportReason, setReportReason] = useState("")
    
    const [compliment, setCompliment] = useState("")
    
    const {data : rolesAndResponsibilities} = useMyRolesAndResponsibilities()
    const { currentUpgradePlanModalType } = useSelector((state) => state.payment)
    // const { profileFilters } = useSelector(state => state.like)

    const currentIndexRef = useRef()

    const profileDecisionHandler = (direction) => {
        if (!currentIndexRef.current) return
        currentIndexRef.current.swipe(direction)
    }

    const complimentProfile = () => {
        profileDecisionHandler("right")
        setComplimentModalActive(false)
        setCompliment("")
    }

    const reportProfile = () => {
        profileDecisionHandler("left")
        setReportModalActive(false)
        setReportReason("")
    }

    const handleCompliment = () => {
        if (rolesAndResponsibilities?.gate?.superswipes) {
            return setComplimentModalActive(true)
        }

        // Else show toast error
        toast.error("Please upgrade for this feature")
    }

    const advertisementRedirectLink = () => {
        if (profile.is_advertisement && profile.advertisement.redirect_link) {
            return window.open(profile.advertisement.redirect_link)
        }
    }

    if (!profile) return <div style={{ display: "flex", justifyContent: "center", marginTop: "30vh" }}><DotLoader /></div>

    return (
        <>
            <Modal title={`Compliment ${profile.name}`} show={complimentModalActive} onHide={() => setComplimentModalActive(false)}>
                <div className="compliment-container">
                    <textarea onChange={(e) => setCompliment(e.target.value)} value={compliment} className="compliment-input" placeholder="Write your compliment here!"></textarea>
                    <button onClick={complimentProfile} className="btn-add-compliment" name="Send">Send</button>
                </div>
            </Modal>

            <Modal title={`Get Idyll ${currentUpgradePlanModalType}`} headerStyles={{ color: "#ffffff" }} styles={{ backgroundColor: (currentUpgradePlanModalType === "PRO" ? "#5bdaa6" : "#5b6bda") }} show={currentUpgradePlanModalType} onHide={() => dispatch(toggleUpgradePlanModal(false))}>
                <div className="compliment-container" style={{ marginLeft: "-20px", marginRight: "-20px", marginBottom: "-21px" }}>
                    <Pricing plan={currentUpgradePlanModalType} />
                </div>
            </Modal>

            <Modal title={`Report This Profile`} show={reportModalActive} onHide={() => setReportModalActive(false)}>
                <div className="compliment-container">
                    <textarea onChange={(e) => setReportReason(e.target.value)} value={reportReason} className="compliment-input" placeholder="Write your reason here!"></textarea>
                    <button onClick={reportProfile} className="btn-add-compliment" name="Send">Report</button>
                </div>
            </Modal>

            <div className="main">
                {/* <TinderCard className="idyll-swipe-card" ref={currentIndexRef} flickOnSwipe={true} swipeRequirementType="velocity" onSwipe={onSwipe} preventSwipe={['up', 'down']}>
                    <div className="cards">
                        <div className={`card ${(profile.is_advertisement) ? "is-advertisement" : ""}`} style={{ backgroundImage: `url(${showImage(profile.photos[0])})` }}>
                            <div className={`detail ${(profile.is_advertisement) ? "is-advertisement" : ""}`}>
                                <div className="education">
                                    {profile.is_advertisement ? null : <img style={{ marginRight: 8 }} src={educationIcon} alt="" />}
                                    <div className="text">{profile.college}</div>
                                </div>
                                {
                                    (profile.final_name && profile.final_name.length) ? <div className="name">{profile.final_name}{!profile.is_advertisement ? `, ${profile.age}` : ""} {profile.is_verified ? <CheckBadgeIcon className='icon-md icon-yellow' /> : null}</div> : null
                                }

                            </div>
                        </div>
                    </div>
                </TinderCard> */}
            </div>

            {
                profile.is_advertisement ? <div onClick={advertisementRedirectLink} className='advertisement-cta-button' style={{ backgroundColor: profile.advertisement.cta_bg_color }}>
                    <span>{profile.advertisement.cta_text}</span>
                    <img height={14} src={ArrowRight} alt="advertisement-cta-button-arrow-right" />
                </div> : null
            }


            {
                !searchParams.get("disableActions") ? (
                    <div className="buttons">
                        <div className="dislike btn" onClick={() => profileDecisionHandler("left")}>
                            <img src={dislike} alt="" />
                        </div>
                        <div className="like btn" onClick={() => profileDecisionHandler("right")}>
                            <img src={like} alt="" />
                        </div>
                        {
                            !profile.is_advertisement ? <div className="star btn" onClick={() => handleCompliment()}>
                                <img src={star} alt="" />
                            </div> : null
                        }

                    </div>

                ) : (searchParams.get("showDislike") ? (
                    <div className="buttons">
                        <div className="dislike btn" onClick={() => profileDecisionHandler("left")}>
                            <img src={dislike} alt="" />
                        </div>
                    </div>
                ) : null)
            }

            <Details profile={profile} setReportModalActive={(e) => setReportModalActive(e)} />
        </>
    )
}

export default ProfileDetails