import {
    TRACK_EVENT_REQUESTED,
    TRACK_EVENT_SUCCESS,
    TRACK_EVENT_FAILURE,
} from "../actionTypes";

export const trackEventRequested = (data) => {
    return {
        type: TRACK_EVENT_REQUESTED,
        payload: data,
    };
}

export const trackEventSuccess = (data) => {
    return {
        type: TRACK_EVENT_SUCCESS,
        payload: data,
    };
};

export const trackEventFailure = (data) => {
    return {
        type: TRACK_EVENT_FAILURE,
        payload: data,
    };
};