import React from 'react'
import { ReactComponent as Star } from "../assets/img/icons/star.svg";

export default function RangeBar({ step, height }) {
    return (
        <div className={`relative shadow-md w-full h-[${height}px]  flex rounded-[29px] bg-[#fff] `}>
            <div className="flex items-center absolute w-full h-full">
                <div style={{ width: step + '%' }} className="flex items-center h-full text-white text-[10px] leading-[22px] text-center shadow-none rounded-[29px] whitespace-nowrap  justify-center bg-black ">
                    {step}%
                </div>
                {/* {step === 6 ?
                    <Star color={"#CE376D"} className={`h-[51px] -ml-5 -mr-3 -mt-2 rounded-full  shadow-none  text-center whitespace-nowrap  justify-center  `} />
                    :
                    <div style={{ width: radius + "px", height: radius + "px" }} className={`-ml-2 rounded-full  bg-gradient-to-r from-[#fc6849] from-15% via-[#D1396B] via-30% to-[#95095A] to-90%   `}></div>
                } */}
            </div>




        </div>
    )
}
