import React from 'react';
import { useLocation } from 'react-router-dom';

const fadeAnimation = `
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

const RouteTransitionWrapper = ({ children }) => {
  const location = useLocation();

  return (
    <>
      <style>{fadeAnimation}</style>
      <div
        key={location.pathname}
        className='w-full h-screen'
        style={{
          animation: 'fadeIn 200ms ease-in-out forwards',
          position: 'relative',
        }}
      >
        {children}
      </div>
    </>
  );
};

export default RouteTransitionWrapper;