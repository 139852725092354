import {
  FETCH_RANDOM_PROFILES_REQUESTED,
  FETCH_RANDOM_PROFILES_SUCCESS,
  FETCH_RANDOM_PROFILES_FAILURE,
  UPDATE_PROFILE_DECISION_REQUESTED,
  UPDATE_PROFILE_DECISION_SUCCESS,
  UPDATE_PROFILE_DECISION_FAILURE,
  FETCH_MATCHED_USER_DETAILS_SUCCESS,
  UPDATE_PROFILE_FILTERS,
  FETCH_MY_BLIND_MATCH_REQUESTED,
  FETCH_MY_BLIND_MATCH_SUCCESS,
  FETCH_MY_BLIND_MATCH_FAILURE,
  UPDATE_STREAK_MODAL_ACTIVE,
  UPDATE_CACHED_PROFILE_IMAGES,
  TOGGLE_REPORT_PROFILE_MODAL,
  UPDATE_CURRENT_PROFILE_INDEX,
  TOGGLE_MUTE_MUSIC,
  TOGGLE_SHOW_ERROR_SHOW_NEXT_PROFILE_MODAL
} from "../actionTypes";

const initialState = {
  isLoading: false,
  error: null,
  profiles: [],
  currentIndex: 0,
  matchedUserDetails: null,
  currentMatchDetails : null,
  profileFilters : {},
  isFetchMyBlindMatchLoading : false,
  blindMatch : null,
  streakModalActive : false,
  cachedProfileImages : {},
  reportProfileModalActiveId : null,
  isDecisionLoading : false,
  isMusicMuted : false,
  showErrorShowNextProfileModal : false,
  userRatings : {}
};

export default function likeReducer(state = initialState, action) {
  const { type } = action;

  switch (type) {

    case UPDATE_PROFILE_FILTERS: {
      return {
        ...state,
        profileFilters : action.payload
      }
    }

    case UPDATE_CURRENT_PROFILE_INDEX: {
      return {
        ...state,
        currentIndex : action.payload
      }
    }

    case TOGGLE_REPORT_PROFILE_MODAL : {
      return {
        ...state,
        reportProfileModalActiveId : action.payload
      }
    }

    case FETCH_RANDOM_PROFILES_REQUESTED: {
      return {
        ...state,
        isLoading: action.payload.isLoading,
      };
    }

    case FETCH_RANDOM_PROFILES_SUCCESS: {
      const oldProfileIds = state.profiles.map(p => p._id)
      const newProfiles = action.payload.profiles.filter(p => !oldProfileIds.includes(p._id))
      return {
        ...state,
        isLoading: false,
        profiles: [...state.profiles, ...newProfiles],
      };
    }

    case FETCH_RANDOM_PROFILES_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    }

    case UPDATE_PROFILE_DECISION_REQUESTED: {
      return {
        ...state,
        isDecisionLoading: true,
        userRatings : {
          ...state.userRatings, [action.payload.data.user] : action.payload.data.userRating
        }
      };
    }

    case UPDATE_PROFILE_DECISION_SUCCESS: {
      let newState = {
        ...state,
        isDecisionLoading: false,
        streakModalActive : action.payload.showStreak,
      }

      if(state.reportProfileModalActiveId){
        newState["reportProfileModalActiveId"] = null
      }
      return {
        ...newState
      };
    }

    case UPDATE_PROFILE_DECISION_FAILURE: {
      return {
        ...state,
        isDecisionLoading: false,
        error: action.payload,
      };
    }

    case FETCH_MATCHED_USER_DETAILS_SUCCESS: {
      return {
        ...state,
        matchedUserDetails: action.payload.user,
        currentMatchDetails : action.payload.match_details
      };
    }

    case FETCH_MY_BLIND_MATCH_REQUESTED : {
      return {
        ...state,
        isFetchMyBlindMatchLoading : true,
        blindMatch : null
      }
    }

    case FETCH_MY_BLIND_MATCH_SUCCESS : {
      return {
        ...state,
        isFetchMyBlindMatchLoading : false,
        blindMatch : action.payload._id
      }
    }

    case FETCH_MY_BLIND_MATCH_FAILURE : {
      return {
        ...state,
        isFetchMyBlindMatchLoading : false,
      }
    }

    case UPDATE_STREAK_MODAL_ACTIVE : {
      return {
        ...state,
        streakModalActive : action.payload
      }
    }

    case UPDATE_CACHED_PROFILE_IMAGES : {
      return {
        ...state,
        cachedProfileImages : { ...state.cachedProfileImages, ...action.payload }
      }
    }

    case TOGGLE_MUTE_MUSIC : {
      return {
        ...state,
        isMusicMuted : action.payload
      }
    }

    case TOGGLE_SHOW_ERROR_SHOW_NEXT_PROFILE_MODAL: {
      return {
        ...state,
        showErrorShowNextProfileModal : action.payload
      }
    }

    default:
      return state;
  }
}
