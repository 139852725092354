import React from "react";
import doubleTick from "../../assets/images/icons/chat/double-tick.svg";
import singleTick from "../../assets/images/icons/chat/tick.svg";

const SendMsg = (props) => {
  return (
    <div className="w-[85%] rounded-l-xl rounded-tr-xl px-3 py-2 ml-auto text-sm text-right bg-white">
      {props.msg}
      <br />
      <div className="mt-1 flex items-center justify-end">
        <span className="text-[9px] font-light">{props.time}</span>
        <span>
          {props?.is_pending ? (
            <ClockIcon className="h-3 w-3 ml-1" />
          ) : (
            <img
              className="h-3 ml-1"
              src={props.read ? doubleTick : singleTick}
              alt=""
            />
          )}
        </span>
      </div>
    </div>
  );
};

const ClockIcon = (props) => (
  <svg
    width="800px"
    height="800px"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12 12V7M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z"
      stroke="#000000"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SendMsg;
