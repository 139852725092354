import React, { useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import "../../../assets/css/select-college.scss";
import TopHeader from "../../../components/AllProfiles/TopHeader";
import ProfileProgressBar from "../../../components/ProfileScreens/ProfileProgressBar";
import Button from "../../../components/Buttons/Button";
import bottomCurve from "../../../assets/images/bottom-curve.png";
import { PROFILE_PAGES, profileSetupNextPage } from "../../../utils/profile";
// Actions
import { fetchCollegesRequested } from "../../../store/actions";
import { useUpdateMyProfile, useMyProfile } from "../../../react-query/profile/useProfile";

const SelectCollege = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { type } = useParams();
    const dispatch = useDispatch();
    const { colleges } = useSelector(state => state.college)
    const {data : profile} = useMyProfile({ enabled : false })
    const [val, setVal] = useState((profile && profile[type]) ? profile[type] : PROFILE_PAGES[type].initial_value);
    const [error, setError] = useState(false);
    const [showMenu, setShowMenu] = useState(false);

    const collegeSearchHandler = (name) => {
        dispatch(fetchCollegesRequested({ name }))
    }

    const PROFILE_PAGES_SETUP_KEYS = (Object.keys(PROFILE_PAGES).filter(k => PROFILE_PAGES[k].isSetupScreen))
    const currentPageIndex = PROFILE_PAGES_SETUP_KEYS.findIndex(k => k === type)
    const item = PROFILE_PAGES[type];

    const nextPage = () => {
        return profileSetupNextPage(type, profile, location.state)
    };

    const onUpdateMyProfileSuccess = () => {
        navigate(nextPage())
    }

    const {isLoading : isProfileUpdateLoading, mutate : mutateUpdateProfile} = useUpdateMyProfile({ onSuccess : onUpdateMyProfileSuccess })

    const handleCollegeInput = (inpVal) => {
        if (!showMenu) {
            setShowMenu(true)
        }
        setVal(inpVal)
        collegeSearchHandler(inpVal)
    }

    const updateHandler = () => {
        if (item.required && !val) {
            setError(true);
            return;
        }

        mutateUpdateProfile({ [type]: val })
        setError(false);
    };

    const handleCollegeClick = (name) => {
        setVal(name)
        setShowMenu(false)
    }

    return (
        <div className="top-header-common select-college">
            {
                location.state !== "editprofile" ? <ProfileProgressBar currentPage={currentPageIndex + 1} totalPages={PROFILE_PAGES_SETUP_KEYS.length} /> : null
            }
            <div className="bg-image">
                <img src={bottomCurve} alt="" />
            </div>
            <div className="top">
                <TopHeader
                    icon={item.icon}
                    title={item.title}
                    subtitle={item.subtitle}
                />

                <div className="input">

                    <input
                        onChange={(e) => handleCollegeInput(e.target.value)}
                        value={val}
                        type="text"
                        placeholder={item.placeholder}
                        id="name"
                    />

                    {error && (
                        <div className="error" style={{ padding: "10px 0px" }}>
                            <span>
                                Please enter valid {type === "dob" ? "Date of Birth" : type}
                            </span>
                        </div>
                    )}
                </div>

                {(showMenu && colleges.length) ? <div className="college-list">
                    {
                        colleges.map((c, ci) => {
                            return (
                                <div onClick={() => handleCollegeClick(c.name)} className="college-row" key={`college-${ci}`}>{c.name}</div>
                            )
                        })
                    }
                </div> : null}
            </div>
            <div className="bottom">
                <Button
                    onClick={updateHandler}
                    isLoading={isProfileUpdateLoading}
                    name={(location.state === "editprofile") ? "Update" : "Next"}
                    type="fill"
                    padding="14px 29px"
                />
                {!item.required && (
                    <Button
                        onClick={() => navigate(nextPage())}
                        name="Skip"
                        type="outline"
                        padding="14px 29px"
                    />
                )}
            </div>
        </div>
    );
};

export default SelectCollege;
