import React, { useEffect, useState } from "react";
import Navigation from "../components_tailwindcss/Navigation";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useMyProfile } from "../react-query/profile/useProfile";
// Plugins
import { Purchases, LOG_LEVEL } from "@revenuecat/purchases-capacitor";
import toast from "react-hot-toast";

if (process.env.REACT_APP_DEVICE_TYPE === "MOBILE") {
  Purchases.setLogLevel({ level: LOG_LEVEL.DEBUG }); // Enable to get debug logs in dev mode
  Purchases.configure({ apiKey: process.env.REACT_APP_REVENUE_CAT_API_KEY });
}

const bgColors = ["#12C2E9", "#D1396B", "#222222"];
const planMapping = {
  PRO: {
    bgColor: "#950F9A",
    title: "IDYLL PRO",
    packages: ["Pro Weekly", "Pro Monthly", "Pro 3 Months"],
    features: [
      {
        title: "10x chances of getting a match",
        subtitle: "Swipe 25 profiles a day instead of 15",
      },
      {
        title: "Check who rated your profile",
        subtitle: "Now easily see who likes your profile in a second",
      },
      {
        title: "Chat with people who rated you back",
        subtitle: "Extend your match for 3 more days",
      },
      // {
      //   title: "Compliments to stand out from others",
      //   subtitle: "Woohoo, 1 compliment per day",
      // },
    ],
  },
  ACE: {
    bgColor: "#00C9FF",

    title: "Get Idyll Ace",
    packages: ["Ace Weekly", "Ace Monthly", "Ace 3 Months"],
    features: [
      {
        title: "Auto Profile Boosts (new)",
        subtitle: "More visibility to ensure upto 10x match chances",
      },
      {
        title: "Swipe more profiles in a day",
        subtitle: "Swipe 49 profiles a day instead of 15",
      },
      {
        title: "See who likes you",
        subtitle: "Now easily see who likes your profile in a second",
      },
      {
        title: "Match Extends",
        subtitle: "Extend your match for 7 more days",
      },
      {
        title: "Compliments to stand out from others",
        subtitle: "Woohoo, 3 compliments per day",
      },
    ],
  },
};
export default function Plans() {
  const navigate = useNavigate();
  const { planType } = useParams();
  const { paymentLink, offerings, currentUpgradePlanModalType } = useSelector(
    (state) => state.payment
  );

  const { isFreePricing } = useSelector((state) => state.general);

  const planDetails = planMapping[planType];

  const { isLoading: isMyProfileLoading, data: profile } = useMyProfile();

  const getDiscountKey = () => {
    if (!profile) return null;

    if (profile["is_forty_percent_discount_allowed"]) {
      return "Default 40%";
    } else if (profile["is_twentyfive_percent_discount_allowed"]) {
      return "Default 25%";
    } else if (profile["is_fifteen_percent_discount_allowed"]) {
      return "Default 15%";
    }
    return null;
  };
  let allPackages = [],
    discountedPackages = [];

  if (offerings) {
    allPackages =
      offerings.current.availablePackages.filter((p) =>
        planDetails.packages.includes(p.identifier)
      ) || [];
    allPackages = allPackages.map((p) => {
      return { ...p, discountedPackage: null };
    });
    if (planType === "ACE") {
      const currentDiscountKey = getDiscountKey();

      if (currentDiscountKey) {
        discountedPackages =
          offerings.all[currentDiscountKey].availablePackages;
        allPackages = allPackages.map((p) => {
          const discountedPackage = discountedPackages.find(
            (dp) => dp.identifier === p.identifier
          );
          const newP = { ...p, discountedPackage };
          return newP;
        });
      }
    }
  }

  const purchaseFromPlay = async (p) => {
    try {
      const data = await Purchases.purchasePackage({
        aPackage: p,
      });

      console.log({ data });
      const purchaserInfo = data.customerInfo;
      console.log({ purchaserInfo });

      if (purchaserInfo.activeSubscriptions.includes(p.identifier)) {
        // If successfull, then call my responsibilities api again
        toast.success("Subscribed Successfully");
        navigate("/allprofiles");
      }

      return purchaserInfo;
    } catch (err) {
      console.error("listenBuy error", err);
    }

    return null;
  };

  const handlePayment = (planIndex) => {
    if(isFreePricing){
      return toast.error("You have already received a complementary subscription from us")
    }
    
    const p = allPackages[planIndex].discountedPackage
      ? allPackages[planIndex].discountedPackage
      : allPackages[planIndex];
    purchaseFromPlay(p);
  };

  const discountString = (planRow) => {
    return (
      100 -
      Math.round(
        (planRow.discountedPackage.product.price / planRow.product.price) * 100
      )
    );
  };

  useEffect(() => {
    if (paymentLink) {
      window.open(paymentLink);
    }
  }, [paymentLink]);

  console.log(allPackages);

  return (
    <div className="flex flex-col bg-white w-full w-full h-screen h-max">
      {/* <Arrow onClick={() => navigate("/premium-plans")} className="cursor-pointer w-[11px] h-[11px] mt-10 ml-10" /> */}
      <div className="px-5 flex flex-col flex-1 items-center overflow-y-scroll">
        <div className="relative flex items-center justify-center mb-5 mt-[50px] ">
          <p className="p-2 bg-black font-semibold text-xl text-white rounded-lg text-[#950F9A]">
            Idyll Pro Subscription
          </p>
        </div>

        {isFreePricing ? (
          <p className="font-bold text-sm text-center text-[#666666] mb-2">
            Congratulations!! you got a free subscription from Idyll for sometime.
            Utilize it maximum.
            <br/>
            🥳🥳🥳🥳🥳🥳🥳🥳
          </p>
        ) : (
          <>
            <p className="font-bold text-sm text-center text-[#666666] mb-2">
              Premium se dar nhi lagta sahab, pyaar se lgta hai!
            </p>

            <p className="font-semibold text-xs text-center text-[#666666]">
              Your Love Life Deserves A VIP Pass! Pick your plan: Weekly,
              Monthly, or Quarterly
            </p>
          </>
        )}

        <swiper-container
          pagination="true"
          pagination-clickable="true"
          slides-per-view={1.3}
          space-between="5"
          centered-slides="true"
        >
          {allPackages.map((planRow, planRowIndex) => {
            return (
              <swiper-slide key={`pricing-details-${planType}-${planRowIndex}`}>
                <div
                  key={`${planType}-${planRowIndex}`}
                  style={{ background: bgColors[planRowIndex] }}
                  className={`flex mt-9 flex-col w-full h-[80%] rounded-[15px] leading-5 p-5`}
                >
                  <div className="flex gap-5 justify-between">
                    <p className="font-semibold text-sm text-white">
                      {planRow.identifier}
                    </p>
                    {/* <p className="text-sm text-white">
                      From{" "}
                      <span className="font-semibold text-sm text-white">
                        {planRow.product.priceString}
                      </span>
                    </p> */}
                  </div>

                  <div className="flex-1 flex flex-col justify-center gap-4 text-white">
                    <p className="text-[24px] leading-[30px] font-light">
                      Find Who Rated You
                    </p>
                    <p className="text-[24px] leading-[30px] font-light">
                      10x Profile Visibility
                    </p>
                    <p className="text-[24px] leading-[30px] font-light">
                      Chat With Anyone
                    </p>
                    <p className="text-[24px] leading-[30px] font-light">
                      Personalization{" "}
                      <span className="text-xs">(Coming Soon)</span>
                    </p>
                  </div>

                  <p className=" font-bold text-[14px] text-white pb-5 mt-2">
                    {planRow.discountedPackage
                      ? `${discountString(planRow)}% off`
                      : null}
                  </p>

                  <div className="w-full flex justify-center">
                    <div
                      onClick={() => handlePayment(planRowIndex)}
                      className="w-3/4 cursor-pointer flex items-center justify-center bg-white font-bold text-black  text-[8px]  rounded-[15px] py-1 "
                    >
                      <p className="text-[12px] mx-3">
                        Pay&nbsp;&nbsp;
                        <span>
                          {planRow.discountedPackage ? (
                            <strike>{planRow.product.priceString}</strike>
                          ) : (
                            planRow.product.priceString
                          )}
                        </span>
                        <span>
                          {planRow.discountedPackage
                            ? ` ${planRow.discountedPackage.product.priceString}`
                            : null}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </swiper-slide>
            );
          })}
        </swiper-container>
      </div>

      <Navigation />
    </div>
  );
}
