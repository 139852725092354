import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import OtpInput from "react-otp-input";
import logo from "../assets/img/Delivery/IDYLLCOLORED1.svg";
import HorizontalLoader from "../components/General/Loaders/HorizontalLoader";

// Plugins
import OneSignal from "onesignal-cordova-plugin";
import { Purchases, LOG_LEVEL } from "@revenuecat/purchases-capacitor";
import toast from "react-hot-toast";
import {
  useLoginUser,
  useVerifyOtpUser,
} from "../react-query/profile/useProfile";

if (process.env.REACT_APP_DEVICE_TYPE === "MOBILE") {
  Purchases.setLogLevel({ level: LOG_LEVEL.DEBUG }); // Enable to get debug logs in dev mode
  Purchases.configure({ apiKey: process.env.REACT_APP_REVENUE_CAT_API_KEY });
}

const Login = () => {
  const navigate = useNavigate();
  const [mobile, setMobile] = useState("");
  const [pageType, setPageType] = useState("auth");
  const [referral_code, setReferralCode] = useState("");
  const [otp, setOtp] = useState("");
  const [showReferral, setShowReferral] = useState(false);

  const handleLoginSuccess = () => {
    setPageType("otp");
  };

  const handleVerifyOtpSuccess = (data) => {
    for (let key in data) {
      localStorage.setItem(key, data[key]);
    }
    navigate("/loginhandler");
  };

  const { mutate: loginUserMutate, isLoading: isLoginLoading } = useLoginUser({
    onSuccess: handleLoginSuccess,
  });
  const { mutate: verifyOtpMutate, isLoading: isVerifyUserLoading } =
    useVerifyOtpUser({ onSuccess: handleVerifyOtpSuccess });

  const toggleReferral = () => {
    setShowReferral(() => {
      return !showReferral;
    });
  };

  const handleLogin = (isResendOTP = false) => {
    if (!mobile.length === 10) {
      return toast.error("Please enter exactly 10 digit mobile number");
    }

    if (process.env.REACT_APP_DEVICE_TYPE === "MOBILE") {
      OneSignal.login(`IDYLL${mobile}`);
      Purchases.logIn({ appUserID: mobile });
    }

    loginUserMutate({ mobile, referral_code });

    if (isResendOTP) {
      toast.success("OTP Resent Successfully");
    }
  };

  return (
    <div className="flex w-full flex-col items-center  bg_login w-full h-screen h-max">
      <div className=" relative w-[80%] mt-5 min-h-[97vh]">
        {pageType === "auth" ? (
          <>
            <div className="mt-[240px] flex w-full justify-center">
              <img src={logo} alt="logo" />
            </div>
            <div className="flex mt-5 mb-2 mx-auto w-full  text-center ">
              <input
                value={mobile}
                onChange={(e) => setMobile(e.target.value)}
                placeholder="Mobile"
                className="px-3 py-[14px] w-full placeholder: placeholder:text-[16px]  placeholder:font-light placeholder:text-[#000] bg-white rounded-[15px] border-none"
              />
            </div>
            {showReferral ? (
              <div className="flex mt-5 mb-2 mx-auto w-full  text-center ">
                <input
                  value={referral_code}
                  onChange={(e) => setReferralCode(e.target.value)}
                  placeholder="Got a referal code?"
                  className="px-3 py-[14px] w-full placeholder: placeholder:text-[16px]  placeholder:font-light placeholder:text-[#000] bg-white rounded-[15px] border-none"
                />
              </div>
            ) : null}

            <p className="text-start  text-xs font-light text-[#000]">
              Have a Referral Code?{" "}
              <span
                onClick={toggleReferral}
                className="font-semibold underline"
              >
                <b>Click Here</b>
              </span>{" "}
              to add.
            </p>
            <button
              onClick={() => handleLogin()}
              className=" w-full mt-8 mb-2 text-[14px] text-white font-medium  py-[12px] px-[5px] border border-white rounded-[15px]"
            >
              {isLoginLoading ? <HorizontalLoader /> : "Login"}
            </button>

            <p className="text-center text-xs font-light text-[#000]">
              By logging in, you agree to the{" "}
              <a
                className="font-semibold"
                href={`${process.env.REACT_APP_LANDING_PAGE_URL}/term-conditions.html`}
              >
                Terms & Conditions
              </a>{" "}
              and{" "}
              <a
                className="font-semibold"
                href={`${process.env.REACT_APP_LANDING_PAGE_URL}/privacy-policy.html`}
              >
                Privacy Policy
              </a>{" "}
              of Idyll
            </p>
          </>
        ) : null}

        {pageType === "otp" ? (
          <>
            <p className="mt-[250px] mb-4  font-bold text-[26px] text-center">
              Enter Verification Code
            </p>
            <div className="flex justify-around w-full">
              <OtpInput
                value={otp}
                onChange={setOtp}
                numInputs={6}
                renderSeparator={<span></span>}
                className="w-full"
                containerStyle="w-full"
                shouldAutoFocus={true}
                inputType="number"
                renderInput={(props) => (
                  <input
                    {...props}
                    className={`h-[52px] flex-1 mx-1 placeholder:text-black bg-[#fff] rounded-[18px] border-none`}
                  />
                )}
              />
            </div>

            <button
              onClick={() => verifyOtpMutate({ mobile, otp })}
              className=" w-full mt-8 text-[14px] text-white font-medium  py-[12px] px-[5px] border border-white rounded-[15px]"
            >
              {isLoginLoading ? <HorizontalLoader /> : "Verify OTP"}
              
            </button>

            <p className="mt-5  text-center  text-[10px] font-light text-[#000]">
              Didn't receive a code?
              <span
                onClick={() => handleLogin(true)}
                className="pl-1 text-[#fff] font-light underline uppercase"
              >
                Resend otp
              </span>
            </p>
          </>
        ) : null}
      </div>
    </div>
  );
};

export default Login;
