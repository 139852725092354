import React from "react";
import "../../assets/css/button.scss";
import HorizontalLoader from "../General/Loaders/HorizontalLoader";
const ButtonFill = ({ type, padding, onClick, icon, name, background, isLoading }) => {
  return (
    <div className={`button ${type}`} style={{ padding, background }} onClick={onClick}>
      {
        isLoading ? (
          <HorizontalLoader />
        ) : (
          <>
            {icon ? (
              <img src={icon} alt="" />
            ) : (
              ""
            )}
            <div className="name">{name} </div>
          </>
        )
      }

    </div>
  );
};

export default ButtonFill;
