import React from "react";

const RecievedMsg = (props) => {
  return (
    <div className="w-[85%] rounded-b-xl rounded-tr-xl px-3 py-2 text-white text-sm text-left bg-black">
      {props.msg}
      <br />
      <div className="mt-1 flex justify-start">
        <span className="text-[9px] font-light">{props.time}</span>
      </div>
      
    </div>
    // <div className="received-msg">
    //   {props.msg}
    //   <br />
    //   <div className="time">{props.time}</div>
    // </div>
  );
};

export default RecievedMsg;
