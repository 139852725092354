import { call, put, takeEvery } from "redux-saga/effects";
import {
  FETCH_CHAT_MSGS_REQUESTED,
  UPDATE_CHAT_MSGS_READ_REQUESTED,
  EXTEND_CHAT_REQUESTED
} from "../actionTypes";

import { extendChat, fetchChats, updateChatRead } from "../../services/chat";

import {
  fetchChatMsgsSuccess,
  fetchChatMsgsFailure,
  fetchMatchedUserDetailsSuccess,
  updateChatMsgsSuccess,
  updateChatMsgsFailure,
  showChatsLimitExceeded,
  extendChatSuccess,
  extendChatFailure,
  updateMatchDetails
} from "../actions";

function* fetchChatMsgsSaga(action) {
  try {
    const chat = yield call(fetchChats, action.payload);
    if (chat.success) {
      if(chat.data.chats){
        yield put(fetchChatMsgsSuccess({chats: chat.data.chats, totalPages: chat.data.totalPages, new : action.payload.new}));
      }else{
        yield put(showChatsLimitExceeded({
          isExtendedByBoth: chat.data.isExtendedByBoth,
          isExtendedByMe : chat.data.isExtendedByMe,
          isExtendedByOther : chat.data.isExtendedByOther
        }));
      }
      
      yield put(fetchMatchedUserDetailsSuccess(chat.data));
    }
  } catch (e) {
    yield put(fetchChatMsgsFailure(e));
  }
}

function* updateChatMsgsReadSaga(action) {
  try {
    const chat = yield call(updateChatRead, action.payload);
    if (chat.success) {
      yield put(updateChatMsgsSuccess(chat.data.chats));
    }
  } catch (e) {
    yield put(updateChatMsgsFailure(e));
  }
}

function* extendChatSaga(action) {
  try {
    const chat = yield call(extendChat, action.payload);
    if (chat.success) {
      // yield put(extendChatSuccess(chat.data));
      window.location = window.location.href
    }
  } catch (e) {
    yield put(extendChatFailure(e));
  }
}

function* chatsSaga() {
  yield takeEvery(FETCH_CHAT_MSGS_REQUESTED, fetchChatMsgsSaga);
  yield takeEvery(UPDATE_CHAT_MSGS_READ_REQUESTED, updateChatMsgsReadSaga);
  yield takeEvery(EXTEND_CHAT_REQUESTED, extendChatSaga);
}

export default chatsSaga;
