import React, { useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router";
import "../../../assets/css/smoking.scss";
import TopHeader from "../../../components/AllProfiles/TopHeader";
import ProfileProgressBar from "../../../components/ProfileScreens/ProfileProgressBar";
import Button from "../../../components/Buttons/Button";
import bottomCurve from "../../../assets/images/bottom-curve.png";
import { PROFILE_PAGES, profileSetupNextPage } from "../../../utils/profile";
// Actions
import { useUpdateMyProfile, useMyProfile } from "../../../react-query/profile/useProfile";

const DefaultSelect = () => {
  const navigate = useNavigate();
  const { type } = useParams();
  const location = useLocation();
  const {data : profile} = useMyProfile({ enabled : false })
  const [val, setVal] = useState((profile && profile[type]) ? profile[type] : PROFILE_PAGES[type].initial_value);
  const [error, setError] = useState(false);

  const PROFILE_PAGES_SETUP_KEYS = (Object.keys(PROFILE_PAGES).filter(k => PROFILE_PAGES[k].isSetupScreen))
  const currentPageIndex = PROFILE_PAGES_SETUP_KEYS.findIndex(k => k === type)
  const item = PROFILE_PAGES[type];

  const nextPage = () => {
    return profileSetupNextPage(type, profile, location.state)
  };

  const onUpdateMyProfileSuccess = () => {
    navigate(nextPage())
  }

  const {isLoading : isProfileUpdateLoading, mutate : mutateUpdateProfile} = useUpdateMyProfile({ onSuccess : onUpdateMyProfileSuccess })

  const updateHandler = () => {
    if (!val) {
      setError(true);
      return;
    }

    mutateUpdateProfile({[type]: val})
    setError(false);
  };

  const handleChange = (e) => {
    setVal(e.target.value);
    setError(false);
  };

  return (
    <div className="top-header-common smoking">
      {
        location.state !== "editprofile" ? <ProfileProgressBar currentPage={currentPageIndex + 1} totalPages={PROFILE_PAGES_SETUP_KEYS.length} /> : null
      }
      <div className="bg-image">
        <img src={bottomCurve} alt="" />
      </div>
      <div className="top">
        <TopHeader
          icon={item.icon}
          title={item.title}
          subtitle={item.subtitle}
        />
        <div className="input">
          {item.options.map((option, optionIndex) => {
            return (
              <React.Fragment key={`input-${optionIndex}`}>
                <input
                  type="radio"
                  name={type}
                  value={option.value}
                  id={option.value}
                  onChange={handleChange}
                  checked={option.value === val}
                />
                <label htmlFor={option.value}>{option.name}</label>
              </React.Fragment>
            );
          })}
          {item.required && error && (
            <div className="error" style={{ padding: "0 10px" }}>
              <span>Please select a valid answer</span>
            </div>
          )}
        </div>
      </div>
      <div className="bottom">
        <Button
          onClick={updateHandler}
          isLoading={isProfileUpdateLoading}
          name={(location.state === "editprofile") ? "Update" : "Next"}
          type="fill"
          padding="14px 29px"
        />
        {!item.required && (
          <Button
            onClick={() => navigate(nextPage())}
            name="Skip"
            type="outline"
            padding="14px 29px"
          />
        )}
      </div>
    </div>
  );
};

export default DefaultSelect;
