export const FETCH_RANDOM_PROFILES_REQUESTED = "FETCH_RANDOM_PROFILES_REQUESTED"

export const FETCH_RANDOM_PROFILES_SUCCESS = "FETCH_RANDOM_PROFILES_SUCCESS"

export const FETCH_RANDOM_PROFILES_FAILURE = "FETCH_RANDOM_PROFILES_FAILURE"

export const UPDATE_PROFILE_DECISION_REQUESTED = "UPDATE_PROFILE_DECISION_REQUESTED"

export const UPDATE_PROFILE_DECISION_SUCCESS = "UPDATE_PROFILE_DECISION_SUCCESS"

export const UPDATE_PROFILE_DECISION_FAILURE = "UPDATE_PROFILE_DECISION_FAILURE"

export const FETCH_MATCHED_USER_DETAILS_SUCCESS = "FETCH_MATCHED_USER_DETAILS_SUCCESS"

export const UPDATE_MATCH_DETAILS = "UPDATE_MATCH_DETAILS"

export const UPDATE_PROFILE_FILTERS = "UPDATE_PROFILE_FILTERS"

export const FETCH_MY_BLIND_MATCH_REQUESTED = "FETCH_MY_BLIND_MATCH_REQUESTED"

export const FETCH_MY_BLIND_MATCH_SUCCESS = "FETCH_MY_BLIND_MATCH_SUCCESS"

export const FETCH_MY_BLIND_MATCH_FAILURE = "FETCH_MY_BLIND_MATCH_FAILURE"

export const UPDATE_STREAK_MODAL_ACTIVE = "UPDATE_STREAK_MODAL_ACTIVE"

export const UPDATE_CACHED_PROFILE_IMAGES = "UPDATE_CACHED_PROFILE_IMAGES"

export const TOGGLE_REPORT_PROFILE_MODAL = "TOGGLE_REPORT_PROFILE_MODAL"

export const UPDATE_CURRENT_PROFILE_INDEX = "UPDATE_CURRENT_PROFILE_INDEX"

export const TOGGLE_MUTE_MUSIC = "TOGGLE_MUTE_MUSIC"

export const TOGGLE_SHOW_ERROR_SHOW_NEXT_PROFILE_MODAL = "TOGGLE_SHOW_ERROR_SHOW_NEXT_PROFILE_MODAL"