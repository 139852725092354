import {
    SET_SCREEN_WIDTH,
    SET_SCREEN_HEIGHT,
    SET_SCREEN_SIZE,
    UPDATE_IS_FREE_PRICING
} from "../actionTypes"

export const setScreenWidth = (data) => {
    return {
        type: SET_SCREEN_WIDTH,
        payload: data,
    };
};

export const setScreenHeight = (data) => {
    return {
        type: SET_SCREEN_HEIGHT,
        payload: data,
    };
};

export const setScreenSize = (data) => {
    return {
        type: SET_SCREEN_SIZE,
        payload: data,
    };
};

export const updateIsFreePricing = (data) => {
    return {
        type: UPDATE_IS_FREE_PRICING,
        payload: data,
    };
}