import { call, put, takeEvery } from "redux-saga/effects";
import {
    TRACK_EVENT_REQUESTED,
} from "../actionTypes";

import { trackEventService } from "../../services/event";

import {
    trackEventSuccess,
    trackEventFailure,
} from "../actions";

function* trackEventSaga(action) {
    try {
        const event = yield call(trackEventService, action.payload);
        if (event.success) {
            yield put(trackEventSuccess(event.data));
        }
    } catch (e) {
        yield put(trackEventFailure(e));
    }
}

function* eventsSaga() {
    yield takeEvery(TRACK_EVENT_REQUESTED, trackEventSaga);
}

export default eventsSaga;
