import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import RangeBar from "../../../components_tailwindcss/RangeBar";
import {
  PROFILE_PAGES,
  profileSetupNextPage,
  profileSetupPrevPage,
} from "../../../utils/profile";
// Actions
import {
  useUpdateMyProfile,
  useMyProfile,
} from "../../../react-query/profile/useProfile";
import HorizontalLoader from "../../../components/General/Loaders/HorizontalLoader";

const IsHabitDrink = () => {
  const type = "is_habit_drink";
  const navigate = useNavigate();
  const location = useLocation();

  const [val, setVal] = useState({ is_habit_drink: "" });
  const { data: profile } = useMyProfile();

  const [error, setError] = useState(false);

  const item = PROFILE_PAGES[type];

  const nextPage = () => {
    return profileSetupNextPage(type, profile, location.state);
  };

  const prevPage = () => {
    return profileSetupPrevPage(type, profile, location.state);
  };

  const onUpdateMyProfileSuccess = () => {
    navigate(nextPage());
  };

  const { isLoading: isProfileUpdateLoading, mutate: mutateUpdateProfile } =
    useUpdateMyProfile({ onSuccess: onUpdateMyProfileSuccess });

  const updateHandler = () => {
    for (const key in val) {
      const keyVal = val[key];
      if (!keyVal || !keyVal.length) {
        setError(true);
        return;
      }
    }

    mutateUpdateProfile(val);
    setError(false);
  };

  const handleChange = (key, value) => {
    setVal({ ...val, [key]: value });
    setError(false);
  };

  useEffect(() => {
    let profileValue = Object.assign({}, val);
    if (profile) {
      for (const key in val) {
        if (profile[key]) {
          profileValue[key] = profile[key];
        } else {
          profileValue[key] = PROFILE_PAGES[key].initial_value;
        }
      }
      setVal(profileValue);
    }
  }, [profile]);
  return (
    <div className="flex w-full flex-col items-center  bg_login">
      <div className="flex flex-col relative w-[80%] min-h-screen">
        <div className="my-5 flex-1 flex flex-col">
          {location.state !== "editprofile" ? (
            <RangeBar step={40} height={19} />
          ) : null}

          <div className="flex-1">
            <p className="mt-[170px]   font-bold text-[26px] text-center">
              {item.title}
            </p>
            <p className="mb-4 text-center  text-[10px] font-light text-[#000]">
              {item.placeholder}
            </p>

            {item.options.map((option, optionIndex) => {
              return (
                <p
                  key={`Option-${optionIndex}`}
                  onClick={() => handleChange("is_habit_drink", option.value)}
                  className={`rounded-[18px] my-4 text-left  font-bold text-[18px] pl-5 py-2.5 cursor-pointer ${
                    val[type] === option.value
                      ? "bg-black text-white"
                      : "bg-white"
                  }`}
                >
                  {option.name}
                </p>
              );
            })}
          </div>

          <div className="w-full relative right-0 flex justify-between">
            <button
              onClick={() => navigate(prevPage())}
              className="h-[47px] w-[100px] text-[18px] font-bold  "
            >
              Back
            </button>

            <button
              onClick={updateHandler}
              type="button"
              className="h-[47px] w-[100px] bg-white text-[18px] font-bold  px-4 py-2.5 rounded-[10px]"
            >
              {isProfileUpdateLoading ? <HorizontalLoader /> : "Next"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IsHabitDrink;
