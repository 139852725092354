import {
  FETCH_CHAT_MSGS_FAILURE,
  FETCH_CHAT_MSGS_SUCCESS,
  FETCH_CHAT_MSGS_REQUESTED,
  APPEND_CHAT_MSG,
  UPDATE_CHAT_MSGS_READ_REQUESTED,
  UPDATE_CHAT_MSGS_READ_SUCCESS,
  UPDATE_CHAT_MSGS_READ_FAILURE,
  EXTEND_CHAT_REQUESTED,
  EXTEND_CHAT_SUCCESS,
  EXTEND_CHAT_FAILURE,
  MARK_MSGS_READ,
  RESET_CHATS,
  SHOW_CHAT_LIMIT_EXCEEDED
} from "../actionTypes";

const initialState = {
  isLoading: false,
  chats: [],
  isReadLoading: false,
  isExtendChatLoading : false,
  chatReads: [],
  totalPages: null,
  isLimitExceeded : false,
  isExtendedByMe : false,
  isExtendedByBoth : false
};

export default function chatReducer(state = initialState, action) {
  const { type } = action;

  switch (type) {
    case SHOW_CHAT_LIMIT_EXCEEDED : {
      return {
        ...state,
        isExtendedByBoth : action.payload.isExtendedByBoth,
        isExtendedByMe : action.payload.isExtendedByMe,
        isExtendedByOther : action.payload.isExtendedByOther,
        isLimitExceeded : true
      }
    }

    case RESET_CHATS: {
      return {
        ...state,
        chats: [],
        chatReads: [],
        totalPages: null,
      };
    }
    case FETCH_CHAT_MSGS_SUCCESS: {
      const currChats = action.payload.chats;
      const prevChats = (state.chats && !action.payload.new) ? [...state.chats] : [];
      return {
        ...state,
        isLoading: false,
        chats: [...currChats, ...prevChats],
        totalPages: action.payload.totalPages,
      };
    }
    case FETCH_CHAT_MSGS_REQUESTED: {
      return {
        ...state,
        isLoading: true,
        isExtendedByBoth : false,
        isExtendedByMe : false,
        isExtendedByOther : false,
        isLimitExceeded : false
      };
    }

    case FETCH_CHAT_MSGS_FAILURE: {
      return {
        ...state,
        isLoading: false,
        chats: [],
      };
    }
    case UPDATE_CHAT_MSGS_READ_SUCCESS: {
      const chatReads = action.payload;
      return {
        ...state,
        isReadLoading: false,
        chatReads,
      };
    }
    case UPDATE_CHAT_MSGS_READ_REQUESTED: {
      return {
        ...state,
        isReadLoading: true,
      };
    }

    case UPDATE_CHAT_MSGS_READ_FAILURE: {
      return {
        ...state,
        isReadLoading: false,
        chats: [],
      };
    }

    case EXTEND_CHAT_SUCCESS: {
      const chatReads = action.payload;
      return {
        ...state,
        isExtendChatLoading: false,
        chatReads,
      };
    }
    case EXTEND_CHAT_REQUESTED: {
      return {
        ...state,
        isExtendChatLoading: true,
      };
    }

    case EXTEND_CHAT_FAILURE: {
      return {
        ...state,
        isExtendChatLoading: false,
        chats: [],
      };
    }

    case APPEND_CHAT_MSG: {
      // Find if the chat exist with the frontend uuid specified
      let updatedChats = [...state.chats];
      if (action.payload?.frontend_uuid){
        const chatLocalIndex = state.chats.findIndex(c => c["frontend_uuid"] === action.payload.frontend_uuid)
        
        if(chatLocalIndex >= 0){
          updatedChats[chatLocalIndex] = {...action.payload, is_pending : false}
        }else{
          // If not found, create a new chat object
          updatedChats.push(action.payload)
        }
      }else{
        // If not found, create a new chat object
        updatedChats = [...state.chats, action.payload]
      }

      console.log({updatedChats})
      return {
        ...state,
        chats: updatedChats,
      };
    }

    case MARK_MSGS_READ: {
      const updatedChatIds = action.payload;
      const newChats = (state.chats || []).map((chat) => {
        if (updatedChatIds.includes(chat._id)) {
          const newObj = JSON.parse(JSON.stringify(chat));
          newObj["is_read"] = true;
          return newObj;
        }
        return chat;
      });

      return {
        ...state,
        chats: newChats,
      };
    }

    default:
      return state;
  }
}
