import React from "react";
import TopHeader from "./AllProfiles/TopHeader";
import topBg from "../assets/images/top-bg-img.png";
// import { useNavigate } from "react-router-dom";

function LikeMatchHeader({ title, icon, subtitle }) {
  // const navigate = useNavigate();
  return (
    <>
      <div className="top-bg">
        <img src={topBg} alt="" />
      </div>
      <div className="top">
        <TopHeader icon={icon} title={title} subtitle={subtitle} />
      </div>
    </>
  );
}

export default LikeMatchHeader;
