import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import {
  fetchMyMatchesService,
  fetchLikesMeService,
  fetchRandomProfilesService,
  fetchUserFeedService,
} from "../../services/like";

export const useMyMatches = (chatType) => {
  // Stale time of 5 minutes
  const query = useQuery({
    staleTime: 5 * 60 * 1000,
    queryKey: ["my-matches", chatType],
    queryFn: () => fetchMyMatchesService(chatType),
  });
  return query;
};

export const useLikesMe = ({ enabled }) => {
  // Cant have custom stale time here since after disliking a profile or liking, it should refetch the likes array. For matches it is handled
  const query = useQuery({
    queryKey: ["likes-me"],
    queryFn: () => fetchLikesMeService(),
    enabled,
  });
  return query;
};

// export const useLikesMe = ({ enabled }) => {
//   const query = useInfiniteQuery(
//     ["likes-me"],
//     ({ pageParam = 1 }) => fetchLikesMeService({ page: pageParam }),
//     {
//       getNextPageParam: (lastPage, allPages) => {
//         const nextPageNumber = allPages ? allPages.length + 1 : 1;
//         return nextPageNumber;
//         // return hasNextPage ? nextPageNumber : undefined;
//       },
//     }
//   );

//   return query;
// };

// export const useRandomProfiles = ({ enabled, filters }) => {
//   const query = useInfiniteQuery(
//     ["random-profiles", filters],
//     ({ pageParam = 1 }) =>
//       fetchUserFeedService({ ...filters, page: pageParam }),
//     {
//       getNextPageParam: (lastPage, allPages) => {
//         const nextPageNumber = allPages ? allPages.length + 1 : 1;
//         return nextPageNumber;
//         // return hasNextPage ? nextPageNumber : undefined;
//       },
//     }
//   );

//   return query;
// };

export const useRandomProfiles = ({enabled, filters}) => {
    // Cant have custom stale time here since after disliking a profile or liking, it should refetch the likes array. For matches it is handled
    const query = useQuery({ queryKey: ['random-profiles', filters], queryFn: () => fetchUserFeedService(filters), enabled })
    return query
}
