import { MusicalNoteIcon, SpeakerWaveIcon, SpeakerXMarkIcon } from '@heroicons/react/24/solid'
import React from 'react'
import "../../assets/css/allprofile.scss"
import { useDispatch, useSelector } from 'react-redux'
import { toggleMuteMusic } from '../../store/actions'

function MusicTag({title}) {
  const dispatch = useDispatch()
  const isMusicMuted = useSelector(state => state.like.isMusicMuted)

  return (
    <div className='flex h-[24px] items-center z-10 text-xs p-1 ml-2 mt-2 bg-[#000000] px-3 text-[#FFFFFF] opacity-50 rounded-xl w-[150px]'>
        <MusicalNoteIcon className='text-white h-3 mr-1' />
        <div className='overflow-hidden whitespace-nowrap flex-1'><div className='inline-block music-marquee'>{title}</div></div>
        {
          !isMusicMuted ? <SpeakerWaveIcon onClick={() => dispatch(toggleMuteMusic(!isMusicMuted))} className='text-white h-3 mx-1' /> : <SpeakerXMarkIcon onClick={() => dispatch(toggleMuteMusic(!isMusicMuted))} className='text-white h-3 mx-1' />
        }
    </div>
  )
}

export default MusicTag