import { InformationCircleIcon } from '@heroicons/react/24/solid'
import React from 'react'
import "../../assets/css/allprofile.scss"
import { useDispatch } from 'react-redux'

function RatingTag({rating}) {
    const dispatch = useDispatch()
  return (
    <div className='h-[60px] opacity-50 mr-2 flex flex-col items-center justify-center text-xs p-1 px-1.5 mt-2 bg-[#000000] text-[#FFFFFF] rounded-xl'>
        <span className='font-[11px] mb-2'>Rated You</span>
        <span className='font-bold text-[24px]'>{rating}</span>
    </div>
  )
}

export default RatingTag