import axios from "axios";
const { authHeader } = require("../utils/auth");

// data is
export const fetchChats = (data) => {
  const { id, page } = data;
  return axios
    .get(`chat/match/${id}?page=${page}`, {
      headers: {
        ...authHeader(),
      },
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err;
    });
};

// data is match id
export const updateChatRead = (data) => {
  return axios
    .put(
      `chat/match/${data}/readmessage`,
      {},
      {
        headers: {
          ...authHeader(),
        },
      }
    )
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err;
    });
};

// data is match id
export const extendChat = (data) => {
  return axios
    .put(
      `chat/match/${data}/extendonce`,
      {},
      {
        headers: {
          ...authHeader(),
        },
      }
    )
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err;
    });
};
