import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Purchases, LOG_LEVEL } from "@revenuecat/purchases-capacitor";
import toast from "react-hot-toast";
// Components
import Navigation from "../components_tailwindcss/Navigation";
import Referral from "../components/MyProfile/Referral";
import Header from "../components/MyProfile/Header";
import Loader from "../common/loader";
// Images
import { CheckBadgeIcon } from "@heroicons/react/24/solid";
import profilebg from "../assets/img/icons/Profile_bg.svg";
// Redux
import {
  toggleUpgradePlanModal,
  // togglePurchaseBoostsModal,
  updateRestorePurchasesModalActive,
  restoreMyProfilePurchasesRequested,
  // fetchPlansRequested, logoutUser,
  trackEventRequested,
} from "../store/actions";

import {
  useAllPlans,
  useMyProfile,
  useMyRolesAndResponsibilities,
  useRestorePurchases,
} from "../react-query/profile/useProfile";

// Functions
import { showImage } from "../utils/profile";
import { formatDateGood } from "../utils/common";
import { useQueryClient } from "@tanstack/react-query";

// import {useInterval} from "../hooks/general"
if (process.env.REACT_APP_DEVICE_TYPE === "MOBILE") {
  Purchases.setLogLevel({ level: LOG_LEVEL.DEBUG }); // Enable to get debug logs in dev mode
  Purchases.configure({ apiKey: process.env.REACT_APP_REVENUE_CAT_API_KEY });
}

export default function Profile() {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    data: rolesAndResponsibilities,
    isLoading: isMyRolesAndResponsibilitiesLoading,
    refetch: refetchRolesAndResponsibilities,
  } = useMyRolesAndResponsibilities();

  const {
    isLoading: isMyProfileLoading,
    data: profile,
    refetch: refetchMyProfile,
    isRefetching : isMyProfileRefetching
  } = useMyProfile();

  const {
    isLoading: isRestorePurchasesLoading,
    mutateAsync: mutateRestorePurchases,
  } = useRestorePurchases({
    onSuccess : (data) => {
      queryClient.setQueryData(["my-roles-and-responsibilities"], (prevData) => {
        return data.data
      })
    }
  });
  
  // const { restorePurchasesModalActive } = useSelector((state) => state.profile);
  // const { isPurchaseBoostsModalOpen } = useSelector((state) => state.boost);
  // const { currentUpgradePlanModalType } = useSelector((state) => state.payment);
  // const [currentLovePotionAnimationKey, setCurrentLovePotionAnimationKey] = useState(0)

  // const Show = (plan) => {
  //   dispatch(
  //     trackEventRequested({ eventName: "PLAN_CLICKED", eventBody: { plan } })
  //   );
  //   dispatch(toggleUpgradePlanModal(plan));
  // };

  const logoutHandler = () => {
    dispatch(trackEventRequested({ eventName: "LOGOUT", eventBody: {} }));
    queryClient.clear();
    localStorage.clear();
    navigate("/login");
  };

  const handleRestorePurchases = async() => {
    await mutateRestorePurchases()
    // dispatch(restoreMyProfilePurchasesRequested());
    if (process.env.REACT_APP_DEVICE_TYPE === "MOBILE") {
      Purchases.restorePurchases().then(({ purchaserInfo }) => {
        console.log({ purchaserInfo });
        if (purchaserInfo.activeSubscriptions.length) {
          return toast.success(
            "Subscription Restored"
          );
        } else {
          return toast.error("No active subscriptions to be restored");
        }
      });
    }else{
      return toast.success(
        "Subscription Restored. Please click refresh on top."
      );
    }
  };

  const refreshPage = () => {
    dispatch(
      trackEventRequested({
        eventName: "REFRESH_PAGE",
        eventBody: { page: "myProfile" },
      })
    );
    refetchRolesAndResponsibilities();
    refetchMyProfile();
  };

  const isLoading = isMyRolesAndResponsibilitiesLoading || isMyProfileLoading;
  return (
    <div className="flex flex-col bg-white w-full w-full h-screen h-max">
      <Header />

      <div className="overflow-y-scroll flex flex-1 flex-col -mt-24 relative items-center">
        {isLoading ? (
          <div className="w-full px-5 flex mt-10 flex-1 flex-col items-center justify-center">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "inherit",
              }}
            >
              <Loader color="#FF6584" />
            </div>
          </div>
        ) : profile ? (
          <>
            <div className="overflow-y-scroll">
              <div className="relative z-10 flex flex-col flex-1 items-center justify-between">
                <img alt="bg" className="w-full absolute" src={profilebg} />

                <div className="px-5 z-[10]">
                  <div className="flex flex-col items-center pt-[37vw] w-full z-[10]">
                    <div
                      className="aspect-square w-[32%] rounded-full bg-cover bg-center bg-no-repeat"
                      style={{
                        backgroundImage: `url(${showImage(profile.photos[0])})`,
                      }}
                    ></div>

                    <button className=" text-white -mt-6  bg-black font-bold rounded-full py-2 px-5 text-[12px]">
                      {`${profile.complete_percent}% completed`}
                    </button>
                  </div>

                  <div className="flex flex-col items-center">
                    <p className="w-full flex items-center justify-center  font-bold text-[24px] text-[#353D44] mt-10">
                      {profile.final_name.split(" ")[0]}, {profile.age}{" "}
                      {profile.is_verified ? (
                        <CheckBadgeIcon className="icon-md icon-yellow" />
                      ) : null}
                    </p>
                    <p className=" font-semibold text-[10px]">
                      {`Currently in ${rolesAndResponsibilities?.subscription?.name} Plan`}
                    </p>

                    <div className="flex gap-5 mt-2">
                      <h1
                        onClick={(e) => {
                          window.location = `https://blog.getidyll.in/safety-well-being-on-idyll/`;
                        }}
                        className="cursor-pointer bg-[#1A2F38] text-white px-6 py-1.5 rounded-[22px]  text-[10px]"
                      >
                        Safety and Wellbeing{" "}
                      </h1>
                      <h1
                        onClick={() => handleRestorePurchases()}
                        className="cursor-pointer bg-[#1A2F38] text-white  px-6 py-1.5 rounded-[22px]  text-[10px]"
                      >
                        {
                          isRestorePurchasesLoading ? "Loading..." : "Restore Purchases"
                        }
                      </h1>
                    </div>
                    <div className="flex gap-5 mt-2">
                      <h1
                        onClick={() => refreshPage()}
                        className="cursor-pointer bg-[#1A2F38] text-white  px-6 py-1.5 rounded-[22px]  text-[10px]"
                      >
                        {
                          isMyProfileRefetching ? "Loading..." : "Refresh Profile"
                        }
                      </h1>
                    </div>
                  </div>

                  <Referral profile={profile} />

                  <p className="mt-4 text-center font-semibold text-[10px]">
                    Read our{" "}
                    <a
                      target="_blank"
                      rel="noreferrer"
                      className="cursor-pointer text-[#971198]"
                      href={`${process.env.REACT_APP_LANDING_PAGE_URL}/privacy-policy.html`}
                    >
                      Privacy policy
                    </a>{" "}
                    and{" "}
                    <a
                      target="_blank"
                      rel="noreferrer"
                      className="cursor-pointer text-[#971198]"
                      href={`${process.env.REACT_APP_LANDING_PAGE_URL}/term-conditions.html`}
                    >
                      T&C
                    </a>{" "}
                    here.
                  </p>

                  <p className="text-center font-semibold text-[10px]">
                    For any issues visit our website{" "}
                    <a
                      className="cursor-pointer text-[#971198]"
                      href={process.env.REACT_APP_LANDING_PAGE_URL}
                    >
                      here
                    </a>
                  </p>

                  <p className="text-center text-[10px]">
                    <b
                      className="cursor-pointer text-[#971198]"
                      onClick={logoutHandler}
                    >
                      Logout
                    </b>
                  </p>
                </div>
              </div>
            </div>
          </>
        ) : null}
      </div>

      <Navigation />
    </div>
  );
}