import React from "react";
import "../../../assets/css/modal.scss"
import crossIcon from "../../../assets/images/icons/general/cross-black.svg"
import { App as CapacitorApp } from '@capacitor/app'

function ViewPhotoModal({ show, onHide, children, isOpaque }) {
    
    return (
        <>
            {
                show ? <div className={`full-screen-dark-modal-page ${isOpaque ? "is-opaque" : ""}`}>
                    <div className="modal-container">
                        <div className="modal-header">
                            <img onClick={() => onHide()} src={crossIcon} className="cross-icon" />
                        </div>

                        {children}
                    </div>
                </div> : null
            }
        </>
    );
}

export default ViewPhotoModal;
