import React from "react";
import { useAllPlans } from "../react-query/profile/useProfile";
import Navigation from "../components_tailwindcss/Navigation";
import { useNavigate } from "react-router-dom";

const bgColorMapping = {
  PRO: "#950F9A",
  ACE: "#00C9FF",
};
export default function PremiumPlans() {
  const navigate = useNavigate();
  const { isLoading: isAllPlansLoading, data: plans } = useAllPlans();

  console.log({ plans });

  return (
    <div className={`flex flex-col items-center w-full bg_image_main w-full h-screen h-max`}>
      <div className="w-full flex flex-1 flex-col justify-center items-center">
        <p className=" text-[#000] mt-28 mx-auto text-3xl font-bold">
          Premium Features
        </p>
        <p className="text-center   font-bold text-[12px] text-[#00000080]">
          Upgrade for exclusive perks and a better dating experience.
        </p>

        {plans && !isAllPlansLoading
          ? ["ACE", "PRO"].map((planType, planTypeIndex) => {
              return (
                <div
                  key={`plan-${planTypeIndex}-details`}
                  style={{ background: bgColorMapping[planType] }}
                  className={`flex my-2 p-5 flex-col justify-center items-center w-[80%] px-10 rounded-[15px]`}
                >
                  <div className="flex pb-5 justify-between items-start w-full">
                    <p className=" text-[#fff] text-[20px] font-bold">
                      {planType}
                    </p>
                    <div className="flex flex-col">
                      <p className=" text-[#fff] text-[20px] ">
                        From{" "}
                        <span className="text-[20px]  font-semibold">
                          ₹150
                        </span>
                      </p>
                      <span className="text-right text-[#fff]  text-[12px]">
                        For 1 week
                      </span>
                    </div>
                  </div>

                  <div className="flex w-full py-0.5 text-[#ffff] justify-between ">
                    <p className="font-normal  text-[14px]">
                      Swipes/Day
                    </p>
                    <p className="font-normal  text-[14px]">
                      {plans[planType]?.num_likes}
                    </p>
                  </div>

                  <div className="flex w-full py-0.5 text-[#ffff] justify-between ">
                    <p className="font-normal  text-[14px]">
                      Filters
                    </p>
                    <p className="font-normal  text-[14px]">
                      {plans[planType]?.can_filter_accounts ? "Yes" : "No"}
                    </p>
                  </div>

                  <div className="flex w-full py-0.5 text-[#ffff]   justify-between ">
                    <p className="font-normal  text-[14px]">
                      Who Liked You
                    </p>
                    <p className="font-normal  text-[14px]">
                      {plans[planType]?.can_see_likes ? "Yes" : "No"}
                    </p>
                  </div>

                  <div className="flex w-full py-0.5 text-[#ffff]   justify-between ">
                    <p className="font-normal  text-[14px]">
                      Compliments/Day
                    </p>
                    <p className="font-normal  text-[14px]">
                      {plans[planType]?.superswipes}
                    </p>
                  </div>

                  <button
                    onClick={() => navigate(`/plans/${planType}`)}
                    className="text-white mt-5  bg-black font-bold rounded-[18.5px] py-1.5 px-6 text-xs"
                  >
                    View Plans
                  </button>
                </div>
              );
            })
          : null}
      </div>

      <Navigation />
    </div>
  );
}
