import { call, put, takeEvery, select } from "redux-saga/effects";
import {
    FETCH_APPEVENTS_REQUESTED,
    FETCH_APPEVENT_DETAILS_REQUESTED,
    FETCH_APPEVENT_REQUESTED,
    SUBSCRIBE_APPEVENT_REQUESTED
} from "../actionTypes";

import { fetchAppeventsService, fetchAppeventService, subscribeAppeventService, fetchAppeventDetailsService } from "../../services/appevent";

import {
    fetchAppeventsSuccess,
    fetchAppeventsFailure,
    fetchAppeventDetailsSuccess,
    fetchAppeventDetailsFailure,
    fetchAppeventSuccess,
    fetchAppeventFailure,
    subscribeAppeventSuccess,
    subscribeAppeventFailure,
    updateProfileFilters,
    fetchRandomProfilesRequested
} from "../actions";

function* fetchAppeventsSaga(action) {
    try {
        const event = yield call(fetchAppeventsService, action.payload);
        if (event.success) {
            yield put(fetchAppeventsSuccess(event.data));
        }
    } catch (e) {
        yield put(fetchAppeventsFailure(e));
    }
}

function* fetchAppeventSaga(action) {
    try {
        const event = yield call(fetchAppeventService, action.payload);
        if (event.success) {
            yield put(fetchAppeventSuccess(event.data));
        }
    } catch (e) {
        yield put(fetchAppeventFailure(e));
    }
}

function* subscribeAppeventsSaga(action) {
    try {
        const event = yield call(subscribeAppeventService, action.payload);
        if (event.success) {
            yield put(subscribeAppeventSuccess(event.data));
            
            const filters = yield select((state) => state.like.profileFilters)
            
            const newFilters = {...filters, event : { value : event.data.event_id, label : event.data.event_name}}
            
            yield put(updateProfileFilters(newFilters))

            yield put(fetchRandomProfilesRequested(newFilters))
        }
    } catch (e) {
        yield put(subscribeAppeventFailure(e));
    }
}

function* fetchAppeventDetailsSaga(action){
    try {
        const event = yield call(fetchAppeventDetailsService, action.payload);
        if (event.success) {
            yield put(fetchAppeventDetailsSuccess(event.data));
        }
    } catch (e) {
        yield put(fetchAppeventDetailsFailure(e));
    }
}

function* appeventsSaga() {
    yield takeEvery(FETCH_APPEVENT_REQUESTED, fetchAppeventSaga);
    yield takeEvery(FETCH_APPEVENTS_REQUESTED, fetchAppeventsSaga);
    yield takeEvery(SUBSCRIBE_APPEVENT_REQUESTED, subscribeAppeventsSaga);
    yield takeEvery(FETCH_APPEVENT_DETAILS_REQUESTED, fetchAppeventDetailsSaga);
}

export default appeventsSaga;
