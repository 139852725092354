export const FETCH_COLLEGES_REQUESTED = "FETCH_COLLEGES_REQUESTED"

export const FETCH_COLLEGES_SUCCESS = "FETCH_COLLEGES_SUCCESS"

export const FETCH_COLLEGES_FAILURE = "FETCH_COLLEGES_FAILURE"

export const CREATE_COLLEGE_REQUESTED = "CREATE_COLLEGE_REQUESTED"

export const CREATE_COLLEGE_SUCCESS = "CREATE_COLLEGE_SUCCESS"

export const CREATE_COLLEGE_FAILURE = "CREATE_COLLEGE_FAILURE"