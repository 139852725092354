import React from "react";
import toast from "react-hot-toast";
import copy from "copy-to-clipboard";
import { Share } from "@capacitor/share";
import whatsappImg from "../../assets/images/icons/my-profile/whatsapp-black.svg";
import telegramImg from "../../assets/images/icons/my-profile/telegram-black.svg";
import shareImg from "../../assets/images/icons/my-profile/share-black.svg";

function Referral({ profile }) {
  const copyReferralCode = () => {
    copy(profile.referral_code);
    toast.success("Code copied");
  };

  const getReferralShareText = (type = "G") => {
    const referral_code_mapping = {
      W: `*${profile.referral_code}*`,
      T: `**${profile.referral_code}**`,
      G: profile.referral_code,
    };

    return `It's time to say Goodbye 🫂 to endless swiping.\nLet's say Hello 🤗 to the world of scrolling!\nDitch the left-right swiping!🙅‍♂\nUse my referral code ${referral_code_mapping[type]} to join Idyll now!❤️\nhttps://getidyll.in`;
  };

  const shareToWhatsapp = () => {
    window.open(
      `https://api.whatsapp.com/send?text=${getReferralShareText("W")}`
    );
  };

  const shareToTelegram = () => {
    window.open(
      `https://telegram.me/share/url?url=${
        process.env.REACT_APP_LANDING_PAGE_URL
      }&text=${getReferralShareText("T")}`
    );
  };

  const shareGeneral = async () => {
    await Share.share({
      title: "Spread ❤️ with Idyll",
      text: getReferralShareText(),
      url: process.env.REACT_APP_LANDING_PAGE_URL,
      dialogTitle: "Spread ❤️ with Idyll",
    });
  };

  return (
    <div>
      <div className="relative flex items-center justify-center my-5">
        <div className=" -rotate-3 mx-auto absolute flex h-16 w-[80%] shrink-0 items-center justify-center rounded-[50%] border border-[#F7544D]"></div>
        <div className="rotate-3 mx-auto absolute flex h-16 w-[80%] shrink-0 items-center justify-center rounded-[50%] border border-[#F7544D]"></div>
        <h1 className="text-center  font-bold text-[45px] leading-10">
          Invite Your Friends!
        </h1>
      </div>

      <div className="flex p-5  bg-black rounded-[15px] flex-col items-center justify-center">
        <p className=" pb-3 text-white text-sm font-medium text-center">
          Share IDYLL with your friends to unlock freebies!
        </p>
        <button
          type="button"
          onClick={copyReferralCode}
          className="bg-white text-[11px] text-black  rounded-[15px] py-2 px-8"
        >
          {profile.referral_code}
        </button>
        <p
          onClick={copyReferralCode}
          className="cursor-pointer italic font-light text-xs text-white pt-1"
        >
          Tap to copy
        </p>

        <div className="flex flex-col gap-3">
          <div className="flex flex-wrap gap-x-3 justify-center items-center pt-5">
            <div
              onClick={shareToWhatsapp}
              className="w-[100px] cursor-pointer flex items-center justify-center bg-white font-bold text-black  text-[8px]  rounded-[15px] py-1.5"
            >
              <img
                src={whatsappImg}
                alt="w"
                className="w-[13px] h-[13px] ml-3 mr-2"
              />
              <p className="text-xs mr-3">Whatsapp</p>
            </div>
            <div
              onClick={shareToTelegram}
              className="w-[100px] cursor-pointer flex items-center justify-center bg-white font-bold text-black  text-[8px]  rounded-[15px] py-1.5"
            >
              <img
                src={telegramImg}
                alt="w"
                className="w-[13px] h-[13px] ml-3 mr-2"
              />
              <p className="text-xs mr-3">Telegram</p>
            </div>
          </div>

          <div className="flex flex-wrap gap-x-2 justify-center items-center">
            <div
              onClick={shareGeneral}
              className="w-full cursor-pointer flex items-center justify-center bg-white font-bold text-black  text-[8px]  rounded-[15px] py-1.5"
            >
              <img
                src={shareImg}
                alt="w"
                className="w-[13px] h-[13px] ml-3 mr-2"
              />
              <p className="text-xs mr-3">Share Via</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    // <div className="referral-card">

    //     <div className="referral-code-box">
    //         <span className="referral-code">{profile.referral_code}</span>
    //         <span onClick={copyReferralCode} className="referral-code-copy">
    //             <img src={copyImg} height={12} />
    //             Copy
    //         </span>
    //     </div>
    //     <div className="referral-buttons">
    //         <div onClick={shareToWhatsapp} className="referral-button referral-whatsapp">
    //             <img style={{ marginRight: 3 }} src={whatsappImg} height={15}></img>
    //             <span>Whatsapp</span>
    //         </div>
    //         <div onClick={shareToTelegram} className="referral-button referral-telegram">
    //             <img style={{ marginRight: 3 }} src={telegramImg} height={15}></img>
    //             <span>Telegram</span>
    //         </div>
    //         <div onClick={shareGeneral} className="referral-button referral-general">
    //             <img style={{ marginRight: 3 }} src={shareImg} height={15}></img>
    //             <span>Share Via</span>
    //         </div>
    //     </div>

    // </div>
  );
}

export default Referral;
