import React from "react";
import 'animate.css';
import "../../../assets/css/modal.scss"
import crossIcon from "../../../assets/images/icons/general/cross-black.svg"
// import { App as CapacitorApp } from '@capacitor/app'

function Modal({ show, onHide, children, title = "", fullScreen=false, styles={}, headerStyles={} }) {
    return (
        <>
            {
                show ? <div className="modal-page">
                    <div className="modal-container animate__animated animate__zoomIn" style={{...styles}}>
                        <div className="modal-header">
                            {(!fullScreen && title.length) ? <h3 style={headerStyles} className="modal-header-title">{title}</h3> : null}
                            <img onClick={() => onHide()} src={crossIcon} className="cross-icon" />
                        </div>

                        {children}
                    </div>
                </div> : null
            }
        </>
    );
}

export default Modal;
