import { call, put, takeEvery } from "redux-saga/effects";
import {
    FETCH_RANDOM_PROFILES_REQUESTED,
    UPDATE_PROFILE_DECISION_REQUESTED,
    FETCH_MY_BLIND_MATCH_REQUESTED
} from "../actionTypes"

import {
    fetchRandomProfilesService,
    updateProfileDecisionService,
    fetchMyBlindMatchService
} from "../../services/like"

import {
    fetchRandomProfilesSuccess,
    fetchRandomProfilesFailure,
    updateProfileDecisionSuccess,
    updateProfileDecisionFailure,
    fetchMyBlindMatchSuccess,
    fetchMyBlindMatchFailure,
    updateProfileStreak,
    toggleUpgradePlanModal
} from "../actions";

function* fetchRandomProfilesSaga(action) {
    try {
        const profiles = yield call(fetchRandomProfilesService, action.payload.filters);
        if(profiles.success){
            yield put(fetchRandomProfilesSuccess(profiles.data))
        }else{
            yield put(fetchRandomProfilesFailure(profiles.msg));
        }
        
    } catch (e) {
        yield put(fetchRandomProfilesFailure(e));
    }
}

function* updateProfileDecisionSaga(action) {
    try {
        const response = yield call(updateProfileDecisionService, action.payload.data);
        if(response.success){
            yield put(updateProfileDecisionSuccess(response))

            if(action.payload?.onSuccess){
                action.payload.onSuccess()
            }
            // if(action.payload.isRedirect){
            //     let navigateLink = ""
            //     if(action.payload.data.isUnmatch){
            //         navigateLink = `/matches?new=true&hideUser=${action.payload.data.user}`
            //     }else{
            //         navigateLink = (action.payload.data.likeType === "LIKE") ? "/matches?new=true" : "/allprofiles"
            //     }
                
            //     action.payload.navigate(navigateLink)
            // }
            
        }
    } catch (e) {
        if(e?.response?.data?.isShowUpgradeModal){
            yield put(toggleUpgradePlanModal("ACE"))
        }
        yield put(updateProfileDecisionFailure(e));
    }
}

function* fetchMyBlindMatchSaga(action){
    try {
        const response = yield call(fetchMyBlindMatchService, action.payload.data);
        if(response.success){
            yield put(fetchMyBlindMatchSuccess(response.data))
            action.payload.navigate("/matches?new=true")
        }
    } catch (e) {
        yield put(fetchMyBlindMatchFailure(e));
    }
}

function* likeSaga() {
    yield takeEvery(FETCH_RANDOM_PROFILES_REQUESTED, fetchRandomProfilesSaga);
    yield takeEvery(UPDATE_PROFILE_DECISION_REQUESTED, updateProfileDecisionSaga);
    yield takeEvery(FETCH_MY_BLIND_MATCH_REQUESTED, fetchMyBlindMatchSaga);
}

export default likeSaga;
