import {
    CREATE_PAYMENT_ORDER_FAILURE, 
    CREATE_PAYMENT_ORDER_REQUESTED, 
    CREATE_PAYMENT_ORDER_SUCCESS,
    UPDATE_PLANS_AND_PACKAGES,
    TOGGLE_UPGRADE_PLAN_MODAL
} from "../actionTypes"

export const createPaymentOrderRequested = (data) => {
    return {
        type : CREATE_PAYMENT_ORDER_REQUESTED,
        payload : data
    }
}

export const createPaymentOrderSuccess = (data) => {
    return {
        type : CREATE_PAYMENT_ORDER_SUCCESS,
        payload : data
    }
}

export const createPaymentOrderFailure = (data) => {
    return {
        type : CREATE_PAYMENT_ORDER_FAILURE,
        payload : data
    }
}

export const updatePlansAndPackages = (data) => {
    return {
        type : UPDATE_PLANS_AND_PACKAGES,
        payload : data
    }
}

export const toggleUpgradePlanModal = (data) => {
    return {
        type : TOGGLE_UPGRADE_PLAN_MODAL,
        payload : data
    }
}