import React from "react";
import empty from "../../assets/images/empty.png";
import "./emptyState.scss";

const Index = ({ icon = empty, label, styles }) => {
  return (
    <div className="empty">
      <img src={icon} alt="" />
      <label className="font-bold" htmlFor="">{label}</label>
    </div>
  );
};

export default Index;
