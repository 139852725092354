import {
    TRACK_EVENT_REQUESTED,
    TRACK_EVENT_SUCCESS,
    TRACK_EVENT_FAILURE,
} from "../actionTypes";

const initialState = {
    isLoading: false,
};

export default function eventReducer(state = initialState, action) {
    const { type } = action;

    switch (type) {

        case TRACK_EVENT_REQUESTED: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case TRACK_EVENT_SUCCESS: {
            return {
                ...state,
                isLoading: false,
            };
        }

        case TRACK_EVENT_FAILURE: {
            return {
                ...state,
                isLoading: false,
            };
        }

        default:
            return state;
    }
}
