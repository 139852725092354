import {
    FETCH_COLLEGES_REQUESTED,
    FETCH_COLLEGES_SUCCESS,
    FETCH_COLLEGES_FAILURE,
    CREATE_COLLEGE_REQUESTED,
    CREATE_COLLEGE_SUCCESS,
    CREATE_COLLEGE_FAILURE,
} from "../actionTypes";

export const fetchCollegesRequested = (data) => {
    return {
        type: FETCH_COLLEGES_REQUESTED,
        payload: data,
    };
}

export const fetchCollegesSuccess = (data) => {
    return {
        type: FETCH_COLLEGES_SUCCESS,
        payload: data,
    };
};

export const fetchCollegesFailure = (data) => {
    return {
        type: FETCH_COLLEGES_FAILURE,
        payload: data,
    };
};

export const createCollegeRequested = (data) => {
    return {
        type: CREATE_COLLEGE_REQUESTED,
        payload: data,
    };
};

export const createCollegeSuccess = (data) => {
    return {
        type: CREATE_COLLEGE_SUCCESS,
        payload: data,
    };
};

export const createCollegeFailure = (data) => {
    return {
        type: CREATE_COLLEGE_FAILURE,
        payload: data,
    };
};