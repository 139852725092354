import { call, put, takeEvery, debounce } from "redux-saga/effects";
import {
    FETCH_COLLEGES_REQUESTED,
    CREATE_COLLEGE_REQUESTED
} from "../actionTypes";

import { fetchCollegesService, createCollegeService } from "../../services/college";

import {
    fetchCollegesSuccess,
    fetchCollegesFailure,
    createCollegeSuccess,
    createCollegeFailure,
} from "../actions";

function* fetchCollegesSaga(action) {
    try {
        const colleges = yield call(fetchCollegesService, action.payload);
        if (colleges.success) {
            yield put(fetchCollegesSuccess(colleges.data));
        }
    } catch (e) {
        yield put(fetchCollegesFailure(e));
    }
}

function* createCollegeSaga(action) {
    try {
        const college = yield call(createCollegeService, action.payload);
        if (college.success) {
            yield put(createCollegeSuccess(college.data));
        }
    } catch (e) {
        yield put(createCollegeFailure(e));
    }
}

function* chatsSaga() {
    yield debounce(500, FETCH_COLLEGES_REQUESTED, fetchCollegesSaga);
    yield takeEvery(CREATE_COLLEGE_REQUESTED, createCollegeSaga);
}

export default chatsSaga;
