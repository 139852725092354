import React, { useState } from "react";
import HorizontalLoader from "../../components/General/Loaders/HorizontalLoader";
import { useSelector } from "react-redux";

function BlockContactModal({ onConfirm, onCancel }) {
  const isDecisionLoading = useSelector(state => state.like.isDecisionLoading)
  return (
    <div
      className={`z-50 flex justify-center items-center overflow-auto left-0 top-0 bottom-0 right-0 w-full h-full fixed transition-opacity duration-300 ${
        true ? "opacity-100" : ""
      }`}
    >
      <div
        className={`z-50 flex flex-col relative border-2 border-[#F7544D] bg-white rounded-[22px] p-3 mx-auto my-0 max-w-md min-w-xs w-[80%] transform scale-0 transition duration-500 ease-in-out ${
          true ? "scale-100" : ""
        }`}
      >
        <p className="flex py-3 px-2 text-[14px]  font-semibold text-[#181818]">
          Are you sure want to block this contact?
        </p>

        <div className="w-full flex justify-center">
          <button
            onClick={() => onCancel()}
            style={{
              background:
                "linear-gradient(90deg, #FC5849 0%, #D1396B 33.85%, #C02E78 67.71%, #950F9A 100%)",
            }}
            className="my-3 mx-2  text-[12px] font-semibold text-white px-5 py-2  rounded-[29px] leading-[11px]"
          >
            Cancel
          </button>
          <button
            onClick={() => onConfirm()}
            style={{
              background:
                "linear-gradient(90deg, #FC5849 0%, #D1396B 33.85%, #C02E78 67.71%, #950F9A 100%)",
            }}
            className="my-3 mx-2  text-[12px] font-semibold text-white px-5 py-2  rounded-[29px] leading-[11px]"
          >
            {isDecisionLoading ? <HorizontalLoader /> : "Block"}
          </button>
        </div>
      </div>
      <div
        className={`z-40 overflow-auto left-0 top-0 bottom-0 right-0 w-full h-full fixed bg-black opacity-50 ${
            true ? "visible" : "invisible"
        }`}
      ></div>
    </div>
  );
}

export default BlockContactModal;
