import React from 'react'
import Navigation from '../components_tailwindcss/Navigation'
import blindDate from "../assets/img/Delivery/blinddating.svg";
import { ReactComponent as Arrow } from "../assets/img/icons/arrow-left.svg";
import { useNavigate } from 'react-router-dom';



export default function BlindDating() {
    const navigate = useNavigate()
    return (
        <div className="flex flex-col flex-1 bg-white w-full relative w-full h-screen h-max">
            <Arrow onClick={() => navigate("/loop")} className="absolute cursor-pointer w-[11px] h-[11px] mt-10 ml-10" />
            <p className="text-center  text-[#353D44] mt-[90px] text-3xl font-bold mx-auto">Blind Date!</p>
            <div className="overflow-scroll px-8 flex mt-10 flex-1 flex-col items-center justify-center">
                <img src={blindDate} alt="b" className="mb-3" />
                <p className=" text-[#353D44] text-3xl font-bold">Dive into the Unknown</p>
                <p className="text-center pb-4  font-bold text-[12px] text-[#b3b3b3]">Your next great story might be waiting.</p>
                <button className="text-black  font-bold rounded-full py-2 px-5 text-sm shadow-lg">Start Blind Dating!</button>
            </div>
            <Navigation />
        </div>
    )
}
