import axios from "axios";
import toast from 'react-hot-toast';
const { authHeader } = require("../utils/auth");

export const fetchAdvertisementsService = (data) => {
  
  return new Promise((resolve, reject) => {
    axios
    .get(`advertisement/specific-user`, {
      headers: {
        ...authHeader(),
      },
    })
    .then((res) => {
      return resolve(res.data)
    })
    .catch((err) => {
      toast.error(err?.response?.data?.msg)
      return reject(err)
    });
  })
};

export const markAdvertisementActionService = (data) => {
  return axios
    .post(
      `advertisement-user`,
      data,
      {
        headers: {
          ...authHeader(),
        },
      }
    )
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      toast.error(err?.response?.data?.msg)
      throw err;
    });
};