import GenderIcon from "../assets/images/icons/profile-setup/gender.svg";
import BirthdayIcon from "../assets/images/icons/profile-setup/birthday.svg";
import MobileIcon from "../assets/images/icons/profile-setup/mobile.svg";
import InstagramIcon from "../assets/images/icons/profile-setup/instagram.svg";
import WhatToFindIcon from "../assets/images/icons/profile-setup/what-to-find.svg";
import WhoToDateIcon from "../assets/images/icons/profile-setup/who-to-date.svg";
import HeightIcon from "../assets/images/icons/profile-setup/height.svg";
import CollegeIcon from "../assets/images/icons/profile-setup/college.svg";
import DrinkingHabitIcon from "../assets/images/icons/profile-setup/drinking-habit.svg";
import SmokingHabitIcon from "../assets/images/icons/profile-setup/smoking-habit.svg";
import BioIcon from "../assets/images/icons/profile-setup/bio.svg";

export const PROFILE_PAGES = {
  // Page type can be 2 types = [DEFAULT_INPUT, DEFAULT_SELECT, UPLOAD_COLLEGE_ID, UPLOAD_PHOTOS, INTERESTS, SELECT_COLLEGE, PROFILE_BIO]
  // "name": {
  //     initial_value: "",
  //     page_type : "DEFAULT_INPUT",
  //     title: "What is your name?",
  //     placeholder: "Enter your name...",
  //     subtitle: "Lorem ipsum dolor sit amet consectetur. Elit amet suspendisse augue vel.",
  //     icon: require('../assets/images/user.png')
  // },
  "basic-info": {
    initial_value: "",
    page_type: "DEFAULT_INPUT",
    page_route : "basic-info",
    keys_to_check : ["name", "email"],
    required: true,
    title: "What is your name?",
    placeholder: "Type here..",
    subtitle:
      "Remember, you can't change that down the road",
    icon: BioIcon,
    isSetupScreen : true
  },
  gender: {
    initial_value: "",
    page_type: "DEFAULT_SELECT",
    page_route : "gender",
    keys_to_check : ["gender"],
    required: true,
    title: "How would you describe your gender?",
    placeholder: "Select your gender...",
    subtitle:
      "Remember, you can't change that down the road",
    icon: GenderIcon,
    isSetupScreen : true,
    options: [
      {
        name: "Male",
        value: "M",
      },
      {
        name: "Female",
        value: "F",
      },
      {
        name: "Non Binary",
        value: "NB",
      },
    ],
  },
  dob: {
    initial_value: "",
    page_type: "DEFAULT_INPUT",
    page_route : "dob",
    keys_to_check : ["dob"],
    required: true,
    title: "What is your birthday?",
    placeholder: "Type here..",
    subtitle:
      "Remember, you can't change that down the road",
    icon: BirthdayIcon,
    isSetupScreen : true,
  },
  insta_username: {
    initial_value: "",
    page_type: "DEFAULT_INPUT",
    page_route : "instagram",
    keys_to_check : ["insta_username"],
    title: "May I know your Instagram username?",
    placeholder: "Enter your insta username...",
    editLabel : "Are you on Insta?",
    subtitle:
      "A good instagram profile helps us rank you better!",
    icon: InstagramIcon,
  },
  what_to_find: {
    initial_value: "",
    page_type: "DEFAULT_SELECT",
    page_route : "what-to-find",
    keys_to_check : ["what_to_find"],
    required: true,
    title: "What are you hoping to find?",
    placeholder: "What are you hoping to find...",
    editLabel : "What are you hoping to find?",
    subtitle:
      "Ummm thats your choice :p",
    options: [
      {
        name: "Relationship",
        value: "R",
      },
      {
        name: "Casual",
        value: "C",
      },
      {
        name: "Not Sure",
        value: "NS",
      },
      {
        name: "Friendship",
        value: "F",
      },
      {
        name: "Connections",
        value: "CON",
      },
    ],
    icon: WhatToFindIcon,
  },
  who_to_date: {
    initial_value: "",
    page_type: "DEFAULT_SELECT",
    page_route : "who-to-date",
    keys_to_check : ["who_to_date"],
    title: "Who you want to be with?",
    placeholder: "Whom do you want to date...",
    editLabel : "Which gender do you get attracted to?",
    subtitle: "We will show profiles based on this choice. Act now or never! HAHA",
    options: [
      {
        name: "Male",
        value: "M",
      },
      {
        name: "Female",
        value: "F",
      },
      {
        name: "Non Binary",
        value: "NB",
      },
      {
        name: "Anyone",
        value: "A",
      },
    ],
    icon: WhoToDateIcon,
  },
  height: {
    initial_value: "",
    page_type: "DEFAULT_INPUT",
    page_route : "height",
    keys_to_check : ["height"],
    title: "Tell me your size (Height)",
    placeholder: "Your height (in cm)",
    editLabel : "Aaah! How tall are you?",
    subtitle:
      "Remember 1 foot is 12 inches and 1 inch is 2.54 cms",
    icon: HeightIcon,
    is_number_input : true,
  },
  college: {
    required : true,
    initial_value: "",
    page_type: "SELECT_COLLEGE",
    page_route : "college",
    keys_to_check : ["college"],
    title: "What's your College/Company name?",
    placeholder: "Type Here",
    subtitle:
      "We allow you to search people in your institution",
    icon: CollegeIcon,
    isSetupScreen : true,
  },
  is_habit_drink: {
    initial_value: null,
    page_type: "DEFAULT_SELECT",
    page_route : "is-habit-drink",
    keys_to_check : ["is_habit_drink"],
    required: true,
    title: "How often do you drink?",
    placeholder: "What is your drinking habit...",
    editLabel : "How often do you drink?",
    subtitle:
      "Don't worry, we wont tell your parents",
    options: [
      {
        name: "Often",
        value: "O",
      },
      {
        name: "Socially",
        value: "S",
      },
      {
        name: "Never",
        value: "N",
      },
    ],
    icon: DrinkingHabitIcon,
  },
  is_habit_smoke: {
    initial_value: null,
    page_type: "DEFAULT_SELECT",
    page_route : "is-habit-smoke",
    keys_to_check : ["is_habit_smoke"],
    required: true,
    title: "How often do you smoke?",
    placeholder: "What is your smoking habit...",
    editLabel : "How often do you smoke?",
    subtitle:
      "Your future partner might be interested in knowing this!",
    options: [
      {
        name: "Often",
        value: "O",
      },
      {
        name: "Socially",
        value: "S",
      },
      {
        name: "Never",
        value: "N",
      },
    ],
    icon: SmokingHabitIcon,
  },
  photos: {
    // This is a seperate page altogether
    page_type: "UPLOAD_PHOTOS",
    page_route : "photos",
    keys_to_check : ["photos"],
    editLabel : "Update your attractive photos. Yeah?",
    required : true,
    isSetupScreen : true,
  },
  college_id_card: {
    page_type: "UPLOAD_COLLEGE_ID",
    page_route : "college-id",
    keys_to_check : ["college_id_card"],
    editLabel : "Update your ID Card for verification?",
  },
  interests: {
    // This is a seperate page altogether
    page_type: "INTERESTS",
    page_route : "interests",
    keys_to_check : ["interests"],
    editLabel : "Are you interests changed over the time?",
    isSetupScreen : true,
  },
  bio: {
    initial_value: "",
    page_type: "PROFILE_BIO",
    page_route : "bio",
    keys_to_check : ["bio"],
    required: true,
    title: "AI Generated Bio Recommendations!",
    placeholder: "Select from the recommendations above or write here in not more than 200 characters",
    editLabel : "Wanna regenerate a cool bio?",
    subtitle:
      "People notice good bios after your photos. Try our recommendations for a cooler bio!",
    icon: BioIcon,
    isSetupScreen : true,
  },
};

export const showImage = (img, className=null) => {
  let queryString = ''
  if(className && className.length > 0) queryString = `?class=${className}`

  let imgUrl = ''
  // Checking if img path is .heic then use .heic cdn on top of it.
  if(img.toLowerCase().endsWith(".heic")) {
    imgUrl = `${process.env.REACT_APP_HEIC_FILE_BASE_URL}${img}`
  }else{
    imgUrl = (img.indexOf("http") === 0) ? img : `${process.env.REACT_APP_FILE_BASE_URL}${img}${queryString}`  
  }

  return imgUrl
}

export const checkIfEmail = function(str) {
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  return emailRegex.test(str);
}

export const profileSetupPrevPage = (type, profile, locationState) => {

  if (locationState === "editprofile") return "/editprofile"

  const allPageTypes = Object.keys(PROFILE_PAGES).filter(k => PROFILE_PAGES[k].isSetupScreen);
  const currentRouteIndex = allPageTypes.findIndex((k) => k === type);

  // Finding the prev route
  let prevRouteIndex = currentRouteIndex-1
  
  const prevPageType = allPageTypes[prevRouteIndex];
  return `/myprofile/${PROFILE_PAGES[prevPageType].page_route}`;
};

export const profileSetupNextPage = (type, profile, locationState) => {

  if (locationState === "editprofile") return "/editprofile"

  const allPageTypes = Object.keys(PROFILE_PAGES).filter(k => PROFILE_PAGES[k].isSetupScreen);
  const currentRouteIndex = allPageTypes.findIndex((k) => k === type);

  // Finding the next empty route
  let nextRouteIndex = currentRouteIndex+1

  console.log({ nextRouteIndex })

  if(profile){
    for(let i = nextRouteIndex; i < allPageTypes.length; i++){
      const profileKey = allPageTypes[i]
      if ((!profile[profileKey] || !profile[profileKey].length)){
        nextRouteIndex = i
        break
      }
    }
  }
  
  const nextPageType = allPageTypes[nextRouteIndex];

  if(!nextPageType) return "/loop"

  return `/myprofile/${PROFILE_PAGES[nextPageType].page_route}`;
};