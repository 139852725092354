import React, { useEffect, useState } from "react";

export default function RangeSelector({ range, handleRange, min, max, isDisabled=false }) {
  const [val, setVal] = useState(range);

  const [isDragging, setIsDragging] = useState(false);

  const handleTouchStart = () => {
    setIsDragging(true);
  };

  const handleTouchMove = (e) => {
    if (isDragging) {
      // Update the range value as the user drags the slider
      setVal(e.target.value);
    }
  };

  const handleTouchEnd = () => {
    if (isDragging) {
      // Trigger the function only when the user releases the touch after dragging the slider
      // You may want to perform any additional actions here
      setIsDragging(false);
      handleRange(val)
    }
  };

  useEffect(() => {
    setVal(range);
  }, [range]);
  return (
    <div className="relative shadow-md w-full h-2 text-[10px] flex rounded bg-[#D9D9D9] ">
      <div
        style={{
          width: val * (100 / max) + 0.2 + "%",
          background:
            "linear-gradient(90deg, #FC5849 0%, #D1396B 33.85%, #C02E78 67.71%, #950F9A 100%)",
        }}
        className="shadow-none rounded flex flex-col min-w-[1%] text-center whitespace-nowrap text-white justify-center  "
      ></div>
      <input
        disabled={isDisabled}
        type="range"
        min={min}
        max={max}
        value={val}
        onChange={handleTouchMove}
        onTouchStart={handleTouchStart}
        onTouchEnd={handleTouchEnd}
        onMouseDown={handleTouchStart}
        onMouseUp={handleTouchEnd}
        className="cursor-pointer absolute w-full  bg-transparent thumb-fill  h-2 rounded-full appearance-none accent-[#95095A]"
      />
    </div>
  );
}
