import {
    FETCH_COLLEGES_REQUESTED,
    FETCH_COLLEGES_SUCCESS,
    FETCH_COLLEGES_FAILURE,
    CREATE_COLLEGE_REQUESTED,
    CREATE_COLLEGE_SUCCESS,
    CREATE_COLLEGE_FAILURE
} from "../actionTypes";

const initialState = {
    isLoading: false,
    colleges: [],
    isNewCollegeLoading: false,
};

export default function chatReducer(state = initialState, action) {
    const { type } = action;

    switch (type) {

        case FETCH_COLLEGES_REQUESTED: {
            return {
                ...state,
                isLoading: true,
                colleges : []
            };
        }
        case FETCH_COLLEGES_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                colleges: action.payload
            };
        }

        case FETCH_COLLEGES_FAILURE: {
            return {
                ...state,
                isLoading: false,
                colleges: [],
            };
        }
        
        case CREATE_COLLEGE_REQUESTED: {
            return {
                ...state,
                isNewCollegeLoading: true,
            };
        }

        case CREATE_COLLEGE_SUCCESS: {
            return {
                ...state,
                isNewCollegeLoading: false,
                colleges : [...state.colleges, action.payload],
            };
        }

        case CREATE_COLLEGE_FAILURE: {
            return {
                ...state,
                isNewCollegeLoading: false,
                error: action.payload
            };
        }

        default:
            return state;
    }
}
