import React, { useState, useRef } from 'react'
import DotLoader from '../General/Loaders/DotLoader';
import photoIcon from "../../assets/images/icons/profile-setup/upload.svg";
import deletePhotoIcon from "../../assets/images/icons/profile-setup/delete-photo.svg";
import { uploadFile } from '../../services/upload';
import toast from 'react-hot-toast';

function UploadPhoto({ index, url, setPhotos, photos, setIsFileUploading }) {
    const [isLoading, setIsLoading] = useState(false)

    const initialPhotos = (photos) => {
        return [null, null, null, null].map((k, ki) => {
            return photos && photos[ki] ? photos[ki] : null
        })
    }

    const getFilePath = (path) => {
        return `${process.env.REACT_APP_FILE_BASE_URL}${path}`;
    };

    const toggleLoading = (stat) => {
        setIsFileUploading(stat)
        setIsLoading(stat)
    }

    const handleUpload = (e) => {
        const file = e.target.files[0]
        if (!file) return

        if (!file.type.includes("image/")) {
            return toast.error("We allow only images to be uploaded")
        }

        toggleLoading(true);
        uploadFile({ file })
            .then((data) => {
                let newPhotos = photos.slice();
                newPhotos[index] = data.filePath;
                setPhotos(newPhotos);
            })
            .catch(err => {
                toast.error(err)
            })
            .finally(() => {
                toggleLoading(false);
            });
    };

    const handleDelete = () => {
        let newPhotos = photos.slice().filter((_, i) => i !== index);
        setPhotos(initialPhotos(newPhotos));
    }

    return (
        <div className={`image ${url ? "uploaded" : ""}`}>
            <div className="image-box">
                {
                    (isLoading) ? <DotLoader /> : <img
                        src={url ? getFilePath(url) : photoIcon}
                        alt="" className="img"
                    />
                }

                <input
                    type="file"
                    onChange={(e) => handleUpload(e)}
                />
            </div>
            <div className="image-actions">
                {
                    url ? <img src={deletePhotoIcon} alt="" onClick={() => handleDelete()} height="16" /> : null
                }
            </div>
        </div>
    )
}

export default UploadPhoto