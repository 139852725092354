import axios from "axios"
import toast from 'react-hot-toast';
const {authHeader} = require("../utils/auth")

export const updateProfilePreferencesService = (data) => {
    return new Promise((resolve, reject) => {
        axios.put(`user/profile`, data, {
            headers : {
                ...authHeader()
            }
        }).then((res)=>{
            if(!res.data.success){
                return reject(res.data.data)
            }
            return resolve(res.data.data)
        }).catch(err => {
            toast.error(err?.response?.data?.msg)
            return reject(err)
        })
    })
}

export const fetchMyProfileService = () => {
    return new Promise((resolve, reject) => {
        axios.get(`user/me`, {
            headers : {
                ...authHeader()
            }
        }).then((res)=>{
            if(!res.data.success){
                return reject(res.data.data)
            }
            return resolve(res.data.data)
        }).catch(err => {
            toast.error(err?.response?.data?.msg)
            return reject(err)
        })
    })
    
}

export const fetchMyRolesAndResponsibilitiesService = (data) => {
    if(!authHeader()['x-auth-token']) return
    
    return new Promise((resolve, reject) => {
        axios.get(`user/roles-and-responsibilities`, {
            headers : {
                ...authHeader()
            }
        }).then((res)=>{
            if(!res.data.success){
                return reject(res.data.data)
            }
            return resolve(res.data.data)
        }).catch(err => {
            toast.error(err?.response?.data?.msg)
            return reject(err)
        })
    })
}

export const fetchSingleProfileService = (data) => {

    return axios.get(`user/${data}`, {
        headers : {
            ...authHeader()
        }
    }).then((res)=>{
        return res.data
    }).catch(err => {
        throw err
    })
}

export const loginUserService = (data) => {
    return new Promise((resolve, reject) => {
        axios.post(`auth`, data, {
            headers : {
                ...authHeader()
            }
        }).then((res)=>{
            if(!res.data.success){
                toast.error(res.data.msg)
                return reject(res.data.data)
            }
            toast.success(res.data.msg)
            return resolve(res.data.data)
        }).catch(err => {
            toast.error(err?.response?.data?.msg)
            return reject(err)
        })
    })
}

export const verifyOtpUserService = (data) => {
    return new Promise((resolve, reject) => {
        axios.post(`auth/verify-otp`, data, {
            headers : {
                ...authHeader()
            }
        }).then((res)=>{
            if(!res.data.success){
                toast.error(res.data.msg)
                return reject(res.data.data)
            }
            toast.success(res.data.msg)
            return resolve(res.data.data)
        }).catch(err => {
            toast.error(err?.response?.data?.msg)
            reject(err)
        })
    })
}

export const updateUniqueMobileIdService = (data) => {
    return axios.put(`user/mobile-id`, data, {
        headers : {
            ...authHeader()
        }
    }).then((res)=>{
        return res.data
    }).catch(err => {
        toast.error(err?.response?.data?.msg)
        throw err
    })
}

export const profileRestorePurchaseService = (data) => {
    return axios.post(`user/restore-purchases`, {}, {
        headers : {
            ...authHeader()
        }
    }).then((res)=>{
        return res.data
    }).catch(err => {
        toast.error(err?.response?.data?.msg)
        throw err
    })
}

export const deleteUserService = () => {
    return axios.delete(`user/me`, {
        headers : {
            ...authHeader()
        }
    }).then((res)=>{
        return res.data
    }).catch(err => {
        toast.error(err?.response?.data?.msg)
        throw err
    })
}

export const updateUserPrivacySettingsService = (data) => {
    return axios.put(`user/update-privacy-settings`, data, {
        headers : {
            ...authHeader()
        }
    }).then((res)=>{
        return res.data
    }).catch(err => {
        toast.error(err?.response?.data?.msg)
        throw err
    })
}

export const fetchUserDescriptionRecommendationService = () => {
    return new Promise((resolve, reject) => {
        axios.get(`user/auto-description`, {
            headers : {
                ...authHeader()
            }
        }).then((res)=>{
            if(!res.data.success){
                return reject(res.data.data)
            }
            return resolve(res.data.data)
        }).catch(err => {
            toast.error(err?.response?.data?.msg)
            return reject(err)
        })
    })
}