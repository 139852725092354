import React, { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { Toaster } from 'react-hot-toast';
import { useDispatch } from "react-redux";
import "./App.scss";
import { setScreenSize, setScreenWidth, toggleMuteMusic, updateIsFreePricing, updatePlansAndPackages } from "./store/actions";
import ProfileLayout from "./pages/ProfileScreens/Layouts";
// import Location from "./pages/ProfileScreens/Location";
import KindNotice from "./pages_tailwind/KindNotice";
import ProfileReadyToGo from "./pages_tailwind/ProfileReadyToGo";
import AllProfiles from "./pages/AllProfiles";
import ProfileBasicInfo from "./pages_tailwind/ProfileScreens/Pages/BasicInfo";
import ProfileGender from "./pages_tailwind/ProfileScreens/Pages/Gender";
import ProfileDOB from "./pages_tailwind/ProfileScreens/Pages/DOB";
import ProfileWhoToDate from "./pages_tailwind/ProfileScreens/Pages/WhoToDate";
import ProfileCollege from "./pages_tailwind/ProfileScreens/Pages/College";
import ProfileCollegeId from "./pages_tailwind/ProfileScreens/Pages/CollegeId";
import ProfilePhotos from "./pages_tailwind/ProfileScreens/Pages/Photos";
import Interests from "./pages_tailwind/ProfileScreens/Pages/Interests";
import ProfileBio from "./pages_tailwind/ProfileScreens/Pages/Bio";
import LikesReelsPage from "./pages_tailwind/LikesReelsPage";
import Matches from "./pages_tailwind/Matches";
import BlindDating from "./pages_tailwind/BlindDating";
import EditProfile from "./pages_tailwind/EditProfile";
import DeleteProfile from "./pages_tailwind/DeleteProfile";
import Login from "./pages_tailwind/Login";
import Chat from "./pages_tailwind/Chat";
import RouteTransitionWrapper from './components_tailwindcss/RouteTransitionWrapper';

import UpdateModal from "./components/General/Modals/UpdateModal";
import PrivateRoute from "./components/Routes/PrivateRoute";
import PublicRoute from "./components/Routes/PublicRoute";
import LoginHandler from "./pages_tailwind/LoginHandler";
import SingleProfile from "./pages_tailwind/SingleProfile";
import Events from "./pages/Events";
import EventDetails from "./pages/EventDetails";
import { App as CapacitorApp } from "@capacitor/app";
import { Device } from "@capacitor/device";
// Plugins
import OneSignal from "onesignal-cordova-plugin";
import { fetchLatestVersion } from "./services/versioncheck";
import { useState } from "react";
import { SafeArea } from "capacitor-plugin-safe-area";
import { useMeasure } from 'react-use'
import DEVELOPMENT_OFFERINGS from "./utils/development-offerings.json";
import {
  useMyProfile,
  useUpdateUniqueDeviceId,
} from "./react-query/profile/useProfile";
import { Purchases, LOG_LEVEL } from "@revenuecat/purchases-capacitor";
import PremiumPlans from "./pages_tailwind/PremiumPlans";
import ReelsPage from "./pages_tailwind/ReelsPage";
import Profile from "./pages_tailwind/Profile";
import Plans from "./pages_tailwind/Plans";
// import MatchesList from "./pages_tailwind/MatchesList";
import WhatToFind from "./pages_tailwind/ProfileScreens/Pages/WhatToFind";
import IsHabitDrink from "./pages_tailwind/ProfileScreens/Pages/IsHabitDrink";
import IsHabitSmoke from "./pages_tailwind/ProfileScreens/Pages/IsHabitSmoke";
import Height from "./pages_tailwind/ProfileScreens/Pages/Height";
import Instagram from "./pages_tailwind/ProfileScreens/Pages/Instagram";
import { App } from '@capacitor/app';
import { SocketProvider } from "./components/Socket/SocketProvider";
import { initSocket } from "./utils/socket";

if (process.env.REACT_APP_DEVICE_TYPE === "MOBILE") {
  Purchases.setLogLevel({ level: LOG_LEVEL.DEBUG }); // Enable to get debug logs in dev mode
  Purchases.configure({ apiKey: process.env.REACT_APP_REVENUE_CAT_API_KEY });
}

const IdyllApp = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [ref, { width : screenWidth, height : screenHeight }] = useMeasure()

  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [versionDetails, setVersionDetails] = useState(null);
  const [safeArea, setSafeArea] = useState();
  const [deviceInfo, setDeviceInfo] = useState();

  const { mutate: mutateUpdateUniqueDeviceId } = useUpdateUniqueDeviceId();
  
  useEffect(() => {
    if (process.env.REACT_APP_DEVICE_TYPE === "MOBILE") {
      // Add listener for back button on mobile
      CapacitorApp.addListener("backButton", ({ canGoBack }) => {
        if (!canGoBack) {
          CapacitorApp.exitApp();
        } else {
          window.history.back();
        }
      });

      // Initializing onesignal
      const OneSignalInit = function () {
        try {
          console.log(
            "One Signal initialized",
            process.env.REACT_APP_ONE_SIGNAL_APP_ID,
            process.env.REACT_APP_DEVICE_TYPE
          );

          // Remove this method to stop OneSignal Debugging
          OneSignal.Debug.setLogLevel(6)

          OneSignal.initialize(process.env.REACT_APP_ONE_SIGNAL_APP_ID);

          OneSignal.Notifications.addEventListener('click', async (e) => {
            let clickData = await e.notification;
            console.log("Notification Clicked : " + clickData);
          })

          OneSignal.Notifications.requestPermission(true).then((success) => {
            console.log("Notification permission granted " + success);
          })

          const localMobile = localStorage.getItem("mobile");

          if(localMobile){
            OneSignal.login(localMobile)
          }

        } catch (error) {
          console.log("One Signal error", error);
        }
      };

      const setupAppListeners = async () => {
        await App.addListener('appStateChange', ({ isActive }) => {
          if (!isActive) {
            // App is going to the background
            dispatch(toggleMuteMusic(true));
          }
          else {
            // App is going to the background
            dispatch(toggleMuteMusic(false));
          }
        });
      };

      OneSignalInit();

      setupAppListeners();

      // Get all offerings and add to redux state
      Purchases.getOfferings().then((offerings) => {
        console.log("oferings", offerings);
        dispatch(updatePlansAndPackages(offerings));
      });

      // Checking if logged in then take mobile and login to revenuecat
      const localMobile = localStorage.getItem("mobile");
      const localToken = localStorage.getItem("token");
      if (localMobile && localToken) {
        Purchases.logIn({ appUserID: localMobile })
      }
    } else if (
      process.env.REACT_APP_DEVICE_TYPE === "WEBSITE" ||
      process.env.REACT_APP_MODE === "development"
    ) {
      dispatch(updatePlansAndPackages(DEVELOPMENT_OFFERINGS));
    }

    // Cleanup listeners when component unmounts
    return () => {
      const cleanupListeners = async () => {
        await App.removeAllListeners();
      };
      cleanupListeners();
    };
  }, [dispatch, mutateUpdateUniqueDeviceId]);

  // Check for version and modal to update
  useEffect(() => {
    // Check for version match else show update popup
    fetchLatestVersion().then(async (ver) => {
      const info = await Device.getInfo();

      const version =
        info?.platform === "ios"
          ? process.env.REACT_APP_VERSION_IOS
          : process.env.REACT_APP_VERSION;
      const versionBackend =
        info?.platform === "ios"
          ? ver.map((v) => v.latest_version_ios.toString())
          : ver.map((v) => v.latest_version_android.toString());

      if (!versionBackend.includes(version)) {
        
        setShowUpdateModal(true);
        setVersionDetails(ver[ver.length - 1]);
        
      } else {
        const versionIndex = versionBackend.findIndex(k => k === version);
        const versionObject = ver[versionIndex]

        setShowUpdateModal(false);
        setVersionDetails(ver);
        dispatch(updateIsFreePricing(!!versionObject?.is_free_pricing))
      }
    });
  }, [location, dispatch]);

  useEffect(() => {
    // Screen Bounds
    const setScreenBounds = async () => {
      const info = await Device.getInfo();
      const { insets } = await SafeArea.getSafeAreaInsets();
      setDeviceInfo(info);
      if (info?.platform === "ios") {
        setSafeArea(insets);
      }
    };
    setScreenBounds();

    // Global Socket Connection
    const socket = initSocket();

    return () => {
      socket.disconnect();
    };
  }, []);

  useEffect(() => {
    if(screenHeight > 0 && screenWidth > 0){
      dispatch(setScreenSize({screenWidth, screenHeight}))
    }
  }, [dispatch, screenHeight, screenWidth])

  return (
    <div
      className="App w-screen"
      ref={ref}
      style={{ marginTop: safeArea?.top, marginBottom: safeArea?.bottom }}
    >
      {deviceInfo ? (
        <UpdateModal
          show={showUpdateModal}
          versionDetails={versionDetails}
          platform={deviceInfo?.platform}
        />
      ) : null}
      <Toaster/>
      <SocketProvider>
      <RouteTransitionWrapper>
      <Routes>
        <Route
          path="/"
          element={
            <PrivateRoute>
              <LoginHandler />
            </PrivateRoute>
          }
        />
        <Route
          path="/login"
          element={
            <PublicRoute>
              <Login />
            </PublicRoute>
          }
        />
        <Route
          path="/rated-you"
          element={
            <PrivateRoute>
              <LikesReelsPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/matches"
          element={
            <PrivateRoute>
              <Matches />
            </PrivateRoute>
          }
        />

        <Route
          path="/loop"
          element={
            <PrivateRoute>
              <ReelsPage />
            </PrivateRoute>
          }
        />

        <Route
          path="/myprofile"
          element={
            <PrivateRoute>
              <Profile />
            </PrivateRoute>
          }
        />

        <Route
          path="/premium-plans"
          element={
            <PrivateRoute>
              <PremiumPlans />
            </PrivateRoute>
          }
        />

        <Route
          path="/plans/:planType"
          element={
            <PrivateRoute>
              <Plans />
            </PrivateRoute>
          }
        />

        <Route
          path="/blind-date"
          element={
            <PrivateRoute>
              <BlindDating />
            </PrivateRoute>
          }
        />

        <Route
          path="/editprofile"
          element={
            <PrivateRoute>
              <EditProfile />
            </PrivateRoute>
          }
        />
        <Route
          path="/delete-profile"
          element={
            <PrivateRoute>
              <DeleteProfile />
            </PrivateRoute>
          }
        />

        <Route
          path="/loginhandler"
          element={
            <PrivateRoute>
              <LoginHandler />
            </PrivateRoute>
          }
        />

        {/* <Route
          path="/location"
          element={
            <PrivateRoute>
              <Location />
            </PrivateRoute>
          }
        /> */}
        <Route
          path="/myprofile/basic-info"
          element={
            <PrivateRoute>
              <ProfileBasicInfo />
            </PrivateRoute>
          }
        />
        <Route
          path="/myprofile/gender"
          element={
            <PrivateRoute>
              <ProfileGender />
            </PrivateRoute>
          }
        />
        <Route
          path="/myprofile/dob"
          element={
            <PrivateRoute>
              <ProfileDOB />
            </PrivateRoute>
          }
        />
        <Route
          path="/myprofile/who-to-date"
          element={
            <PrivateRoute>
              <ProfileWhoToDate />
            </PrivateRoute>
          }
        />
        <Route
          path="/myprofile/college"
          element={
            <PrivateRoute>
              <ProfileCollege />
            </PrivateRoute>
          }
        />
        <Route
          path="/myprofile/college-id"
          element={
            <PrivateRoute>
              <ProfileCollegeId />
            </PrivateRoute>
          }
        />
        <Route
          path="/myprofile/photos"
          element={
            <PrivateRoute>
              <ProfilePhotos />
            </PrivateRoute>
          }
        />
        <Route
          path="/myprofile/interests"
          element={
            <PrivateRoute>
              <Interests />
            </PrivateRoute>
          }
        />
        <Route
          path="/myprofile/bio"
          element={
            <PrivateRoute>
              <ProfileBio />
            </PrivateRoute>
          }
        />
        <Route
          path="/myprofile/what-to-find"
          element={
            <PrivateRoute>
              <WhatToFind />
            </PrivateRoute>
          }
        />
        <Route
          path="/myprofile/is-habit-drink"
          element={
            <PrivateRoute>
              <IsHabitDrink />
            </PrivateRoute>
          }
        />
        <Route
          path="/myprofile/is-habit-smoke"
          element={
            <PrivateRoute>
              <IsHabitSmoke />
            </PrivateRoute>
          }
        />
        <Route
          path="/myprofile/height"
          element={
            <PrivateRoute>
              <Height />
            </PrivateRoute>
          }
        />
        <Route
          path="/myprofile/instagram"
          element={
            <PrivateRoute>
              <Instagram />
            </PrivateRoute>
          }
        />
        <Route
          path="/myprofile/:type"
          element={
            <PrivateRoute>
              <ProfileLayout />
            </PrivateRoute>
          }
        />
        <Route
          path="/kindnotice"
          element={
            <PrivateRoute>
              <KindNotice />
            </PrivateRoute>
          }
        />
        <Route
          path="/readytogo"
          element={
            <PrivateRoute>
              <ProfileReadyToGo />
            </PrivateRoute>
          }
        />
        <Route
          path="/allprofiles"
          element={
            <PrivateRoute>
              <AllProfiles />
            </PrivateRoute>
          }
        />
        <Route
          path="/profile/:id"
          element={
            <PrivateRoute>
              <SingleProfile />
            </PrivateRoute>
          }
        />

        <Route
          path="/chat/:id"
          element={
            <PrivateRoute>
              <Chat />
            </PrivateRoute>
          }
        />
        <Route path="/events" element={<Events />} />
        <Route path="/events/:id" element={<EventDetails />} />
      </Routes>
      </RouteTransitionWrapper>
      </SocketProvider>
    </div>
  );
};

export default IdyllApp;
