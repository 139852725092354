import {
    FETCH_ADVERTISEMENTS_REQUESTED,
    FETCH_ADVERTISEMENTS_SUCCESS,
    FETCH_ADVERTISEMENTS_FAILURE,
    MARK_ADVERTISEMENT_ACTION_REQUESTED,
    MARK_ADVERTISEMENT_ACTION_SUCCESS,
    MARK_ADVERTISEMENT_ACTION_FAILURE,
} from "../actionTypes";

export const fetchAdvertisementsRequested = (data) => {
    return {
        type: FETCH_ADVERTISEMENTS_REQUESTED,
        payload: data,
    };
}

export const fetchAdvertisementsSuccess = (data) => {
    return {
        type: FETCH_ADVERTISEMENTS_SUCCESS,
        payload: data,
    };
};

export const fetchAdvertisementsFailure = (data) => {
    return {
        type: FETCH_ADVERTISEMENTS_FAILURE,
        payload: data,
    };
};

export const markAdvertisementActionRequested = (data) => {
    return {
        type: MARK_ADVERTISEMENT_ACTION_REQUESTED,
        payload: data,
    };
};

export const markAdvertisementActionSuccess = (data) => {
    return {
        type: MARK_ADVERTISEMENT_ACTION_SUCCESS,
        payload: data,
    };
};

export const markAdvertisementActionFailure = (data) => {
    return {
        type: MARK_ADVERTISEMENT_ACTION_FAILURE,
        payload: data,
    };
};