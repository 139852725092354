import React from 'react'
import FireIcon from "../../assets/images/icons/daily-streak/fire.png"
import "../../assets/css/daily-streak.scss"
import { useMyProfile } from '../../react-query/profile/useProfile'

function DailyStreak({onContinue}) {
    const { isLoading : isMyProfileLoading, data : profile } = useMyProfile()

    return (
        <div className='daily-streak-container'>
            <div className="animate__animated animate__delay-1s animate__swing main">
                <img alt="fire-icon" className="icon" src={FireIcon}></img>
                <span className="title">{profile?.current_active_streak}</span>
            </div>
            <div className="streak-content">
            <p className='title'>{profile?.current_active_streak} Day{profile?.current_active_streak > 1 ? "s" : null} Streak!</p>
            <p className='subtitle'>Swipe everyday to build your streak. The longer the streak, the greater are the possibilities of a match.</p>
            
            </div>
            <button onClick={onContinue} className="streak-button">Continue</button>


        </div>
    )
}

export default DailyStreak