export const FETCH_MY_ROLES_AND_RESPONSIBILITIES_REQUESTED = "FETCH_MY_ROLES_AND_RESPONSIBILITIES_REQUESTED"

export const FETCH_MY_ROLES_AND_RESPONSIBILITIES_SUCCESS = "FETCH_MY_ROLES_AND_RESPONSIBILITIES_SUCCESS"

export const FETCH_MY_ROLES_AND_RESPONSIBILITIES_FAILURE = "FETCH_MY_ROLES_AND_RESPONSIBILITIES_FAILURE"

export const FETCH_SINGLE_PROFILE_REQUESTED = "FETCH_SINGLE_PROFILE_REQUESTED"

export const FETCH_SINGLE_PROFILE_SUCCESS = "FETCH_SINGLE_PROFILE_SUCCESS"

export const FETCH_SINGLE_PROFILE_FAILURE = "FETCH_SINGLE_PROFILE_FAILURE"

export const SET_PROFILE_FILTER_MODAL_ACTIVE = "SET_PROFILE_FILTER_MODAL_ACTIVE"

export const UPDATE_PROFILE_PRIVACY_SETTINGS_SUCCESS = "UPDATE_PROFILE_PRIVACY_SETTINGS_SUCCESS"

export const UPDATE_PROFILE_PRIVACY_SETTINGS_FAILURE = "UPDATE_PROFILE_PRIVACY_SETTINGS_FAILURE"

export const RESTORE_PROFILE_PURCHASES_REQUESTED = "RESTORE_PROFILE_PURCHASES_REQUESTED"

export const RESTORE_PROFILE_PURCHASES_SUCCESS = "RESTORE_PROFILE_PURCHASES_SUCCESS"

export const RESTORE_PROFILE_PURCHASES_FAILURE = "RESTORE_PROFILE_PURCHASES_FAILURE"

export const UPDATE_RESTORE_PURCHASES_MODAL_ACTIVE = "UPDATE_RESTORE_PURCHASES_MODAL_ACTIVE"