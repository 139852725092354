import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import RangeBar from "../../../components_tailwindcss/RangeBar";
import HorizontalLoader from "../../../components/General/Loaders/HorizontalLoader";
import {
  PROFILE_PAGES,
  checkIfEmail,
  profileSetupNextPage,
  profileSetupPrevPage
} from "../../../utils/profile";
// Actions
import {
  useUpdateMyProfile,
  useMyProfile,
} from "../../../react-query/profile/useProfile";

const Height = () => {
  const type = "height";
  const navigate = useNavigate();
  const location = useLocation();
  const [val, setVal] = useState({ height: "" });
  const { data: profile } = useMyProfile();

  const [error, setError] = useState(false);

  const item = PROFILE_PAGES[type];

  const nextPage = () => {
    return profileSetupNextPage(type, profile, location.state);
  };

  const prevPage = () => {
    return profileSetupPrevPage(type, profile, location.state);
  };

  const onUpdateMyProfileSuccess = () => {
    navigate(nextPage());
  };

  const { isLoading: isProfileUpdateLoading, mutate: mutateUpdateProfile } =
    useUpdateMyProfile({ onSuccess: onUpdateMyProfileSuccess });

  const updateHandler = () => {
    for (const key in val) {
      const keyVal = val[key];
      if (
        !keyVal ||
        !keyVal.length ||
        (key === "mobile" &&
          (keyVal.length !== 10 || !/^[0-9]{10}$/g.test(keyVal))) ||
        (key === "email" && !checkIfEmail(keyVal))
      ) {
        setError(true);
        return;
      }
    }

    mutateUpdateProfile(val);
    setError(false);
  };

  const handleChange = (key, value) => {
    setVal({ ...val, [key]: value });
    setError(false);
  };

  useEffect(() => {
    let profileValue = Object.assign({}, val);
    if (profile) {
      for (const key in profileValue) {
        if (profile[key]) {
          profileValue[key] = profile[key];
        }
      }
      setVal(profileValue);
    }
  }, [profile]);

  return (
    <div className="flex w-full flex-col items-center  bg_login">
      <div className="flex flex-col relative w-[80%] min-h-screen">
        <div className="my-5 flex-1 flex flex-col">
          {location.state !== "editprofile" ? (
            <RangeBar step={13} height={19} />
          ) : null}

<div className="flex-1">
          <p className="mt-[160px]  font-bold text-[26px] text-center">
            {item.title}
          </p>
          <p className=" text-center  mb-4   text-[10px] font-light text-[#000]">
            {item.placeholder}
          </p>
          <div className="flex mt-5 mb-2 mx-auto w-full  text-center ">
            <input
              onChange={(e) => handleChange("height", e.target.value)}
              value={val?.height}
              placeholder={item.placeholder}
              className="px-3 py-[14px] w-full placeholder: placeholder:text-[16px]  placeholder:font-light placeholder:text-[#000] bg-white rounded-[15px] border-none"
            />
          </div></div>

          <div className="w-full relative right-0 flex justify-between">
            <button
              onClick={() => navigate(prevPage())}
              className="h-[47px] w-[100px] text-[18px] font-bold  "
            >
              Back
            </button>

            <button
              onClick={updateHandler}
              type="button"
              className="h-[47px] w-[100px] bg-white text-[18px] font-bold  px-4 py-2.5 rounded-[10px]"
            >
              {isProfileUpdateLoading ? <HorizontalLoader /> : "Next"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Height;
