import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router";
import "../../../assets/css/upload-id.scss";
import TopHeader from "../../../components/AllProfiles/TopHeader";
import ProfileProgressBar from "../../../components/ProfileScreens/ProfileProgressBar";
import DotLoader from "../../../components/General/Loaders/DotLoader";
import Button from "../../../components/Buttons/Button";
import docIcon from "../../../assets/images/docIcon.png";
import uploadIcon from "../../../assets/images/uploadIcon.png";
import bottomCurve from "../../../assets/images/bottom-curve.png";
import { uploadFile } from "../../../services/upload";
import { showImage, PROFILE_PAGES } from "../../../utils/profile";
import toast from 'react-hot-toast';
import { useUpdateMyProfile, useMyProfile } from "../../../react-query/profile/useProfile";

const UploadCollegeId = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const title = `Upload your College ID to get your yellow tick`;
  const subtitle = `Aren't yellow ticks better than the blue ones? Plus we don't charge you for ticks!`;
  const PROFILE_PAGES_SETUP_KEYS = (Object.keys(PROFILE_PAGES).filter(k => PROFILE_PAGES[k].isSetupScreen))
  const currentPageIndex = PROFILE_PAGES_SETUP_KEYS.findIndex(k => k === "college_id_card")

  const [isLoading, setIsLoading] = useState(false);
  const {data : profile} = useMyProfile({ enabled : false })
  const [photo, setPhoto] = useState(profile?.college_id_card);

  const handleUpload = (file) => {

    if (!file) return

    if (!file.type.includes("image/") && !file.type.includes("pdf")) {
      return toast.error("We allow only images/pdf to be uploaded")
    }

    setIsLoading(true);
    uploadFile({ file })
      .then((data) => {
        setPhoto(data.filePath);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getExtension = (filename) => {
    return filename.split('.').pop()
  }

  const nextPage = () => {
    if (location.state === "editprofile") return "/myprofile"
    return "/myprofile/interests"
  };

  const onUpdateMyProfileSuccess = () => {
    navigate(nextPage())
  }

  const { isLoading: isProfileUpdateLoading, mutate: mutateUpdateProfile } = useUpdateMyProfile({ onSuccess: onUpdateMyProfileSuccess })

  const updateHandler = () => {
    if (!photo) return toast.error("Please upload a valid ID Card or Skip")
    mutateUpdateProfile({ college_id_card: photo })
  };

  return (
    <div className="top-header-common upload-id">
      {
        location.state !== "editprofile" ? <ProfileProgressBar currentPage={currentPageIndex + 1} totalPages={PROFILE_PAGES_SETUP_KEYS.length} /> : null
      }
      <div className="bg-image">
        <img src={bottomCurve} alt="" />
      </div>
      <div className="top">
        <TopHeader icon={docIcon} title={title} subtitle={subtitle} />
        <div className="input">
          <input
            type="file"
            name="myfile"
            id="myfile"
            accept="application/pdf,image/*"
            onChange={(e) => handleUpload(e.target.files[0])}
          />
          <label htmlFor="myfile">
            <p>{photo ? "Replace Document" : "Upload document"}</p>
            <img src={uploadIcon} alt="" />
          </label>
          <p style={{ fontSize: 13 }}><b>We allow only Images or PDF to be uploaded here.</b></p>
          <div style={{ width: "100%", paddingLeft: 10 }}>
            {
              isLoading ? <DotLoader /> : (
                photo ? (
                  getExtension(photo) === "pdf" ? <p style={{ marginTop: 10, fontSize: 13, textAlign: "center" }}>PDF File Uploaded Successfully</p> : <img style={{ width: 150, borderRadius: 10 }} src={showImage(photo)} alt="" />
                ) : null
              )
            }
          </div>
        </div>

      </div>
      <div className="bottom">
        <Button
          onClick={updateHandler}
          isLoading={isProfileUpdateLoading}
          name={(location.state === "editprofile") ? "Update" : "Next"}
          type="fill"
          padding="14px 29px"
        />
        <Button
          onClick={() => navigate(nextPage())}
          name="Skip"
          type="outline"
          padding="14px 29px"
        />
      </div>
    </div>
  );
};

export default UploadCollegeId;
