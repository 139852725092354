import React from 'react'

const TypingMsg = () => {
  return (
    <div className="typing-msg">
      <span></span><span></span><span></span>
    </div>
  )
}

export default TypingMsg