import { call, put, takeEvery } from "redux-saga/effects";
import {
    FETCH_ADVERTISEMENTS_REQUESTED,
    MARK_ADVERTISEMENT_ACTION_REQUESTED
} from "../actionTypes";

import { fetchAdvertisementsService, markAdvertisementActionService } from "../../services/advertisement";

import {
    fetchAdvertisementsSuccess,
    fetchAdvertisementsFailure,
    markAdvertisementActionSuccess,
    markAdvertisementActionFailure,
} from "../actions";

function* fetchAdvertisementsSaga(action) {
    try {
        const advertisements = yield call(fetchAdvertisementsService, action.payload);
        if (advertisements.success) {
            yield put(fetchAdvertisementsSuccess(advertisements.data));
        }
    } catch (e) {
        yield put(fetchAdvertisementsFailure(e));
    }
}

function* markAdvertisementActionSaga(action) {
    try {
        const advertisementAction = yield call(markAdvertisementActionService, action.payload);
        if (advertisementAction.success) {
            yield put(markAdvertisementActionSuccess(advertisementAction.data));
        }
    } catch (e) {
        yield put(markAdvertisementActionFailure(e));
    }
}

function* chatsSaga() {
    yield takeEvery(FETCH_ADVERTISEMENTS_REQUESTED, fetchAdvertisementsSaga);
    yield takeEvery(MARK_ADVERTISEMENT_ACTION_REQUESTED, markAdvertisementActionSaga);
}

export default chatsSaga;
