import { createStore, applyMiddleware, compose } from "redux";
import { composeWithDevTools } from '@redux-devtools/extension';
import createSagaMiddleware from 'redux-saga';
import rootReducer from "./reducers";
import rootSaga from './saga';

const sagaMiddleware = createSagaMiddleware();
const store = compose(
  composeWithDevTools(applyMiddleware(sagaMiddleware)),
)(createStore)(rootReducer)

sagaMiddleware.run(rootSaga);
export default store;