import dayjs from "dayjs";

export const formatDateHourMins = (date) => {
  return dayjs(date).format("HH:mm");
};

export const formatDate = (date) => {
  if(!date) return ""
  return dayjs(date).format("DD MMM, hh:mm A");
};

export const formatDateGood = (date) => {
  if(!date) return ""
  return dayjs(date).format("MMMM DD, YYYY");
};

export const debounce = (func, time) => {
  let timer;
  return function (...args) {
    const context = this;
    if (timer) clearTimeout(timer);
    else func.apply(context, args);
    timer = setTimeout(() => {
      timer = null;
      func.apply(context, args);
    }, time);
  };
};

export const getStaticUrl = (url) => {
  return (url.indexOf("http") === 0) ? url : `${process.env.REACT_APP_STATIC_FILE_BASE_URL}${url}`  
}