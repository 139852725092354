import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import {
  toggleReportProfileModal,
  updateProfileDecisionRequested,
} from "../../store/actions";
import { useNavigate } from "react-router-dom";
import Loader from "../../common/loader";

function ReportProfileModal({ onReport = () => {} }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const textareaRef = useRef(null);

  const { reportProfileModalActiveId, isDecisionLoading } = useSelector(
    (state) => state.like
  );

  const [reportReason, setReportReason] = useState("");

  const reportThisProfile = () => {
    dispatch(
      updateProfileDecisionRequested({
        data: {
          reportReason,
          msg: "",
          user: reportProfileModalActiveId,
          userRating: 0,
        },
        isRedirect: false,
        navigate,
        onSuccess: () => {
          onReport(reportProfileModalActiveId, 0);
          toast.success(
            "We have accepted your request and will act accordingly"
          );
        },
      })
    );
  };

  useEffect(() => {
    if (reportProfileModalActiveId && textareaRef.current) {
      textareaRef.current.focus();
    }
  }, [reportProfileModalActiveId]);

  return (
    <div
      className={`z-50 flex justify-center items-center overflow-auto left-0 top-0 bottom-0 right-0 w-full h-full fixed transition-opacity duration-300 ${
        reportProfileModalActiveId ? "opacity-100" : ""
      }`}
    >
      <div
        className={`z-50 flex flex-col relative border-2 border-[#F7544D] bg-white rounded-[22px] p-3 mx-auto my-0 max-w-md min-w-xs w-[80%] transform scale-0 transition duration-500 ease-in-out ${
          reportProfileModalActiveId ? "scale-100" : ""
        }`}
      >
        {isDecisionLoading ? (
          <div className="h-[200px] w-full flex flex-col items-center justify-center">
            <div className="h-[100px]">
              <Loader />
            </div>
            <p className="text-[12px]  font-semibold text-[#181818]">
              Hang on, we are reporting this profile.
            </p>
          </div>
        ) : (
          <>
            <p className="flex pt-3 text-[16px]  font-semibold text-[#181818]">
              Report this profile
            </p>
            <p className="flex py-2 text-[12px]  font-semibold text-[#181818]">
              This action is irreversible.
            </p>
            <textarea
              ref={textareaRef}
              placeholder="Please let us know your grievances"
              onChange={(e) => setReportReason(e.target.value)}
              value={reportReason}
              style={{ color: "#FFFFFF!important" }}
              className="bg-black duration-300 focus:ring-0 appearance-none focus:outline-none p-2 px-3 h-32 rounded-[10px] font-semibold text-white  text-[12px] w-full outline-none"
            ></textarea>
          </>
        )}

        <div className="w-full flex justify-center">
          <button
            disabled={isDecisionLoading}
            onClick={() => dispatch(toggleReportProfileModal(null))}
            style={{
              background:
                "linear-gradient(90deg, #FC5849 0%, #D1396B 33.85%, #C02E78 67.71%, #950F9A 100%)",
            }}
            className="cursor-pointer my-3 mx-2  text-[12px] font-semibold text-white px-5 py-2  rounded-[29px] leading-[11px]"
          >
            Cancel
          </button>
          <button
            disabled={isDecisionLoading}
            onClick={reportThisProfile}
            style={{
              background:
                "linear-gradient(90deg, #FC5849 0%, #D1396B 33.85%, #C02E78 67.71%, #950F9A 100%)",
            }}
            className="cursor-pointer my-3 mx-2  text-[12px] font-semibold text-white px-5 py-2  rounded-[29px] leading-[11px]"
          >
            Report
          </button>
        </div>
      </div>
      <div
        className={`z-40 overflow-auto left-0 top-0 bottom-0 right-0 w-full h-full fixed bg-black opacity-50 ${
          reportProfileModalActiveId ? "visible" : "invisible"
        }`}
      ></div>
    </div>
  );
}

export default ReportProfileModal;
