import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import RangeBar from "../../../components_tailwindcss/RangeBar";
import HorizontalLoader from "../../../components/General/Loaders/HorizontalLoader";
import { PROFILE_PAGES, profileSetupNextPage, profileSetupPrevPage } from "../../../utils/profile";
import INTERESTS from "../../../assets/data/interests.json";
// Actions
import {
  useUpdateMyProfile,
  useMyProfile,
  useGetBioRecommendation,
} from "../../../react-query/profile/useProfile";
import toast from "react-hot-toast";
import Tag from "../../../components/AllProfiles/Tag";
import DotLoader from "../../../components/General/Loaders/DotLoader";

const ProfileBio = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const type = "bio";
  const { data: profile } = useMyProfile({ enabled: false });
  const [val, setVal] = useState(
    profile && profile[type] ? profile[type] : PROFILE_PAGES[type].initial_value
  );
  const [error, setError] = useState(false);
  const [recommendationIndex, setRecommendationIndex] = useState(null);
  const [showCustomBio, setShowCustomBio] = useState(false);

  const item = PROFILE_PAGES[type];

  const {
    data: profileDescriptionRecommendations,
    refetch: fetchBioRecommentations,
    isLoading: isProfileDescriptionRecommendationLoading,
    isFetching: isProfileDescriptionRecommendationFetching,
  } = useGetBioRecommendation({ enabled: false });

  const nextPage = () => {
    return profileSetupNextPage(type, profile, location.state);
  };

  const prevPage = () => {
    return profileSetupPrevPage(type, profile, location.state);
  };

  const onUpdateMyProfileSuccess = () => {
    navigate(nextPage());
  };

  const { isLoading: isProfileUpdateLoading, mutate: mutateUpdateProfile } =
    useUpdateMyProfile({ onSuccess: onUpdateMyProfileSuccess });

  const updateHandler = () => {
    if (!val || !val.length) {
      setError(true);
      return;
    }

    mutateUpdateProfile({ bio: val });
    setError(false);
  };

  const handleChange = (e) => {
    setVal(e.target.value);
    setRecommendationIndex(null);
    setError(false);
  };

  const selectRecommendation = (i) => {
    setRecommendationIndex(i);
    setVal(profileDescriptionRecommendations[i]);
  };

  useEffect(() => {
    fetchBioRecommentations();
  }, [fetchBioRecommentations]);

  return (
    <div className="flex  w-full flex-col items-center  bg_login">
      <div className="flex flex-col relative w-[80%] min-h-screen">
        <div className="my-5 flex-1 flex flex-col">
          {location.state !== "editprofile" ? (
            <RangeBar step={100} height={19} />
          ) : null}

          <div className="flex-1">
            <p className="mt-[20px]  font-bold text-[26px] text-center">
              Craft Your Story
            </p>

            <p className="text-center  text-[10px] font-light text-[#000]">
              AI Generated Bio using ChatGPT
            </p>
            <div
              className="flex mt-5 mb-2 mx-auto w-full text-center w-[100%] overflow-y-scroll"
              style={{
                height: "calc(100vh - 11rem - 150px)",
              }}
            >
              <div className="tags w-full flex flex-wrap">
                {isProfileDescriptionRecommendationLoading ||
                isProfileDescriptionRecommendationFetching ? (
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "center",
                    }}
                  >
                    <DotLoader />
                  </div>
                ) : (
                  <>
                    {profileDescriptionRecommendations.map(
                      (recom, recomIndex) => {
                        return (
                          <div
                            key={`recom-${recomIndex}`}
                            onClick={(e) => selectRecommendation(recomIndex)}
                            className={`bio-recommendation ${
                              recommendationIndex === recomIndex
                                ? "selected"
                                : ""
                            }`}
                          >
                            {recom}
                          </div>
                        );
                      }
                    )}

                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "space-between",
                      }}
                    >
                      <span
                        style={{
                          fontSize: 12,
                          textDecoration: "underline",
                          fontStyle: "italic",
                        }}
                        onClick={() => setShowCustomBio(!showCustomBio)}
                      >
                        Want to add your own Bio?
                      </span>
                      <span
                        style={{
                          fontSize: 12,
                          textDecoration: "underline",
                          fontStyle: "italic",
                        }}
                        onClick={() => fetchBioRecommentations()}
                      >
                        Regenerate
                      </span>
                    </div>

                    <div
                      style={{ marginTop: 4, paddingBottom: 40 }}
                      className="input"
                    >
                      <textarea
                        style={{
                          display: `${showCustomBio ? "flex" : "none"}`,
                        }}
                        onChange={handleChange}
                        value={val}
                        type="text"
                        placeholder={item.placeholder}
                        id="name"
                        cols="30"
                        rows="10"
                        maxLength={200}
                      ></textarea>

                      {error && (
                        <div className="error" style={{ padding: "10px 0px" }}>
                          <span>Please enter a valid bio</span>
                        </div>
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>

          <div className="w-full relative right-0 flex justify-between">
            <button
              onClick={() => navigate(prevPage())}
              className="h-[47px] w-[100px] text-[18px] font-bold  "
            >
              Back
            </button>

            <button
              onClick={updateHandler}
              type="button"
              className="h-[47px] w-[100px] bg-white text-[18px] font-bold  px-4 py-2.5 rounded-[10px]"
            >
              {isProfileUpdateLoading ? <HorizontalLoader /> : "Next"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileBio;
