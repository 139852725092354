import React from "react";
import "../assets/css/ready-to-go.scss";
import Button from "../components/Buttons/Button";
import right from "../assets/images/right.png";
import bottomCurve from "../assets/images/bottom-curve.png";
import { useNavigate } from "react-router";
import { useMeasure } from 'react-use'
import Confetti from 'react-confetti'
import { useDispatch, useSelector } from "react-redux";
import { trackEventRequested } from "../store/actions";

const ProfileReadyToGo = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [ref, { width, height }] = useMeasure()
  const title = "We are ready to go";
  const { profile } = useSelector(state => state.profile)

  const handler = () => {
    dispatch(trackEventRequested({ eventName: "READY_TO_GO", eventBody: {} }))
    navigate("/loginhandler")
  }
  return (
    <>
      <Confetti
        width={width}
        height={height}
      />
      <div ref={ref} className="top-header-common readytogo">

        <div className="bg-image">
          <img src={bottomCurve} alt="" />
        </div>

        <div className="top">
          {profile?.gender && (profile.gender === "F") ? <div className="important-note">Congratulations 🎉🎉 We are offering you free Idyll PRO Plan.</div> : null
          }
          <div className="circle">
            <div className="inner-circle">
              <img src={right} alt="" />
            </div>
          </div>
          <div className="title">{title}</div>
          <Button onClick={() => handler()} name="Next" type="fill" padding="14px 29px" />
        </div>
      </div>
    </>

  );
};

export default ProfileReadyToGo;
