import React from "react";
import "../../assets/css/tag.scss";

const Tag = ({ icon, title, iconWidth = 32, active, backgroundColor="#fff8e8", fontColor="#242424", isPointerEventsNone=false }) => {

  const tagClassName = `${active ? "bg-white" : `bg-[${backgroundColor}]`}`
  return (
    <div
      className={`${isPointerEventsNone ? "pointer-events-none" : ""} border border-black rounded-md tag btn ${tagClassName}`}
    >
      {icon && (
        <div className="icon">
          <img alt="" className="pr-1" src={icon} width={iconWidth} />
        </div>
      )}
      <div className="text" style={{color : fontColor}}>
        {title}
      </div>
    </div>
  );
};

export default Tag;
