import React from 'react'
import Logo from "../../assets/images/idyll-logo.svg";
import filterIcon from "../../assets/images/icons/random-profiles/filter.svg";
import refreshIcon from "../../assets/images/icons/random-profiles/refresh.svg"
import clockIcon from "../../assets/images/icons/random-profiles/blind-date.svg"
import { updateProfileFilterModalActive, updateStreakModalActive, fetchRandomProfilesRequested } from "../../store/actions"
import { useDispatch, useSelector } from 'react-redux';
import ProfilesFilter from './ProfilesFilter';
import CurrentStreak from '../DailyStreak/DailyStreak';
import Modal from '../General/Modals/Modal';
import { useNavigate } from 'react-router-dom';

function Header() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { filterModalActive } = useSelector(state => state.profile)
    const { profileFilters, streakModalActive } = useSelector(state => state.like)
    return (
        <>
            
            <div className="header">
                <img src={Logo} className='logo btn' alt="" />
                <div className="right">
                    {/* <img src={searchIcon} className='btn' alt="" /> */}
                    <div style={{ position: "relative", display: "flex" }}><img onClick={() => navigate("/blind-dating")} alt="" className='btn' style={{ height: 30, marginRight: 5 }} src={clockIcon} /></div>
                    <img onClick={() => dispatch(fetchRandomProfilesRequested({filters : profileFilters}))} alt="" className='btn' style={{ height: 45, marginRight: 5 }} src={refreshIcon} />
                    <img onClick={() => dispatch(updateProfileFilterModalActive(true))} src={filterIcon} style={{ height: 39 }} className='btn' alt="" />
                </div>
            </div>
            <Modal title={`Filters Profiles`} show={filterModalActive} onHide={() => dispatch(updateProfileFilterModalActive(false))}>
                <div className="compliment-container">
                    <ProfilesFilter />
                </div>
            </Modal>

            <Modal title={`Streak`} show={streakModalActive} onHide={() => dispatch(updateStreakModalActive(false))}>
                <div className="compliment-container">
                    <CurrentStreak onContinue={() => dispatch(updateStreakModalActive(false))} />
                </div>
            </Modal>
        </>
    )
}

export default Header