import React from "react";
// Images
import { ReactComponent as Pref } from "../../assets/img/icons/pref.svg";
import { ReactComponent as Refresh } from "../../assets/img/icons/refresh.svg";
// import { ReactComponent as BlindDate } from "../../assets/images/icons/random-profiles/blind-date.svg";
import { ReactComponent as Logo } from "../../assets/img/Delivery/idyll_logo.svg";
import {
  fetchRandomProfilesRequested,
  updateProfileFilterModalActive,
} from "../../store/actions";
import { useDispatch, useSelector } from "react-redux";

function ReelsPageHeader({type}) {
  const dispatch = useDispatch();
  const profileFilters = useSelector((state) => state.like.profileFilters);

  return (
    <div className="w-full relative flex justify-center">
      <div className="flex z-20 h-[70px] w-full justify-between items-center px-6">
        <div className="flex-1"><Logo /></div>
        { type === "likedyou" ? <div style={{fontFamily: 'CeraProMedium, sans-serif'}} className="rounded-md bg-black text-white px-3 py-1 text-md justify-center text-center">RATED ME</div> : null }
        
        {/* <div className="flex gap-x-5 flex-1 justify-end"> */}
          {/* <BlindDate
              onClick={() => navigate("/blind-date")}
              className="h-[18px] cursor-pointer"
            /> */}
          {/* <Refresh
            onClick={() =>
              dispatch(
                fetchRandomProfilesRequested({ filters: profileFilters })
              )
            }
            className="w-[18px] h-[18px] cursor-pointer"
          /> */}
          {/* { type === "all" ?  <Pref
            className="w-[18px] h-[18px] cursor-pointer"
            onClick={() => dispatch(updateProfileFilterModalActive(true))}
          /> : null } */}
         
        {/* </div> */}
      </div>
    </div>
  );
}

export default ReelsPageHeader;
