import React, {
  useState,
  useRef,
  useEffect,
  useMemo,
  useCallback,
} from "react";
import { Player } from "@remotion/player";
import {
  Sequence,
  useCurrentFrame,
  useVideoConfig,
  interpolate,
  spring,
  AbsoluteFill,
} from "remotion";
import { useMeasure } from "@uidotdev/usehooks";
// import { useInView } from "react-intersection-observer";
import { showImage } from "../../utils/profile";
import { getStaticUrl } from "../../utils/common";
import ReportProfile from "./ReportProfile";
// import { updateCurrentProfileIndex } from "../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import RatingTag from "./RatingTag";
import MusicTag from "./MusicTag";
import ReelRating from "./ReelRating";
import FullPageLoader from "./FullPageLoader";
import RobustAudioLoader from "./RobustAudioLoader";

const TRANSITION_DURATION = 30; // 1 second at 30 fps

const preloadImage = (src) =>
  new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => resolve(img);
    img.onerror = reject;
    img.src = src;
  });

const RemotionVideo = React.memo(({ images, musicUrl, isMusicMuted }) => {
  const [preloadedImages, setPreloadedImages] = useState([]);
  const [isPreloaded, setIsPreloaded] = useState(false);
  const { fps, durationInFrames } = useVideoConfig();
  const frameDuration = 2 * fps; // 2 seconds per image

  const totalImagesDuration = images.length * frameDuration;
  const loopCount = Math.ceil(durationInFrames / totalImagesDuration);

  const loopedImages = useMemo(() => {
    return Array(loopCount).fill(images).flat();
  }, [images, loopCount]);

  useEffect(() => {
    const preloadAllImages = async () => {
      const uniqueImages = [...new Set(images)];
      const loadedImages = await Promise.all(
        uniqueImages.map(
          (img) => preloadImage(img).catch(() => null) // Handle failed image loads
        )
      );
      setPreloadedImages(loadedImages.filter(Boolean)); // Filter out failed loads
      setIsPreloaded(() => true);
    };

    preloadAllImages();
  }, [images]);

  if (!isPreloaded) {
    return <FullPageLoader />;
  }

  return (
    <>
      <RobustAudioLoader src={musicUrl} muted={isMusicMuted} />
      {loopedImages.map((photo, index) => (
        <Sequence
          from={index * frameDuration}
          durationInFrames={frameDuration + TRANSITION_DURATION}
          key={`${photo}-${index}`}
        >
          <PhotoTransition
            photo={photo}
            startFrame={0}
            isFirstImage={index === 0}
          />
        </Sequence>
      ))}
    </>
  );
});

const PhotoTransition = React.memo(({ photo, startFrame, isFirstImage }) => {
  const [imageError, setImageError] = useState(false);
  const frame = useCurrentFrame();
  const { fps } = useVideoConfig();

  const progress = spring({
    fps,
    frame: frame - startFrame,
    config: {
      damping: 200,
    },
  });

  const opacity = isFirstImage
    ? 1
    : interpolate(frame - startFrame, [0, TRANSITION_DURATION], [0, 1], {
        extrapolateRight: "clamp",
      });

  const handleImageError = () => {
    setImageError(true);
  };

  return (
    <AbsoluteFill>
      <div
        style={{
          width: "100%",
          height: "100%",
          overflow: "hidden",
        }}
      >
        {!imageError ? (
          <img
            src={photo}
            alt=""
            onError={handleImageError}
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              opacity,
            }}
          />
        ) : (
          <div
            style={{
              width: "100%",
              height: "100%",
              backgroundColor: "black",
              opacity,
            }}
          />
        )}
      </div>
    </AbsoluteFill>
  );
});

const ProfileDetails = React.memo(
  ({
    profile,
    profileIndex,
    advertisements,
    isRatingDisabled = false,
    onRate = () => {},
  }) => {
    const [ScoreModal, setScoreModal] = useState(false);
    const isMusicMuted = useSelector((state) => state.like.isMusicMuted);
    const active = useSelector((state) => state.like.currentIndex);

    const playerRef = useRef(null);
    const [ref, { width, height }] = useMeasure();

    const handleScoreModalChange = useCallback((isActive) => {
      setScoreModal(isActive);
    }, []);

    const playerInputProps = useMemo(
      () => ({
        images: profile.photos.map((img) => showImage(img)),
        musicUrl: getStaticUrl(profile?.current_music.url),
        title: profile.final_name,
        description: profile.bio,
        isMusicMuted: isMusicMuted,
      }),
      [profile, isMusicMuted]
    );

    const playerProps = {
      ref: playerRef,
      component: RemotionVideo,
      inputProps: playerInputProps,
      durationInFrames: Math.floor(profile?.current_music.duration * 30),
      compositionWidth: parseInt(Math.floor(width)),
      compositionHeight: parseInt(Math.floor(height)),
      fps: TRANSITION_DURATION,
      style: { width: "100%", height: "100%" },
      loop: true,
      controls: false,
      autoPlay: profileIndex===0 ? true : false,
    };

    useEffect(() => {
      if (playerRef.current) {
        if (profileIndex === active) {
          console.log("Active Changed: ", profileIndex, active);
          playerRef.current.play();
        } else {
          playerRef.current.pause();
        }
      }
    }, [active, profileIndex]);

    console.log({ isAutoPlay: profileIndex === active, profileIndex, active });

    return (
      <div
        // ref={mainRef}
        id="Reel"
        className={`flex ${
          profileIndex < active ? "opacity-100" : ""
        } px-5 relative flex-col justify-center items-center`}
      >
        <div
          style={{ height: "calc(100vh - 140px)" }}
          ref={ref}
          className="relative rounded-[22px] w-full overflow-hidden"
        >
          <div className="flex w-full justify-between absolute top-0 z-10">
            <MusicTag title={`${profile?.current_music.title}`} />
            <div className="flex flex-col">
              <ReportProfile profileId={profile._id} />
              {profile?.swipe_rating && (
                <RatingTag rating={profile.swipe_rating} />
              )}
            </div>
          </div>

          <div
            className={`w-full h-full ${
              profileIndex === active && ScoreModal ? "blur" : ""
            }`}
          >
            {width && height && (
              <Player {...playerProps} />
            )}
          </div>
        </div>

        <ReelRating
          profile={profile}
          setScoreModal={handleScoreModalChange}
          ScoreModal={ScoreModal}
          onSuccess={onRate}
          isRatingDisabled={isRatingDisabled}
        />
        
      </div>
    );
  }
);

export default ProfileDetails;
