import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { ReactComponent as Upload } from "../../../assets/img/icons/upload.svg";
import { uploadFile } from "../../../services/upload";
import { showImage, PROFILE_PAGES, profileSetupNextPage, profileSetupPrevPage } from "../../../utils/profile";
import toast from "react-hot-toast";
import {
  useUpdateMyProfile,
  useMyProfile,
} from "../../../react-query/profile/useProfile";
import HorizontalLoader from "../../../components/General/Loaders/HorizontalLoader";
import { CheckCircleIcon } from "@heroicons/react/24/solid";
import RangeBar from "../../../components_tailwindcss/RangeBar";

const Login = () => {
  const type = "college_id_card"
  const navigate = useNavigate();
  const location = useLocation();
  const title = `Upload your College ID to get your yellow tick`;
  const subtitle = `Aren't yellow ticks better than the blue ones? Plus we don't charge you for ticks!`;
  const PROFILE_PAGES_SETUP_KEYS = Object.keys(PROFILE_PAGES).filter(
    (k) => PROFILE_PAGES[k].isSetupScreen
  );

  const currentPageIndex = PROFILE_PAGES_SETUP_KEYS.findIndex(
    (k) => k === "college_id_card"
  );

  const [isLoading, setIsLoading] = useState(false);
  const { data: profile } = useMyProfile({ enabled: false });
  const [photo, setPhoto] = useState(profile?.college_id_card);

  const handleUpload = (file) => {
    if (!file) return;

    if (!file.type.includes("image/") && !file.type.includes("pdf")) {
      return toast.error("We allow only images/pdf to be uploaded");
    }

    setIsLoading(true);
    uploadFile({ file })
      .then((data) => {
        setPhoto(data.filePath);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getExtension = (filename) => {
    return filename.split(".").pop();
  };

  const nextPage = () => {
    return profileSetupNextPage(type, profile, location.state);
  };

  const prevPage = () => {
    return profileSetupPrevPage(type, profile, location.state);
  };

  const onUpdateMyProfileSuccess = () => {
    navigate(nextPage());
  };

  const { isLoading: isProfileUpdateLoading, mutate: mutateUpdateProfile } =
    useUpdateMyProfile({ onSuccess: onUpdateMyProfileSuccess });

  const updateHandler = () => {
    if (!photo) return toast.error("Please upload a valid ID Card or Skip");
    mutateUpdateProfile({ college_id_card: photo });
  };

  return (
    <div className="flex w-full flex-col items-center  bg_login">
      <div className="flex flex-col relative w-[80%] min-h-screen">
        <div className="my-5 flex-1 flex flex-col">
          {location.state !== "editprofile" ? (
            <RangeBar step={75} height={19} />
          ) : null}

          <div className="flex-1">
            <p className="mt-[170px]   font-bold text-[23px] text-center">
              {title}
            </p>
            <p className="mb-4 text-center  text-[10px] font-light text-[#000]">
              {subtitle}
            </p>

            <div className="flex items-center justify-center relative  mb-2 mx-auto w-full   bg-white rounded-[15px] border-none">
              <input
                onChange={(e) => handleUpload(e.target.files[0])}
                type="file"
                id="collegeId"
                className="top-0 left-0 opacity-0 w-full h-full py-[14px] px-[5px]"
              />
              <label
                for="collegeId"
                className="px-3 my-auto absolute left-0  w-full  text-[16px]  font-light text-[#000]"
              >
                College ID {photo ? "Uploaded" : ""}
              </label>
              {photo ? (
                <CheckCircleIcon className="absolute right-4 h-6 w-6 text-lime-600" />
              ) : (
                <Upload
                  for="collegeId"
                  className="absolute right-4"
                  onClick={() => document.getElementById("collegeId").click()}
                />
              )}
            </div>
            <p className="my-4 text-center  text-[20px] font-light text-[#000]">
              College ID is optional
            </p>
          </div>

          <div className="w-full relative right-0 flex justify-between">
            <button onClick={() => navigate(prevPage())} className="h-[47px] w-[100px] text-[18px] font-bold  ">
              Back
            </button>

            <button
              onClick={updateHandler}
              type="button"
              className="h-[47px] w-[100px] bg-white text-[18px] font-bold  px-4 py-2.5 rounded-[10px]"
            >
              {isProfileUpdateLoading ? <HorizontalLoader /> : "Next"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
