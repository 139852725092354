export const FETCH_ADVERTISEMENTS_REQUESTED = "FETCH_ADVERTISEMENTS_REQUESTED"

export const FETCH_ADVERTISEMENTS_SUCCESS = "FETCH_ADVERTISEMENTS_SUCCESS"

export const FETCH_ADVERTISEMENTS_FAILURE = "FETCH_ADVERTISEMENTS_FAILURE"

export const MARK_ADVERTISEMENT_ACTION_REQUESTED = "MARK_ADVERTISEMENT_ACTION_REQUESTED"

export const MARK_ADVERTISEMENT_ACTION_SUCCESS = "MARK_ADVERTISEMENT_ACTION_SUCCESS"

export const MARK_ADVERTISEMENT_ACTION_FAILURE = "MARK_ADVERTISEMENT_ACTION_FAILURE"