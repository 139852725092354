import axios from "axios"
const { authHeader } = require("../utils/auth")

export const uploadFile = ({ file }) => {
    return new Promise((resolve, reject) => {
        // var reader = new FileReader();
        // reader.readAsDataURL(file);
        // reader.onload = function () {
        //     axios.post(`file-upload/base-64`, {
        //         file: reader.result
        //     }, {
        //         headers: {
        //             ...authHeader(),
        //             'Content-Type': 'application/json'
        //         },
        //     })
        //         .then(res => {
        //             resolve(res.data)
        //         })
        //         .catch(err => {
        //             reject(err)
        //             console.log(err)
        //         })
        // };
        // reader.onerror = function (error) {
        //     reject(error)
        //     console.log('Error: ', error);
        // };
        const formdata = new FormData();
        formdata.append("file", file);
        axios.post(process.env.REACT_APP_FILE_UPLOADER_URL, formdata, {
            headers: {
                ...authHeader(),
                'Content-Type': "multipart/form-data"
            },
        })
            .then(res => {
                resolve(res.data)
            })
            .catch(err => {
                reject(err)
                console.log(err)
            })
    })

}