import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import RangeBar from "../../../components_tailwindcss/RangeBar";
import HorizontalLoader from "../../../components/General/Loaders/HorizontalLoader";
import {
  PROFILE_PAGES,
  checkIfEmail,
  profileSetupNextPage,
} from "../../../utils/profile";
// Actions
import {
  useUpdateMyProfile,
  useMyProfile,
} from "../../../react-query/profile/useProfile";
import toast from "react-hot-toast";

const BasicInfo = () => {
  const type = "basic-info";
  const navigate = useNavigate();
  const location = useLocation();
  const [val, setVal] = useState({ name: "", email: "" });
  const { data: profile } = useMyProfile();

  const nextPage = () => {
    return profileSetupNextPage(type, profile, location.state);
  };

  const onUpdateMyProfileSuccess = () => {
    navigate(nextPage());
  };

  const { isLoading: isProfileUpdateLoading, mutate: mutateUpdateProfile } =
    useUpdateMyProfile({ onSuccess: onUpdateMyProfileSuccess });

  const updateHandler = () => {
    for (const key in val) {
      const keyVal = val[key];
      if (
        !keyVal ||
        !keyVal.length ||
        (key === "email" && !checkIfEmail(keyVal))
      ) {
        return toast.error("Invalid Email Address")
      }
    }

    mutateUpdateProfile(val);
  };

  const handleChange = (key, value) => {
    if(key === "email"){
      setVal({ ...val, [key]: value.toString().trim() });  
    }else{
      setVal({ ...val, [key]: value });
    }
    
  };

  useEffect(() => {
    let profileValue = Object.assign({}, val);
    if (profile) {
      for (const key in val) {
        if (profile[key]) {
          profileValue[key] = profile[key];
        }
      }
      setVal(profileValue);
    }
  }, [profile]);

  return (
    <div className="flex w-full flex-col items-center  bg_login">
      <div className="flex flex-col relative w-[80%] min-h-screen">
        <div className="my-5 flex-1 flex flex-col">
          {location.state !== "editprofile" ? (
            <RangeBar step={13} height={19} />
          ) : null}

          <div className="flex-1">
            <p className="mt-[160px]  font-bold text-[26px] text-center">
              Tell us your name?
            </p>
            <p className=" text-center  mb-4   text-[10px] font-light text-[#000]">
              this can't be changed later on...
            </p>
            <div className="flex mt-5 mb-2 mx-auto w-full  text-center ">
              <input
                onChange={(e) => handleChange("name", e.target.value)}
                value={val?.name}
                placeholder="Your name"
                className="px-3 py-[14px] w-full placeholder: placeholder:text-[16px]  placeholder:font-light placeholder:text-[#000] bg-white rounded-[15px] border-none"
              />
            </div>

            {/* <p className=" mt-10 mb-4  font-bold text-[26px] text-center">Enter Email ID</p> */}
            <div className="flex mt-5 mb-2 mx-auto w-full  text-center ">
              <input
                onChange={(e) => handleChange("email", e.target.value)}
                value={val?.email}
                placeholder="Email"
                className="px-3 py-[14px] w-full placeholder: placeholder:text-[16px]  placeholder:font-light placeholder:text-[#000] bg-white rounded-[15px] border-none"
              />
            </div>
          </div>

          <div className="w-full relative right-0 flex justify-end">
            <button
              onClick={() => updateHandler()}
              className="h-[47px] w-[100px] bg-white text-[18px] font-bold  px-4 py-2.5 rounded-[10px]"
            >
              {isProfileUpdateLoading ? <HorizontalLoader /> : "Next"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BasicInfo;
