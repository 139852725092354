import React from "react";
import { useParams } from "react-router";
import { PROFILE_PAGES } from "../../../utils/profile";
import DefaultInput from "./DefaultInput";
import DefaultSelect from "./DefaultSelect";
import UploadPhotos from "./UploadPhotos";
import UploadCollegeId from "./UploadCollegeId";
import Interests from "./Interests";
import SelectCollege from "./SelectCollege";
import { useSelector } from "react-redux";
import ProfileBio from "./ProfileBio";

const ProfileLayout = () => {
  const { type } = useParams();
  const { isLoading } = useSelector((state) => state.like);
  const item = PROFILE_PAGES[type];
  const pageTypeMapping = {
    DEFAULT_INPUT: <DefaultInput key={`${type}-page`} />,
    DEFAULT_SELECT: <DefaultSelect key={`${type}-page`} />,
    UPLOAD_PHOTOS: <UploadPhotos key={`${type}-page`} />,
    UPLOAD_COLLEGE_ID: <UploadCollegeId key={`${type}-page`} />,
    INTERESTS: <Interests key={`${type}-page`} />,
    SELECT_COLLEGE: <SelectCollege key={`${type}-page`} />,
    PROFILE_BIO: <ProfileBio key={`${type}-page`} />,
  };
  
  return <>{isLoading ? "Loader" : pageTypeMapping[item.page_type]}</>;
};

export default ProfileLayout;
