import React, { useEffect, useState, useCallback } from "react";
import "../../../assets/css/upload-photos.scss";
import TopHeader from "../../../components/AllProfiles/TopHeader";
import ProfileProgressBar from "../../../components/ProfileScreens/ProfileProgressBar";
import Button from "../../../components/Buttons/Button";
import galleryIcon from "../../../assets/images/photos.png";
import bottomCurve from "../../../assets/images/bottom-curve.png";
import { useLocation, useNavigate } from "react-router";
import { profileSetupNextPage, PROFILE_PAGES } from "../../../utils/profile";
import toast from 'react-hot-toast';
import { useUpdateMyProfile, useMyProfile } from "../../../react-query/profile/useProfile";
import UploadPhoto from "../../../components/ProfileScreens/UploadPhoto";
import DotLoader from "../../../components/General/Loaders/DotLoader";

const UploadPhotos = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const title = "Add your photos";
  const subtitle = "Upload good quality photos for better match prospects";

  const [isLoading, setIsLoading] = useState(false);
  const { data: profile, isLoading: isMyProfileLoading } = useMyProfile();
  const [photos, setPhotos] = useState([]);
  const [error, setError] = useState(false);

  const PROFILE_PAGES_SETUP_KEYS = Object.keys(PROFILE_PAGES).filter(k => PROFILE_PAGES[k].isSetupScreen);
  const currentPageIndex = PROFILE_PAGES_SETUP_KEYS.findIndex(k => k === "photos");

  const nextPage = useCallback(() => {
    return profileSetupNextPage("photos", profile, location.state);
  }, [profile, location.state]);

  const onUpdateMyProfileSuccess = useCallback(() => {
    navigate(nextPage());
  }, [navigate, nextPage]);

  const { isLoading: isProfileUpdateLoading, mutate: mutateUpdateProfile } = useUpdateMyProfile({ onSuccess: onUpdateMyProfileSuccess });

  const updateHandler = useCallback(() => {
    if (isLoading) return toast.error("Please wait for the photo to upload");

    if (photos.filter(Boolean).length === 0) {
      setError(true);
      return;
    }

    mutateUpdateProfile({ photos: photos.filter((p) => !!p) });
    setError(false);
  }, [isLoading, photos, mutateUpdateProfile]);

  useEffect(() => {
    if (!isMyProfileLoading && profile) {
      setPhotos(profile?.photos || [null, null, null, null]);
    }
  }, [isMyProfileLoading, profile]);

  if (isMyProfileLoading || !profile) {
    return (
      <div style={{display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center"}}>
        <DotLoader />
      </div>
    );
  }

  return (
    <div className="top-header-common upload-photo">
      {location.state !== "editprofile" && (
        <ProfileProgressBar currentPage={currentPageIndex + 1} totalPages={PROFILE_PAGES_SETUP_KEYS.length} />
      )}
      <div className="bg-image">
        <img src={bottomCurve} alt="" />
      </div>

      <div className="top">
        <TopHeader icon={galleryIcon} title={title} subtitle={subtitle} />
        <div className="image-container">
          <div className="column">
            <UploadPhoto photos={photos} url={photos[0]} index={0} setPhotos={setPhotos} setIsFileUploading={setIsLoading} />
            <UploadPhoto photos={photos} url={photos[2]} index={2} setPhotos={setPhotos} setIsFileUploading={setIsLoading} />
          </div>
          <div className="column">
            <UploadPhoto photos={photos} url={photos[1]} index={1} setPhotos={setPhotos} setIsFileUploading={setIsLoading} />
            <UploadPhoto photos={photos} url={photos[3]} index={3} setPhotos={setPhotos} setIsFileUploading={setIsLoading} />
          </div>

          {error && (
            <div className="error" style={{ padding: "0 10px" }}>
              <span>Please upload at least one photo</span>
            </div>
          )}
        </div>
        <p style={{ fontSize: "13px", lineHeight: "16px", color: "#ffff45" }}>
          Note we have a zero tolerance policy towards fake profiles. Your account may be disabled if found using fake images.
        </p>
      </div>
      <div className="bottom">
        <Button
          onClick={updateHandler}
          isLoading={isProfileUpdateLoading}
          name={(location.state === "editprofile") ? "Update" : "Next"}
          type="fill"
          padding="14px 29px"
        />
      </div>
    </div>
  );
};

export default UploadPhotos;