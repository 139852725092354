import React from "react";
import { ReactComponent as Arrow } from "../assets/img/icons/arrow-left.svg";
import Navigation from "../components_tailwindcss/Navigation";
import { useNavigate } from "react-router-dom";
import {
  useDeleteUser,
  useMyProfile,
  useUpdatePrivacySettingsUser,
} from "../react-query/profile/useProfile";
import toast from "react-hot-toast";
import HorizontalLoader from "../components/General/Loaders/HorizontalLoader";

const deleteCards = [
  {
    title: "Ghost Mode",
    slug: "ghost-mode",
    cardClasses: "bg-[#00C9FF]",
    buttonText: "Try now",
    questions: [
      {
        question: "Can others view my profile?",
        answer: "No",
      },
      {
        question: "Can I re-login and activate it?",
        answer: "Yes",
      },
      {
        question: "Will my existing data be saved?",
        answer: "Yes",
      },
      {
        question: "Will I receive notifications?",
        answer: "Yes",
      },
      {
        question: "Can I still access my chats?",
        answer: "Yes",
      },
    ],
  },
  {
    title: "Delete Account",
    slug: "delete-account",
    cardClasses: "bg-[#950F9A] text-white",
    buttonText: "Confirm Delete",
    questions: [
      {
        question: "Can others view my profile?",
        answer: "No",
      },
      {
        question: "Can I create a new account?",
        answer: "Yes",
      },
      {
        question: "Will my existing data be saved?",
        answer: "No",
      },
      {
        question: "Will I receive notifications?",
        answer: "No",
      },
      {
        question: "Can I still access my chats?",
        answer: "No",
      },
    ],
  },
];

export default function DeleteProfile() {
  const navigate = useNavigate();

  const {
    mutate: mutateUpdatePrivacySettings,
    isLoading: isUpdatePrivacySettingsLoading,
  } = useUpdatePrivacySettingsUser({
    onSuccess: () => {
      toast.success("Wohoo! We have saved your settings");
    }
  });

  const {data : myProfile} = useMyProfile()

  const { mutate: mutateDeleteProfile, isLoading: isProfileDeleting } =
    useDeleteUser({
      onSuccess: () => {
        localStorage.clear();
        toast.success("Your profile should be deleted in a few minutes");
        window.location = "/login";
      },
    });

  const deleteHandler = (slug) => {
    if (slug === "ghost-mode") {
      mutateUpdatePrivacySettings({ is_hide_name: false, is_ghost_mode: !myProfile.is_ghost_mode });
    } else if (slug === "delete-account") {
      mutateDeleteProfile();
    }
  };
  return (
    <div className="flex flex-col bg-white w-full w-full h-screen h-max">
      <Arrow
        onClick={() => navigate("/editprofile")}
        className="absolute cursor-pointer w-[11px] h-[11px] mt-10 ml-10"
      />
      <div className="overflow-scroll flex w-full flex-1 flex-col mt-20 items-center px-5 pb-5">
        <p className=" text-center text-[#353D44] mx-auto text-3xl font-bold">
          Account Deletion
        </p>
        <p className="text-center my-2 font-bold text-[12px] ">
          Mat kar bhai, ro dunga🥺
        </p>

        <div className="w-full flex flex-col flex-1 gap-4">
          {deleteCards.map((card, cardIndex) => {
            return (
              <div
                key={`card-${cardIndex}`}
                className={`w-full ${card.cardClasses} px-10 py-8 rounded-2xl`}
              >
                <p className="text-left font-semibold text-xl mb-6">
                  {card.title}
                </p>
                {
                  card.slug === "ghost-mode" && myProfile?.is_ghost_mode ? <p className="font-semibold text-xs mb-3">Currently your profile is on Ghost Mode. Disable it below to fully utilize it!</p> : null
                }
                {card.questions.map((question, questionIndex) => {
                  return (
                    <div
                      key={`question-${questionIndex}`}
                      className="flex items-center mb-2"
                    >
                      <p className="flex-1 text-left font-light text-sm">
                        {question.question}
                      </p>
                      <p className="w-[50px] text-right text-sm">
                        {question.answer}
                      </p>
                    </div>
                  );
                })}

                <div className="w-full flex mt-6 justify-center">
                  <button
                    onClick={() => deleteHandler(card.slug)}
                    className="text-white px-4 font-bold bg-black rounded-[18.5px] py-2 text-sm"
                  >
                    {card.slug === "ghost-mode" ? (
                      isUpdatePrivacySettingsLoading ? (
                        <HorizontalLoader />
                      ) : (
                        myProfile?.is_ghost_mode ? "Disable Ghost Mode" : card.buttonText
                      )
                    ) : isProfileDeleting ? (
                      <HorizontalLoader />
                    ) : (
                      card.buttonText
                    )}
                  </button>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <Navigation />
    </div>
  );
}
