import { all } from "redux-saga/effects";
import profileSaga from "./profile";
import likeSaga from "./like";
import chatsSaga from "./chat";
import paymentSaga from "./payment";
import collegeSaga from "./college";
import eventSaga from "./event";
import appeventSaga from "./appevent";
import advertisementSaga from "./advertisement";

export default function* rootSaga() {
  yield all([profileSaga(), likeSaga(), chatsSaga(), paymentSaga(), collegeSaga(), eventSaga(), appeventSaga(), advertisementSaga()]);
}