import { useState } from "react"
import AsyncSelect from "react-select/async"
import Select from "react-select"
import toast from 'react-hot-toast';
import { fetchCollegesService } from "../../services/college"
import { PROFILE_PAGES } from "../../utils/profile"
import newFeatureIcon from "../../assets/images/icons/general/new-feature-label.png"
import { useDispatch, useSelector } from "react-redux"
import { fetchRandomProfilesRequested, updateProfileFilters, updateProfileFilterModalActive, fetchAppeventsRequested } from "../../store/actions"
import { useEffect } from "react"

const ProfilesFilter = () => {
    const dispatch = useDispatch()
    const { appevents } = useSelector(state => state.appevent)
    const { profileFilters } = useSelector(state => state.like)
    const [college, setCollege] = useState(profileFilters?.college)
    // const [fromHeight, setFromHeight] = useState(profileFilters?.fromHeight || 0)
    // const [toHeight, setToHeight] = useState(profileFilters?.toHeight || 250)
    const [fromAge, setFromAge] = useState(profileFilters?.fromAge || 18)
    const [toAge, setToAge] = useState(profileFilters?.toAge || 100)
    const [whatToFind, setWhatToFind] = useState(profileFilters?.whatToFind)
    const [event, setEvent] = useState(profileFilters?.event)

    const promiseOptions = async (inputValue) => {
        return await fetchCollegesService({ name: inputValue }).then((response) => {
            return response.data.map((row) => {
                return { value: row._id, label: row.name };
            });
        }).catch((err) => {
            return toast.error(err?.response?.data, {
                position: toast.POSITION.TOP_CENTER,
            });
        });
    };

    const debounce = (f, interval) => {
        let timer = null;

        return (...args) => {
            clearTimeout(timer);
            return new Promise((resolve) => {
                timer = setTimeout(() => resolve(f(...args)), interval);
            });
        };
    };

    const saveFilters = () => {
        let filters = {
            college,
            // fromHeight,
            // toHeight,
            fromAge,
            toAge,
            whatToFind,
            event
        }

        dispatch(updateProfileFilters(filters))
        dispatch(fetchRandomProfilesRequested({filters}))
        dispatch(updateProfileFilterModalActive(false))

    }

    useEffect(() => {
        dispatch(fetchAppeventsRequested())
    }, [dispatch])

    return (
        <div className="profile-filter">
            <div className="row">
                <div className="col-12 flex">
                    <div style={{ flexGrow: 1, marginRight: 10 }}>
                        <AsyncSelect
                            menuPlacement="bottom"
                            isClearable={true}
                            loadOptions={debounce(promiseOptions, 500)}
                            onChange={(e) => {
                                setCollege(e)
                            }}
                            defaultValue={college}
                            className="login-form-selectInput"
                            placeholder="Select college"
                            noOptionsMessage={() => "Search college here"}
                        />
                    </div>
                    <img height="35" src={newFeatureIcon} />
                </div>
            </div>

            {/* <div className="row">
                <div className="col-6">
                    <label>Height from {fromHeight} cm</label>
                    <input className="input-range-filter" onChange={(e) => setFromHeight(e.target.value)} type="range" value={fromHeight} min="0" max="250"></input>
                </div>
                <div className="col-6">
                    <label>Height till {toHeight} cm</label>
                    <input className="input-range-filter" onChange={(e) => setToHeight(e.target.value)} type="range" value={toHeight} min="0" max="250"></input>
                </div>
            </div> */}

            <div className="row">
                <div className="col-6">
                    <label>Age from {fromAge} years</label>
                    <input className="input-range-filter" onChange={(e) => setFromAge(e.target.value)} type="range" value={fromAge} min="18" max="100"></input>
                </div>
                <div className="col-6">
                    <label>Age to {toAge} years</label>
                    <input className="input-range-filter" onChange={(e) => setToAge(e.target.value)} type="range" value={toAge} min="18" max="100"></input>
                </div>
            </div>

            <div className="row">
                <div className="col-12">
                    <Select
                        menuPlacement="top"
                        isSearchable={true}
                        isClearable={true}
                        options={PROFILE_PAGES.what_to_find.options.map(row => {
                            return {
                                value: row.value, label: row.name
                            }
                        })}
                        onChange={(e) => {
                            setWhatToFind(e)
                        }}
                        defaultValue={whatToFind}
                        className="login-form-selectInput"
                        placeholder="What to find?"
                    />
                </div>
            </div>

            <div className="row">
                <div className="col-12">
                    <Select
                        menuPlacement="top"
                        isSearchable={true}
                        isClearable={true}
                        options={appevents.map(k => {
                            return {
                                label : k.title,
                                value : k._id
                            }
                        })}
                        onChange={(e) => {
                            setEvent(e)
                        }}
                        defaultValue={event}
                        className="login-form-selectInput"
                        placeholder="Ongoing Events"
                    />
                </div>
            </div>

            <div style={{ justifyContent: "center" }} className="row flex">
                <button onClick={saveFilters} className="btn-add-compliment" name="Send">Save Filters</button>
            </div>

        </div>
    )
}
export default ProfilesFilter