import {
    TOGGLE_PURCHASE_BOOSTS_MODAL,
} from "../actionTypes";

const initialState = {
    isPurchaseBoostsModalOpen : false
};

export default function chatReducer(state = initialState, action) {
    const { type } = action;

    switch (type) {

        case TOGGLE_PURCHASE_BOOSTS_MODAL: {
            return {
                ...state,
                isPurchaseBoostsModalOpen : action.payload
            };
        }

        default:
            return state;
    }
}
