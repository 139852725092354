import "../../../assets/css/loader.scss"

const DotLoader = function () {
    return (
        <div className="dot-loader">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    )
}

export default DotLoader