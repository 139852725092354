import React from "react";
import { useNavigate } from "react-router";
import Tag from "./AllProfiles/Tag";

const EventCard = ({ event }) => {
  const navigate = useNavigate()
  return (
    <div onClick={() => navigate(`/events/${event._id}`)} className="event_card">
      <img src={event.photos && event.photos.length ? event.photos[0] : null} alt="" />
      <div className="content">
        <Tag title={"Online"} />
        <div className="title">{event.title}</div>
      </div>
    </div>
  );
};

export default EventCard;
