import axios from "axios";
import toast from 'react-hot-toast';
const { authHeader } = require("../utils/auth");

export const trackEventService = (data) => {
  return axios
    .post(
      `event`,
      data,
      {
        headers: {
          ...authHeader(),
        },
      }
    )
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      toast.error(err?.response?.data?.msg || err?.response?.data || err?.response || err)
      throw err;
    });
};