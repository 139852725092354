import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import "../assets/css/pricing.scss";
import Heart from "../assets/images/icons/my-profile/upgrade-heart.svg";
import Tag from "./AllProfiles/Tag";
import Button from "./Buttons/Button";
import { Splide, SplideSlide } from "@splidejs/react-splide";
// Plugins
import { Purchases, LOG_LEVEL } from "@revenuecat/purchases-capacitor";
import toast from 'react-hot-toast';
import { useNavigate } from "react-router";
import { useMyProfile } from "../react-query/profile/useProfile";


const Pricing = ({ plan }) => {
  const navigate = useNavigate();
  const { paymentLink, offerings, currentUpgradePlanModalType } = useSelector(
    (state) => state.payment
  );

  const planMapping = {}
  const planDetails = planMapping[plan];
  const [planDuration, setPlanDuration] = useState(1);

  const { isLoading: isMyProfileLoading, data: profile } = useMyProfile();

  const getDiscountKey = () => {
    if (!profile) return null;

    if (profile["is_forty_percent_discount_allowed"]) {
      return "Default 40%";
    } else if (profile["is_twentyfive_percent_discount_allowed"]) {
      return "Default 25%";
    } else if (profile["is_fifteen_percent_discount_allowed"]) {
      return "Default 15%";
    }
    return null;
  };
  let allPackages = [],
    discountedPackages = [];

  if (offerings) {
    allPackages =
      offerings.current.availablePackages.filter((p) =>
        planDetails.packages.includes(p.identifier)
      ) || [];
    allPackages = allPackages.map(p => {
      return { ...p, discountedPackage : null }
    })
    if (plan === "ACE") {
      const currentDiscountKey = getDiscountKey();

      if (currentDiscountKey) {
        discountedPackages =
          offerings.all[currentDiscountKey].availablePackages;
        allPackages = allPackages.map((p) => {
          const discountedPackage = discountedPackages.find(
            (dp) => dp.identifier === p.identifier
          );
          const newP = { ...p, discountedPackage };
          return newP;
        });
      }
    }
  }

  const purchaseFromPlay = async (p) => {
    try {
      const data = await Purchases.purchasePackage({
        aPackage: p,
      });

      console.log({ data });
      const purchaserInfo = data.customerInfo;
      console.log({ purchaserInfo });

      if (purchaserInfo.activeSubscriptions.includes(p.identifier)) {
        // If successfull, then call my responsibilities api again
        toast.success("Subscribed Successfully");
        navigate("/allprofiles");
      }

      return purchaserInfo;
    } catch (err) {
      console.error("listenBuy error", err);
    }

    return null;
  };
  const handlePayment = () => {
    const p = allPackages[planDuration].discountedPackage
      ? allPackages[planDuration].discountedPackage
      : allPackages[planDuration];
    purchaseFromPlay(p);
  };

  const discountString = (planRow) => {
    return (
      100 -
      Math.round(
        (planRow.discountedPackage.product.price / planRow.product.price) * 100
      )
    );
  };

  useEffect(() => {
    if (paymentLink) {
      window.open(paymentLink);
    }
  }, [paymentLink]);
  return plan ? (
    <div className="pricing">
      <div className="img-container">
        <div className="img">
          <img src={Heart} alt="" />
        </div>
      </div>
      <div className="circle-container">
        <div className="circle1"></div>
        <div className="circle2"></div>
      </div>
      <div className="feature-slides">
        <Splide
          aria-label="Ace Features"
          options={{
            pauseOnHover: false,
            pauseOnFocus: false,
            autoplay: true,
            interval: 3000,
            rewind: true,
            arrows: false,
            perPage: 1,
            width: "100%",
            gap: "1rem",
            height: "120px",
          }}
        >
          {planDetails.features.map((r, ri) => {
            return (
              <SplideSlide key={`feature-${ri}`}>
                <div className="feature-slide">
                  <p
                    className={`title${
                      currentUpgradePlanModalType === "ACE" ? "-white" : ""
                    }`}
                  >
                    {r.title}
                  </p>
                  <p
                    className={`subtitle${
                      currentUpgradePlanModalType === "ACE" ? "-white" : ""
                    }`}
                  >
                    {r.subtitle}
                  </p>
                </div>
              </SplideSlide>
            );
          })}
        </Splide>
      </div>
      <div className="card-container">
        {allPackages.map((planRow, planRowIndex) => {
          return (
            <div
              onClick={(e) => setPlanDuration(planRowIndex)}
              key={`plan-${plan}-${planRowIndex}`}
              className={`card ${
                planRowIndex === planDuration ? "selected" : ""
              } ${planRow.isPopular ? "active" : ""}`}
            >
              <Tag title="Most Popular" />
                   
            </div>
          );
        })}
      </div>
      {allPackages[planDuration] ? (
        <div className="button-container">
          <Button
            onClick={() => handlePayment()}
            type="outline"
            name={`Continue to pay ${
              allPackages[planDuration].discountedPackage
                ? allPackages[planDuration].discountedPackage.product
                    .priceString
                : allPackages[planDuration].product.priceString
            }`}
            padding="14px 72px"
          />
        </div>
      ) : null}

      <div className="text3">
        By clicking to pay, you agree to Idyll{" "}
        <a
          style={{ color: "#000", fontWeight: "bold" }}
          href={`${process.env.REACT_APP_LANDING_PAGE_URL}/term-conditions.html`}
        >
          Terms & Conditions
        </a>{" "}
        of Idyll and the{" "}
        <a
          style={{ color: "#000", fontWeight: "bold" }}
          href={`${process.env.REACT_APP_LANDING_PAGE_URL}/refund-policy.html`}
        >
          Refund Policy
        </a>
      </div>
    </div>
  ) : null;
};

export default Pricing;
