import React from "react";
import { Navigate } from "react-router-dom";
import { PROFILE_PAGES } from "../utils/profile";
import { useMyProfile } from "../react-query/profile/useProfile";
import Loader from "../common/loader";

export default function LoginHandler() {
  const { isLoading: isMyProfileLoading, data: profile } = useMyProfile();

  // If profile is fetched from API, navigate to next pages
  if (!isMyProfileLoading && profile) {
    // Checking one by one for all the pages which is empty and required, send there directly
    for (let profileKey in PROFILE_PAGES) {
      const keys_to_check = PROFILE_PAGES[profileKey].keys_to_check;
      console.log(keys_to_check);
      for (const keyToCheck of keys_to_check) {
        if (
          (!profile[keyToCheck] || !profile[keyToCheck].length) &&
          PROFILE_PAGES[profileKey].isSetupScreen &&
          PROFILE_PAGES[profileKey].required
        )
          return (
            <Navigate
              to={`/myprofile/${PROFILE_PAGES[profileKey].page_route}`}
            />
          );
      }
    }

    // If all keys are filled, return to all profiles screen
    return <Navigate to="/loop" />;
  }

  // Show loading states
  return (
    <div className="flex justify-center items-center h-screen w-full">
      <Loader />
    </div>
  );
}
