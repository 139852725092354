import axios from "axios"
import toast from 'react-hot-toast';
import {dataToQueryString} from "../utils/service"

const {authHeader} = require("../utils/auth")

export const fetchLikesMeService = () => {
    return new Promise((resolve, reject) => {
        axios.get(`like/likesme?limit=10`, {
            headers : {
                ...authHeader()
            }
        }).then((res)=>{
            if(!res.data.success){
                toast.error(res.data.msg)
                return reject(res.data.data)
            }
            return resolve(res.data.data)
        }).catch(err => {
            toast.error(err?.response?.data?.msg)
            return reject(err)
        })
    })
}

export const fetchMyMatchesService = (chatType) => {
    return new Promise((resolve, reject) => {
        axios.get(`like/matches?chatType=${chatType}`, {
            headers : {
                ...authHeader()
            }
        }).then((res)=>{
            if(!res.data.success){
                toast.error(res.data.msg)
                return reject(res.data.data)
            }
            return resolve(res.data.data)
        }).catch(err => {
            toast.error(err?.response?.data?.msg)
            return reject(err)
        })
    })
    
}

export const fetchRandomProfilesService = (data) => {
    
    let newData = {}
    for(let key in data){
        const val = data[key]
        if(val !== undefined && val !== null){
            if(["college", "event", "whatToFind"].includes(key)){
                newData[key] = val.value
            }else{
                newData[key] = val
            }
        }
    }

    return new Promise((resolve, reject) => {
        axios.get(`like/random?${dataToQueryString(newData)}`, {
            headers : {
                ...authHeader()
            }
        }).then((res)=>{
            if(!res.data.success){
                toast.error(res.data.msg)
                return reject(res.data.data)
            }
            return resolve(res.data.data)
        }).catch(err => {
            toast.error(err?.response?.data?.msg)
            return reject(err)
        })
    })
    
}

export const fetchUserFeedService = (data) => {
    
    let newData = {}
    for(let key in data){
        const val = data[key]
        if(val !== undefined && val !== null){
            if(["college", "event", "whatToFind"].includes(key)){
                newData[key] = val.value
            }else{
                newData[key] = val
            }
        }
    }

    return new Promise((resolve, reject) => {
        axios.get(`feed?${dataToQueryString(newData)}`, {
            headers : {
                ...authHeader()
            }
        }).then((res)=>{
            if(!res.data.success){
                toast.error(res.data.msg)
                return reject(res.data.data)
            }
            return resolve(res.data.data)
        }).catch(err => {
            toast.error(err?.response?.data?.msg)
            return reject(err)
        })
    })
    
}

export const updateProfileDecisionService = (data) => {
    return new Promise((resolve, reject) => {
        axios.put(`like`, data, {
            headers : {
                ...authHeader()
            }
        }).then((res)=>{
            if(res.data.showToast){
                toast.success(res.data.msg)
            }
            return resolve(res.data)
        }).catch(err => {
            toast.error(err?.response?.data?.msg)
            return reject(err)
        })
    })
}

export const fetchMyBlindMatchService = (data={}) => {
    return new Promise((resolve, reject) => {
        axios.put(`like/get-blind-match`, data, {
            headers : {
                ...authHeader()
            }
        }).then((res)=>{
            if(res.data.showToast){
                toast.success(res.data.msg)
            }
            return resolve(res.data)
        }).catch(err => {
            toast.error(err?.response?.data?.msg)
            return reject(err)
        })
    })
}