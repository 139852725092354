import React, { useState } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import RangeBar from "../../../components_tailwindcss/RangeBar";
import HorizontalLoader from "../../../components/General/Loaders/HorizontalLoader";
import { useDispatch, useSelector } from "react-redux";
import {
  useMyProfile,
  useUpdateMyProfile,
} from "../../../react-query/profile/useProfile";
import {
  PROFILE_PAGES,
  profileSetupNextPage,
  profileSetupPrevPage,
} from "../../../utils/profile";
import { fetchCollegesRequested } from "../../../store/actions";
import toast from "react-hot-toast";

const type = "college";
const Login = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { colleges } = useSelector((state) => state.college);
  const { data: profile } = useMyProfile({ enabled: false });
  const [val, setVal] = useState(
    profile && profile[type] ? profile[type] : PROFILE_PAGES[type].initial_value
  );
  const [showMenu, setShowMenu] = useState(false);

  const collegeSearchHandler = (name) => {
    dispatch(fetchCollegesRequested({ name }));
  };

  const PROFILE_PAGES_SETUP_KEYS = Object.keys(PROFILE_PAGES).filter(
    (k) => PROFILE_PAGES[k].isSetupScreen
  );
  const currentPageIndex = PROFILE_PAGES_SETUP_KEYS.findIndex(
    (k) => k === type
  );
  const item = PROFILE_PAGES[type];

  const nextPage = () => {
    return profileSetupNextPage(type, profile, location.state);
  };

  const prevPage = () => {
    return profileSetupPrevPage(type, profile, location.state);
  };

  const onUpdateMyProfileSuccess = () => {
    navigate(nextPage());
  };

  const { isLoading: isProfileUpdateLoading, mutate: mutateUpdateProfile } =
    useUpdateMyProfile({ onSuccess: onUpdateMyProfileSuccess });

  const handleCollegeInput = (inpVal) => {
    if (!showMenu) {
      setShowMenu(true);
    }
    setVal(inpVal);
    collegeSearchHandler(inpVal);
  };

  const updateHandler = () => {
    let collegeName = val
    if (typeof val === "object" && val[0]){
      collegeName = val[0]
    }

    if (item.required && !collegeName) {
      return toast.error("Your college name is required")
    }

    mutateUpdateProfile({ [type]: collegeName });
  };

  const handleCollegeClick = (name) => {
    setVal(name);
    setShowMenu(false);
  };

  return (
    <div className="flex  w-full flex-col items-center  bg_login">
      <div className="flex flex-col relative w-[80%] min-h-screen">
        <div className="my-5 flex-1 flex flex-col">
          {location.state !== "editprofile" ? (
            <RangeBar step={50} height={19} />
          ) : null}

          <div className="flex-1">
            <p className="mt-[170px]   font-bold text-[23px] text-center">
              {/* Are you a student? */}
              Add your College Name
            </p>
            <p className="mb-4 text-center  text-[10px] font-light text-[#000]">
              {/* Share your college name and search people in your institution */}
              Share your college name if you are a student or a working professional
            </p>
            <div className="flex flex-col mt-5 mb-2 mx-auto w-full  text-center ">
              <input
                onChange={(e) => handleCollegeInput(e.target.value)}
                value={val}
                placeholder="Enter your full college name"
                className="px-3 py-[14px] w-full placeholder: placeholder:text-[16px]  placeholder:font-light placeholder:text-[#000] bg-white rounded-[15px] border-none"
              />

              {showMenu && colleges.length ? (
                <div className="mt-3 college-list">
                  {colleges.map((c, ci) => {
                    return (
                      <div
                        onClick={() => handleCollegeClick(c.name)}
                        className="college-row"
                        key={`college-${ci}`}
                      >
                        {c.name}
                      </div>
                    );
                  })}
                </div>
              ) : null}
            </div>
          </div>

          <div className="w-full relative right-0 flex justify-between">
            <button onClick={() => navigate(prevPage())} className="h-[47px] w-[100px] text-[18px] font-bold  ">
              Back
            </button>

            <button
              onClick={updateHandler}
              type="button"
              className="h-[47px] w-[100px] bg-white text-[18px] font-bold  px-4 py-2.5 rounded-[10px]"
            >
              {isProfileUpdateLoading ? <HorizontalLoader /> : "Next"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
