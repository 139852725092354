import React, { useState, useEffect } from "react";
import RangeBar from "../../../components_tailwindcss/RangeBar";
import UploadPhoto from "../../../components_tailwindcss/ProfileScreens/UploadPhoto";
import { useLocation, useNavigate } from "react-router";
import {
  profileSetupPrevPage,
  profileSetupNextPage,
  PROFILE_PAGES,
} from "../../../utils/profile";
import toast from "react-hot-toast";
import {
  useUpdateMyProfile,
  useMyProfile,
} from "../../../react-query/profile/useProfile";
import HorizontalLoader from "../../../components/General/Loaders/HorizontalLoader";

const Login = () => {
  const initialPhotos = (photos) => {
    return [null, null, null, null].map((_, ki) => {
      return photos && photos[ki] ? photos[ki] : null;
    });
  };

  const navigate = useNavigate();
  const location = useLocation();

  const [isLoading, setIsLoading] = useState(false);
  const { data: profile, isLoading: isMyProfileLoading } = useMyProfile();
  const [photos, setPhotos] = useState(initialPhotos(profile?.photos));
  const [error, setError] = useState(false);
  const type = "photos";

  const prevPage = () => {
    return profileSetupPrevPage(type, profile, location.state);
  };

  const nextPage = () => {
    return profileSetupNextPage(type, profile, location.state);
  };

  const onUpdateMyProfileSuccess = () => {
    navigate(nextPage());
  };

  const { isLoading: isProfileUpdateLoading, mutate: mutateUpdateProfile } =
    useUpdateMyProfile({ onSuccess: onUpdateMyProfileSuccess });

  const updateHandler = () => {
    if (isLoading) return toast.error("Please wait for the photo to upload");

    if (photos.filter(Boolean).length === 0) {
      setError(true);
      return;
    }

    mutateUpdateProfile({ photos: photos.filter((p) => !!p) });
    setError(false);
  };

  // If my profile api is fetched then update photos array
  useEffect(() => {
    if (!isMyProfileLoading && profile) {
      setPhotos(initialPhotos(profile?.photos));
    }
  }, [isMyProfileLoading, profile]);

  return (
    <div className="flex w-full flex-col items-center  bg_login">
      <div className="flex flex-col relative w-[80%] min-h-screen">
        <div className="my-5 flex-1 flex flex-col">
          {location.state !== "editprofile" ? (
            <RangeBar step={63} height={19} />
          ) : null}

          <div className="flex-1">
            <p className="mt-[50px]  font-bold text-[26px] text-center">
              Add your photos!
            </p>
            <p className="mt-1 text-center  text-[10px] font-light text-[#000]">
              A picture is worth a thousand words.
            </p>
            <div className={`relative mt-10 w-full  grid grid-cols-2 gap-2`}>
              <UploadPhoto
                photos={photos}
                url={photos[0]}
                index={0}
                setPhotos={(p) => setPhotos(p)}
                setIsFileUploading={(p) => setIsLoading(p)}
              />
              <UploadPhoto
                photos={photos}
                url={photos[1]}
                index={1}
                setPhotos={(p) => setPhotos(p)}
                setIsFileUploading={(p) => setIsLoading(p)}
              />
              <UploadPhoto
                photos={photos}
                url={photos[2]}
                index={2}
                setPhotos={(p) => setPhotos(p)}
                setIsFileUploading={(p) => setIsLoading(p)}
              />
              <UploadPhoto
                photos={photos}
                url={photos[3]}
                index={3}
                setPhotos={(p) => setPhotos(p)}
                setIsFileUploading={(p) => setIsLoading(p)}
              />
            </div>
          </div>

          <div className="w-full relative right-0 flex justify-between">
            <button onClick={() => navigate(prevPage())} className="h-[47px] w-[100px] text-[18px] font-bold  ">
              Back
            </button>

            <button
              onClick={updateHandler}
              type="button"
              className="h-[47px] w-[100px] bg-white text-[18px] font-bold  px-4 py-2.5 rounded-[10px]"
            >
              {isProfileUpdateLoading ? <HorizontalLoader /> : "Next"}
            </button>
          </div>
          
        </div>
      </div>
    </div>
  );
};

export default Login;
