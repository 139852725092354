import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import RangeBar from "../../../components_tailwindcss/RangeBar";
import HorizontalLoader from "../../../components/General/Loaders/HorizontalLoader";
import { PROFILE_PAGES, profileSetupNextPage, profileSetupPrevPage } from "../../../utils/profile";
import INTERESTS from "../../../assets/data/interests.json";
// Actions
import {
  useUpdateMyProfile,
  useMyProfile,
} from "../../../react-query/profile/useProfile";
import toast from "react-hot-toast";
import Tag from "../../../components/AllProfiles/Tag";

const Interests = () => {
  const type = "interests";
  const navigate = useNavigate();
  const location = useLocation();
  const { data: profile } = useMyProfile({ enabled: false });
  const [userInterests, setUserInterests] = useState(
    profile?.interests
      ? profile.interests.reduce((acc, curr) => ((acc[curr] = true), acc), {})
      : {}
  );

  const PROFILE_PAGES_SETUP_KEYS = Object.keys(PROFILE_PAGES).filter(
    (k) => PROFILE_PAGES[k].isSetupScreen
  );
  const currentPageIndex = PROFILE_PAGES_SETUP_KEYS.findIndex(
    (k) => k === "interests"
  );

  const interestsHandler = (name) => {
    // Check if the name exists in the array
    const isLabelAvailable = userInterests[name];

    if (Object.entries(userInterests).length >= 5 && !isLabelAvailable) {
      return toast.error("You can choose only 5 interests")
    }

    if (!isLabelAvailable) {
      setUserInterests({ ...userInterests, [name]: true });
    } else if (isLabelAvailable) {
      let userInterestsLocal = Object.assign({}, userInterests);
      delete userInterestsLocal[name];
      setUserInterests(userInterestsLocal);
    }
  };

  const nextPage = () => {
    return profileSetupNextPage("interests", profile, location.state);
  };

  const prevPage = () => {
    return profileSetupPrevPage(type, profile, location.state);
  };

  const onUpdateMyProfileSuccess = () => {
    navigate(nextPage());
  };

  const { isLoading: isProfileUpdateLoading, mutate: mutateUpdateProfile } =
    useUpdateMyProfile({ onSuccess: onUpdateMyProfileSuccess });

  const updateHandler = () => {
    const interests = Object.keys(userInterests);
    if (!interests || !interests.length) {
      return toast.error("Please select atleast one");
    }

    mutateUpdateProfile({ interests });
  };

  return (
    <div className="flex w-full flex-col items-center  bg_login">
      <div className="flex flex-col relative w-[80%] min-h-screen">
        <div className="my-5 flex-1 flex flex-col">
          {location.state !== "editprofile" ? (
            <RangeBar step={88} height={19} />
          ) : null}

          <div className="flex-1">
            <p className="mt-[20px]  font-bold text-[26px] text-center">
              Interests
            </p>

            <p className="text-center  text-[10px] font-light text-[#000]">
              Let us know your interests and we will generate a bio for you.
            </p>
            <div
              className="flex mt-5 mb-2 mx-auto text-center w-[120%] overflow-y-scroll"
              style={{
                height: "calc(100vh - 11rem - 150px)",
                marginLeft: "-10%",
                marginRight: "-10%",
              }}
            >
              <div className="tags w-full flex justify-center flex-wrap">
                {INTERESTS.map((interest, interestIndex) => {
                  return (
                    <div
                      className="tag-container p-1"
                      key={`interest-${interestIndex}`}
                    >
                      <label
                        onClick={(e) => interestsHandler(interest.name)}
                        htmlFor={`checkbox-interest-${interestIndex}`}
                      >
                        <Tag
                          iconWidth="20"
                          icon={interest.url}
                          title={interest.name}
                          active={!!userInterests[interest.name]}
                        />
                      </label>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>

          <div className="w-full relative right-0 flex justify-between">
            <button
              onClick={() => navigate(prevPage())}
              className="h-[47px] w-[100px] text-[18px] font-bold  "
            >
              Back
            </button>

            <button
              onClick={updateHandler}
              type="button"
              className="h-[47px] w-[100px] bg-white text-[18px] font-bold  px-4 py-2.5 rounded-[10px]"
            >
              {isProfileUpdateLoading ? <HorizontalLoader /> : "Next"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Interests;
