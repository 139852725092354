import React from "react";
import Loader from "../../common/loader";

function FullPageLoader() {
  return (
    <div className="absolute top-0 left-0 z-20 opacity-50 bg-white w-full h-full flex justify-center items-center">
      <Loader color="#e8649a" />
    </div>
  );
}

export default FullPageLoader;
