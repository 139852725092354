import { io } from "socket.io-client";

let socket;

export const initSocket = () => {
  console.log("Connecting socket");
  socket = io(process.env.REACT_APP_SOCKET_BASE_URL, {
    // transports: ['websocket'],
    autoConnect: true,
    reconnection: true,
    reconnectionAttempts: 5,
    reconnectionDelay: 1000,
  });

  socket.on("connect", () => {
    console.log("Socket connected");
  });

  socket.on("disconnect", (reason) => {
    console.log("Socket disconnected:", reason);
  });

  socket.on("connect_error", (error) => {
    console.error("Connection error:", error);
  });

  return socket;
};

export const getSocket = () => {
  if (!socket) {
    initSocket()
    console.log("Socket not initialized. Initializing initSocket first.");
  }
  return socket;
};
