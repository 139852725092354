import React from "react";
import "../../../assets/css/modal.scss"
import crossIcon from "../../../assets/images/icons/general/cross-black.svg"
import Button from "../../Buttons/Button";

function Modal({ show, versionDetails, platform }) {
    const handleButton = () => {
        window.location = (platform === "android") ? process.env.REACT_APP_PLAYSTORE_LINK : process.env.REACT_APP_LANDING_PAGE_URL
    }
    return (
        <>
            {
                (show && versionDetails && versionDetails.force_update) ? <div className="modal-page">
                    <div className="modal-container">
                        <div className="modal-header">
                            <h3 className="modal-header-title">{versionDetails.title}</h3>
                        </div>
                        <div className="modal-body" style={{ flexGrow: 1 }}>
                            <p>{versionDetails.body}</p>
                        </div>
                        {
                            versionDetails.show_button ? <Button
                                onClick={() => handleButton()}
                                name={versionDetails.button_text}
                                type="fill"
                                padding="14px 72px"
                            /> : null
                        }

                    </div>
                </div> : null
            }
        </>
    );
}

export default Modal;
