import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    toggleShowErrorShowNextProfileModal
} from "../../store/actions";

function ErrorNextPageProfileModal() {
  const dispatch = useDispatch();
  
  const { showErrorShowNextProfileModal } = useSelector(
    (state) => state.like
  );

  return (
    <div
      className={`z-50 flex justify-center items-center overflow-auto left-0 top-0 bottom-0 right-0 w-full h-full fixed transition-opacity duration-300 ${
        showErrorShowNextProfileModal ? "opacity-100" : ""
      }`}
    >
      <div
        className={`z-50 flex flex-col relative border-2 border-[#F7544D] bg-white rounded-[22px] p-3 mx-auto my-0 max-w-md min-w-xs w-[80%] transform scale-0 transition duration-500 ease-in-out ${
          showErrorShowNextProfileModal ? "scale-100" : ""
        }`}
      >
        <p className="pt-3 pb-2 text-[16px] text-center font-semibold text-[#181818]">
        Rate to Meet Your Next Match!
        </p>
        <p className="flex px-2 py-2 text-[12px] text-[#181818]">
        Your quick rating helps us find better matches for you.
        <br/>
        Just rate this profile, and you're off to the next one.
        </p>

        <div className="w-full flex justify-center">
          <button
            onClick={() => dispatch(toggleShowErrorShowNextProfileModal(false))}
            style={{
              background:
                "linear-gradient(90deg, #FC5849 0%, #D1396B 33.85%, #C02E78 67.71%, #950F9A 100%)",
            }}
            className="cursor-pointer my-3 mx-2  text-[12px] font-semibold text-white px-5 py-2  rounded-[29px] leading-[11px]"
          >
            OK I Understand
          </button>
        </div>
      </div>
      <div
        className={`z-40 overflow-auto left-0 top-0 bottom-0 right-0 w-full h-full fixed bg-black opacity-50 ${
            showErrorShowNextProfileModal ? "visible" : "invisible"
        }`}
      ></div>
    </div>
  );
}

export default ErrorNextPageProfileModal;
