import React, { useRef, useEffect } from "react";
import { Player, Controls } from "@lottiefiles/react-lottie-player";
import heightIcon from "../../assets/images/icons/random-profiles/height.png";
import drinkingIcon from "../../assets/images/icons/random-profiles/drinking.png";
import smokingIcon from "../../assets/images/icons/random-profiles/smoking.png";
import whatToFindIcon from "../../assets/images/icons/random-profiles/what_to_find.png";
import maleIcon from "../../assets/images/icons/random-profiles/male.png";
import femaleIcon from "../../assets/images/icons/random-profiles/female.png";
import nonBinaryIcon from "../../assets/images/icons/random-profiles/non-binary.png";
import "../../assets/css/animations.scss";
import icon from "../../assets/img/Delivery/icon.png";
import { useState } from "react";
import RangeSelector from "./RangeSelector";
import INTERESTS from "../../assets/data/interests.json";
import Tag from "../../components/AllProfiles/Tag";
import { showImage } from "../../utils/profile";
import {
  markAdvertisementActionRequested,
  updateProfileDecisionRequested,
  toggleUpgradePlanModal,
} from "../../store/actions";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  HeartIcon,
  ChevronUpIcon,
  ChevronDownIcon,
  HandThumbDownIcon,
} from "@heroicons/react/24/solid";
// Import the local Lottie JSON file
import likeAnimation from '../../assets/lottie_animations/likeAnimation.json';

const ReelRating = React.memo(function ReelRating({
  setScoreModal,
  ScoreModal,
  profile,
  onSuccess = () => {},
  isRatingDisabled=false
}) {
  const ratingRef = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const lottiePlayerRef = useRef();
  const userRatings = useSelector((state) => state.like.userRatings)

  const genderDetails = (gender) => {
    const genderIconMapping = {
      M: { icon: maleIcon, title: "Male" },
      F: { icon: femaleIcon, title: "Female" },
      NB: { icon: nonBinaryIcon, title: "Non-Binary" },
    };
    return genderIconMapping[gender];
  };

  const whatToFindDetails = (data) => {
    const mapping = {
      R: "Relationship",
      C: "Casual",
      NS: "Not Sure",
      F: "Friendship",
      CON: "Connections",
    };
    return mapping[data];
  };

  const drinkSmokeDetails = (data) => {
    const mapping = {
      N: "Never",
      S: "Socially",
      O: "Often",
    };
    return mapping[data];
  };

  const advertisementRedirectLink = () => {
    if (profile.is_advertisement && profile.advertisement.redirect_link) {
      return window.open(profile.advertisement.redirect_link);
    }
  };

  const getRangeValue = () => {
    return userRatings[profile._id] ?? 5
  }

  const handleUserRating = (rating) => {

    const likeType = rating >= 5 ? "LIKE" : "DISLIKE";

    if (profile.is_advertisement) {
      if (likeType === "LIKE") {
        advertisementRedirectLink();
      }

      dispatch(
        markAdvertisementActionRequested({
          advertisement: profile._id,
          is_liked: likeType === "LIKE",
        })
      );
    } else {
      dispatch(
        updateProfileDecisionRequested({
          data: {
            reportReason: "",
            msg: "",
            user: profile._id,
            userRating: rating,
          },
          isRedirect: false,
          navigate,
        })
      );

      if (lottiePlayerRef.current && rating > 5) {
        lottiePlayerRef.current.container.style.display = "block";
        lottiePlayerRef.current.play();
      }

      onSuccess(profile._id, rating);
    }
  };

  return (
    <>
      <div
      ref={ratingRef}
        className={`reel-rating flex flex-col z-20 py-3 px-4 bottom-2  absolute w-[85%] rounded-[22px] ${
          ScoreModal ? "bg-[#ffffff8a]" : "bg-[#ffffffb3]"
        } max-h-[70vh]`}
      >
        <div className="absolute -top-[100px] -right-[30px] z-10">
          <Player
            onEvent={(event) => {
              if (event === "complete")
                lottiePlayerRef.current.container.style.display = "none"; // check event type and do something
            }}
            ref={lottiePlayerRef}
            autoplay={false}
            loop={false}
            controls={true}
            src={likeAnimation}
            style={{ height: "100px", width: "100px", display: "none" }}
          ></Player>
        </div>
        <div className={`flex items-center gap-x-2 pb-2`}>
          <img
            src={showImage(profile.photos[0], "thumbnailxs")}
            alt="icon"
            className="w-[20px] h-[20px] rounded-[100%]"
          />
          <p className=" text-[#181818] font-semibold text-[12px]">
            {profile.final_name}, {profile.age}
          </p>
          {!ScoreModal ? (
            <ChevronUpIcon
              onClick={() => setScoreModal(!ScoreModal)}
              className={`cursor-pointer bouncing-element font-bold [&>path]:stroke-[2] w-[15px] ml-auto`}
            />
          ) : (
            <ChevronDownIcon
              onClick={() => setScoreModal(!ScoreModal)}
              className={`cursor-pointer stroke-2 w-[15px] ml-auto`}
            />
          )}
        </div>

        <div className="overflow-y-scroll relative">
          {profile.bio && profile.bio.length ? (
            <>
              {ScoreModal ? (
                <h1 className=" my-1 font-semibold text-[12px]">About</h1>
              ) : null}

              <p
                className={` text-[12px] ${
                  ScoreModal
                    ? "font-normal "
                    : "overflow-hidden text-ellipsis whitespace-nowrap font-semibold"
                } `}
              >
                {profile.bio}
              </p>
            </>
          ) : null}

          {ScoreModal && (
            <div className="loop-profile-details">
              {!profile.is_advertisement ? (
                <>
                  <h1 className=" my-2 font-semibold text-[12px]">My Basics</h1>
                  <div className="w-full flex flex-wrap gap-2">
                    {profile.height && (
                      <Tag
                        backgroundColor="#282727"
                        iconWidth={20}
                        icon={heightIcon}
                        title={`${profile.height}cm`}
                        isPointerEventsNone={true}
                      />
                    )}

                    {profile.is_habit_drink && (
                      <Tag
                        backgroundColor="#282727"
                        iconWidth={20}
                        icon={drinkingIcon}
                        title={`${drinkSmokeDetails(profile.is_habit_drink)}`}
                        isPointerEventsNone={true}
                      />
                    )}

                    {profile.is_habit_smoke && (
                      <Tag
                        backgroundColor="#282727"
                        iconWidth={20}
                        icon={smokingIcon}
                        title={`${drinkSmokeDetails(profile.is_habit_smoke)}`}
                        isPointerEventsNone={true}
                      />
                    )}

                    {profile.what_to_find && (
                      <Tag
                        backgroundColor="#282727"
                        iconWidth={20}
                        icon={whatToFindIcon}
                        title={`${whatToFindDetails(profile.what_to_find)}`}
                        isPointerEventsNone={true}
                      />
                    )}

                    {profile.gender && (
                      <Tag
                        backgroundColor="#282727"
                        iconWidth={20}
                        icon={genderDetails(profile.gender).icon}
                        title={genderDetails(profile.gender).title}
                        isPointerEventsNone={true}
                      />
                    )}
                  </div>
                </>
              ) : null}

              {!profile.is_advertisement && profile.interests ? (
                <>
                  <h1 className=" my-2 font-semibold text-[12px]">
                    My Interests
                  </h1>

                  <div className="w-full flex flex-wrap gap-2">
                    {INTERESTS.filter((interest) =>
                      profile.interests.includes(interest.name)
                    ).map((interest, interestIndex) => {
                      return (
                        <Tag
                          backgroundColor="#282727"
                          key={`${profile._id}-interest-${interestIndex}`}
                          icon={interest.url}
                          iconWidth={20}
                          title={interest.name}
                          isPointerEventsNone={true}
                        />
                      );
                    })}
                  </div>
                </>
              ) : null}

              {/* {!profile.is_advertisement && profile.interests ? (
                <>
                  <h1 className=" my-2 font-semibold text-[12px]">My Photos</h1>

                  <div className="w-full flex flex-col gap-2">
                    <swiper-container pagination="true" pagination-clickable="true" slides-per-view="2"
                      space-between="20" centered-slides="true">
                      {profile.photos.map((p, pi) => {
                        return (
                          <swiper-slide
                            key={`loop-details-${profile._id}-${pi}`}
                          >
                            <img className="w-full h-full rounded-lg" src={showImage(p)} />
                          </swiper-slide>
                        );
                      })}
                    </swiper-container>
                  </div>
                </>
              ) : null} */}
            </div>
          )}
        </div>

        <div className="flex flex-col pt-3 pb-4">
          <RangeSelector
            range={getRangeValue()}
            min="0"
            max="10"
            isDisabled={isRatingDisabled}
            handleRange={(data) => handleUserRating(data)}
          />

          <div className="flex ml-1 mt-3 justify-between">
            <HandThumbDownIcon className="w-[14px] font-normal text-[#000000]" />
            {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((x) => (
              <div key={x} className="text-[14px] font-bold text-[#000000]">
                {x}
              </div>
            ))}
            <HeartIcon className="w-[14px] font-normal text-[#000000]" />
          </div>
        </div>
      </div>
    </>
  );
})
export default ReelRating