import React, { useState } from "react";
import "../../../assets/css/interest.scss";
import TopHeader from "../../../components/AllProfiles/TopHeader";
import Tag from "../../../components/AllProfiles/Tag";
import Button from "../../../components/Buttons/Button";
import interestIcon from "../../../assets/images/interest-icon.png";
import INTERESTS from "../../../assets/data/interests.json";
import { useLocation, useNavigate } from "react-router-dom";
import toast from 'react-hot-toast';
import { profileSetupNextPage, PROFILE_PAGES } from "../../../utils/profile";
import ProfileProgressBar from "../../../components/ProfileScreens/ProfileProgressBar";
import { useUpdateMyProfile, useMyProfile } from "../../../react-query/profile/useProfile";

const Interests = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const {data : profile} = useMyProfile({ enabled : false })
  const [userInterests, setUserInterests] = useState((profile?.interests) ? profile.interests.reduce((acc, curr) => (acc[curr] = true, acc), {}) : {});

  const PROFILE_PAGES_SETUP_KEYS = (Object.keys(PROFILE_PAGES).filter(k => PROFILE_PAGES[k].isSetupScreen))
  const currentPageIndex = PROFILE_PAGES_SETUP_KEYS.findIndex(k => k === "interests")

  const interestsHandler = (name, isChecked) => {
    if (Object.entries(userInterests).length >= 5 && isChecked) {
      return;
    }

    if (isChecked && !userInterests[name]) {
      setUserInterests({ ...userInterests, [name]: true });
    } else if (!isChecked && userInterests[name]) {
      let userInterestsLocal = Object.assign({}, userInterests);
      delete userInterestsLocal[name];
      setUserInterests(userInterestsLocal);
    }
  };

  const nextPage = () => {
    return profileSetupNextPage("interests", profile, location.state)
  };

  const onUpdateMyProfileSuccess = () => {
    navigate(nextPage())
  }

  const {isLoading : isProfileUpdateLoading, mutate : mutateUpdateProfile} = useUpdateMyProfile({ onSuccess : onUpdateMyProfileSuccess })

  const updateHandler = () => {
    const interests = Object.keys(userInterests)
    if (!interests || !interests.length) {
      return toast.error("Please select atleast one")
    }

    mutateUpdateProfile({interests})
  };

  return (
    <div className="interest">
      {
        location.state !== "editprofile" ? <ProfileProgressBar currentPage={currentPageIndex + 1} totalPages={PROFILE_PAGES_SETUP_KEYS.length} /> : null
      }
      <div className="top">
        <TopHeader icon={interestIcon} title="Your interests" />
      </div>
      <div className="bottom">
        <div>
          <div className="select-any">
            <p className="selected-items">
              {Object.keys(userInterests).length}/5 Selected
            </p>{" "}
          </div>
          <div className="tags">
            {INTERESTS.map((interest, interestIndex) => {
              return (
                <div
                  className="tag-container"
                  key={`interest-${interestIndex}`}
                >
                  <input
                    type="checkbox"
                    id={`checkbox-interest-${interestIndex}`}
                    onChange={(e) =>
                      interestsHandler(interest.name, e.target.checked)
                    }
                    checked={userInterests[interest.name]}
                    value={interest.name}
                  />
                  <label
                    className={`${userInterests[interest.name] ? "input-checked" : ""
                      }`}
                    htmlFor={`checkbox-interest-${interestIndex}`}
                  >
                    <Tag
                      iconWidth="20"
                      icon={interest.url}
                      title={interest.name}
                    />
                  </label>
                </div>
              );
            })}
          </div>
        </div>
        <div className="buttons">
          <Button
            onClick={() => navigate(nextPage())}
            name="Skip"
            type="outline"
            padding="14px 29px"
          />
          <p className="selected-items">
            {Object.keys(userInterests).length}/5 Selected
          </p>
          <Button
            onClick={updateHandler}
            isLoading={isProfileUpdateLoading}
            name="Next"
            type="fill"
            padding="14px 29px"
          />
        </div>
      </div>
    </div>
  );
};

export default Interests;
