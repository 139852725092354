import "../../../assets/css/loader.scss"

const HorizontalLoader = function () {
    return (
        <div className='dot-flashing-container'>
            <div className="dot-flashing"></div>
        </div>
    )
}

export default HorizontalLoader