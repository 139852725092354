import { call, put, takeEvery } from "redux-saga/effects";
import {
    CREATE_PAYMENT_ORDER_REQUESTED,
} from "../actionTypes";

import { createPaymentLink } from "../../services/payment";

import {
  createPaymentOrderSuccess,
  createPaymentOrderFailure,
} from "../actions";

function* createPaymentOrderSaga(action) {
  try {
    const order = yield call(createPaymentLink, action.payload);
    if (order.success) {
      yield put(createPaymentOrderSuccess(order.data));
    }
  } catch (e) {
    yield put(createPaymentOrderFailure(e));
  }
}

function* chatsSaga() {
  yield takeEvery(CREATE_PAYMENT_ORDER_REQUESTED, createPaymentOrderSaga);
}

export default chatsSaga;
