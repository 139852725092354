import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router";
import "../../../assets/css/name.scss";
import TopHeader from "../../../components/AllProfiles/TopHeader";
import ProfileProgressBar from "../../../components/ProfileScreens/ProfileProgressBar";
import Button from "../../../components/Buttons/Button";
import bottomCurve from "../../../assets/images/bottom-curve.png";
import { PROFILE_PAGES } from "../../../utils/profile";
// Actions
import DotLoader from "../../../components/General/Loaders/DotLoader";
import { useGetBioRecommendation, useUpdateMyProfile, useMyProfile } from "../../../react-query/profile/useProfile";

const ProfileBio = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const type = "bio"
    const {data : profile} = useMyProfile({ enabled : false })  
    const [val, setVal] = useState((profile && profile[type]) ? profile[type] : PROFILE_PAGES[type].initial_value);
    const [error, setError] = useState(false);
    const [recommendationIndex, setRecommendationIndex] = useState(null)
    const [showCustomBio, setShowCustomBio] = useState(false)

    const PROFILE_PAGES_SETUP_KEYS = (Object.keys(PROFILE_PAGES).filter(k => PROFILE_PAGES[k].isSetupScreen))
    const currentPageIndex = PROFILE_PAGES_SETUP_KEYS.findIndex(k => k === type)
    const item = PROFILE_PAGES[type];

    const {data : profileDescriptionRecommendations, refetch : fetchBioRecommentations, isLoading : isProfileDescriptionRecommendationLoading, isFetching : isProfileDescriptionRecommendationFetching} = useGetBioRecommendation({enabled : false})
    
    const nextPage = () => {
        if (location.state === "editprofile") return "/myprofile"
        return `/kindnotice`
    };

    const onUpdateMyProfileSuccess = () => {
        navigate(nextPage())
    }
  
    const {isLoading : isProfileUpdateLoading, mutate : mutateUpdateProfile} = useUpdateMyProfile({ onSuccess : onUpdateMyProfileSuccess })

    const updateHandler = () => {
        if (((!val || !val.length))) {
            setError(true);
            return;
        }

        mutateUpdateProfile({ "bio": val })
        setError(false);
    };

    const handleChange = (e) => {
        setVal(e.target.value);
        setRecommendationIndex(null)
        setError(false);
    };

    const selectRecommendation = (i) => {
        setRecommendationIndex(i)
        setVal(profileDescriptionRecommendations[i])
    }

    useEffect(() => {
        fetchBioRecommentations()
    }, [fetchBioRecommentations])

    return (
        <div className="top-header-common name">
            {
                location.state !== "editprofile" ? <ProfileProgressBar currentPage={currentPageIndex + 1} totalPages={PROFILE_PAGES_SETUP_KEYS.length} /> : null
            }
            <div className="bg-image">
                <img src={bottomCurve} alt="" />
            </div>
            <div className="top">
                <TopHeader
                    icon={item.icon}
                    title={item.title}
                    subtitle={item.subtitle}
                />

                {
                    (isProfileDescriptionRecommendationLoading || isProfileDescriptionRecommendationFetching) ? <div style={{display : "flex", width : "100%", justifyContent : "center"}}><DotLoader /></div> : 
                    <>
                        {
                            profileDescriptionRecommendations.map((recom, recomIndex) => {
                                return <div onClick={(e) => selectRecommendation(recomIndex)} className={`bio-recommendation ${recommendationIndex === recomIndex ? "selected" : ""}`}>{recom}</div>
                            })
                        }

                        <div style={{display : "flex", width : "100%", justifyContent : "space-between"}}>
                            <span style={{ fontSize : 12, textDecoration: "underline", fontStyle: "italic" }} onClick={() => setShowCustomBio(!showCustomBio)}>Want to add your own Bio?</span>
                            <span style={{ fontSize : 12, textDecoration: "underline", fontStyle: "italic" }} onClick={() => fetchBioRecommentations()}>Regenerate</span>
                        </div>

                        <div style={{ marginTop: 4, paddingBottom: 40 }} className="input">

                            <textarea
                                style={{ display: `${showCustomBio ? "flex" : "none"}` }}
                                onChange={handleChange}
                                value={val}
                                type="text"
                                placeholder={item.placeholder}
                                id="name"
                                cols="30"
                                rows="10"
                                maxLength={200}
                            ></textarea>

                            {error && (
                                <div className="error" style={{ padding: "10px 0px" }}>
                                    <span>
                                        Please enter a valid bio
                                    </span>
                                </div>
                            )}
                        </div>
                    </>
                }

            </div>
            <div className="bottom">
                <Button
                    onClick={updateHandler}
                    isLoading={isProfileUpdateLoading}
                    name={(location.state === "editprofile") ? "Update" : "Next"}
                    type="fill"
                    padding="14px 29px"
                />
                {!item.required && (
                    <Button
                        onClick={() => navigate(nextPage())}
                        name="Skip"
                        type="outline"
                        padding="14px 29px"
                    />
                )}
            </div>
        </div>
    );
};

export default ProfileBio;
