import { InformationCircleIcon } from '@heroicons/react/24/solid'
import React from 'react'
import "../../assets/css/allprofile.scss"
import { useDispatch } from 'react-redux'
import { toggleReportProfileModal } from '../../store/actions'

function ReportProfile({profileId}) {
    const dispatch = useDispatch()
  return (
    <div onClick={() => dispatch(toggleReportProfileModal(profileId))} className='h-[24px] opacity-50 mr-2 flex items-center justify-center text-xs p-1 px-1.5 mt-2 bg-[#000000] text-[#FFFFFF] rounded-xl'>
        <InformationCircleIcon className='h-3 mr-1' />
        <span>Report</span>
    </div>
  )
}

export default ReportProfile