import React from "react";
import "./Loader.scss";

const Loader = ({ color = "white" }) => {
  return (
    <div style={{ "--ringColor": color }}>
      <div className="loader"></div>
    </div>
  )
};

export default Loader;