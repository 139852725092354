import React, { createContext, useContext, useEffect, useState } from 'react';
import io from 'socket.io-client';

const SocketContext = createContext();

export const useSocket = () => {
  const context = useContext(SocketContext);
  if (context === undefined) {
    throw new Error('useSocket must be used within a SocketProvider');
  }
  return context;
};

export const SocketProvider = ({ children }) => {
  const [socket, setSocket] = useState(null);

  const initSocket = () => {
    if (!socket) {
      const newSocket = io(process.env.REACT_APP_SOCKET_BASE_URL, {
        credentials: true,
      });
      setSocket(newSocket);
      return newSocket;
    }
    return socket;
  };

  const closeSocket = () => {
    if (socket) {
      socket.close();
      setSocket(null);
    }
  };

  useEffect(() => {
    return () => {
      if (socket) socket.close();
    };
  }, [socket]);

  return (
    <SocketContext.Provider value={{ socket, initSocket, closeSocket }}>
      {children}
    </SocketContext.Provider>
  );
};