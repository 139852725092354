import {
    FETCH_ADVERTISEMENTS_REQUESTED,
    FETCH_ADVERTISEMENTS_SUCCESS,
    FETCH_ADVERTISEMENTS_FAILURE,
    MARK_ADVERTISEMENT_ACTION_REQUESTED,
    MARK_ADVERTISEMENT_ACTION_SUCCESS,
    MARK_ADVERTISEMENT_ACTION_FAILURE
} from "../actionTypes";

const initialState = {
    isLoading: false,
    advertisements: [],
    isMarkAdvertisementActionLoading: false,
};

export default function chatReducer(state = initialState, action) {
    const { type } = action;

    switch (type) {

        case FETCH_ADVERTISEMENTS_REQUESTED: {
            return {
                ...state,
                isLoading: true,
                advertisements : []
            };
        }
        case FETCH_ADVERTISEMENTS_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                advertisements: action.payload
            };
        }

        case FETCH_ADVERTISEMENTS_FAILURE: {
            return {
                ...state,
                isLoading: false,
                advertisements: [],
            };
        }
        
        case MARK_ADVERTISEMENT_ACTION_REQUESTED: {
            return {
                ...state,
                isMarkAdvertisementActionLoading: true,
            };
        }

        case MARK_ADVERTISEMENT_ACTION_SUCCESS: {
            console.log(state.advertisements, action.payload, state.advertisements.filter(a => a._id !== action.payload.advertisement))
            return {
                ...state,
                isMarkAdvertisementActionLoading: false,
                advertisements : state.advertisements.filter(a => a._id !== action.payload.advertisement)
            };
        }

        case MARK_ADVERTISEMENT_ACTION_FAILURE: {
            return {
                ...state,
                isMarkAdvertisementActionLoading: false,
                error: action.payload
            };
        }

        default:
            return state;
    }
}
