import React from "react";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { trackEventRequested } from "../store/actions";
// Components
import TopHeader from "../components/AllProfiles/TopHeader";
import Button from "../components/Buttons/Button";
// Images
import notice from "../assets/images/notice.png";
import tick from "../assets/images/tick.png";
// CSS
import "../assets/css/kind-notice.scss";

const KindNotice = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const title = "It's great to be nice. 👌";

  const handler = () => {
    dispatch(trackEventRequested({ eventName : "ACCEPT_KINDNESS", eventBody : {} }))
    navigate("/readytogo")
  }
  return (
    <div className="kind-notice">
      <div className="top">
        <TopHeader icon={notice} title={title} />
      </div>
      <div className="notice">
        <div className="title">
          We have a strict policy towards harassment/discrimination against anyone.
        </div>
        <div className="subtitle">
          Respect each other and do not indulge in cheap practices.
        </div>
        <div className="points">
          <div className="point">
            <img src={tick} alt="" />
            <div className="text">
              Do not harass or discriminate against any particular sex/race/religion
            </div>
          </div>
          <div className="point">
            <img src={tick} alt="" />
            <div className="text">
              Do not promote hate speech, vulgar jokes, animal cruelty, child pornography in the app.
            </div>
          </div>
          <div className="point">
            <img src={tick} alt="" />
            <div className="text">
              Be kind and respectful to all.
            </div>
          </div>
        </div>
        <Button onClick={() => handler()} name="I promise" type="fill" padding="14px 129px" />
      </div>
    </div>
  );
};

export default KindNotice;
