import React from 'react'
import '../../assets/css/top-header.scss'

const TopHeader = ({icon, title, subtitle}) => {
  return (
    <div className="top-header">
        <div className="icon">
            <img src={icon} alt="" />
        </div>
        <div className="title">
            {title}
        </div>
        <div className="subtitle">{subtitle}</div>
    </div>
  )
}

export default TopHeader