import axios from "axios";
import toast from 'react-hot-toast';
const { authHeader } = require("../utils/auth");

export const fetchCollegesService = (data) => {
  let query_arr = []
  for(let key in data){
    query_arr.push(`${key}=${data[key]}`)
  }
  const query_string = query_arr.join("&")
  return new Promise((resolve, reject) => {
    axios
    .get(`college?${query_string}`, {
      headers: {
        ...authHeader(),
      },
    })
    .then((res) => {
      return resolve(res.data)
    })
    .catch((err) => {
      toast.error(err?.response?.data?.msg)
      return reject(err)
    });
  })
};

export const createCollegeService = (data) => {
  return axios
    .post(
      `college`,
      data,
      {
        headers: {
          ...authHeader(),
        },
      }
    )
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      toast.error(err?.response?.data?.msg)
      throw err;
    });
};