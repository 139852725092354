import {
  FETCH_SINGLE_PROFILE_REQUESTED,
  FETCH_SINGLE_PROFILE_SUCCESS,
  FETCH_SINGLE_PROFILE_FAILURE,
  SET_PROFILE_FILTER_MODAL_ACTIVE,
  UPDATE_PROFILE_FILTERS,
  RESTORE_PROFILE_PURCHASES_REQUESTED,
  RESTORE_PROFILE_PURCHASES_SUCCESS,
  RESTORE_PROFILE_PURCHASES_FAILURE,
  UPDATE_RESTORE_PURCHASES_MODAL_ACTIVE
} from "../actionTypes";

export const fetchSingleProfileRequested = (data) => {
  return {
    type: FETCH_SINGLE_PROFILE_REQUESTED,
    payload: data,
  };
};

export const fetchSingleProfileSuccess = (data) => {
  return {
    type: FETCH_SINGLE_PROFILE_SUCCESS,
    payload: data,
  };
};

export const fetchSingleProfileFailure = (data) => {
  return {
    type: FETCH_SINGLE_PROFILE_FAILURE,
    payload: data,
  };
};

export const updateProfileFilterModalActive = (data) => {
  return {
    type : SET_PROFILE_FILTER_MODAL_ACTIVE,
    payload : data
  }
}

export const updateProfileFilters = (data) => {
  return {
    type : UPDATE_PROFILE_FILTERS,
    payload : data
  }
}

export const restoreMyProfilePurchasesRequested = () => {
  return {
    type: RESTORE_PROFILE_PURCHASES_REQUESTED,
    payload: {},
  };
};

export const restoreMyProfilePurchasesSuccess = (data) => {
  return {
    type: RESTORE_PROFILE_PURCHASES_SUCCESS,
    payload: data,
  };
};

export const restoreMyProfilePurchasesFailure = (data) => {
  return {
    type: RESTORE_PROFILE_PURCHASES_FAILURE,
    payload: data,
  };
};

export const updateRestorePurchasesModalActive = (data) => {
  return {
    type : UPDATE_RESTORE_PURCHASES_MODAL_ACTIVE,
    payload : data
  }
}