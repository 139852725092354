import React, { useEffect } from "react";
import "../assets/css/allprofile.scss";
import "../assets/css/common.scss";
import ProfileDetails from "../components/AllProfiles/ProfileDetails";
import crossIcon from "../assets/images/icons/general/cross-black.svg";
import Button from "../components/Buttons/Button";
import Navigation from "../components/Navigation";
import Header from "../components/AllProfiles/Header";
import FullScreenDarkModal from "../components/General/Modals/FullScreenDarkModal";
import { useDispatch, useSelector } from "react-redux";
import { updateCachedProfileImages, fetchRandomProfilesRequested, fetchAppeventRequested, clearAppevents, subscribeAppeventRequested, updateAppeventModal, updateProfileFilters, fetchAdvertisementsRequested } from "../store/actions";
import EmptyState from "../common/emptyState";
import Loader from "../common/loader";

const AllProfiles = () => {
  const dispatch = useDispatch();
  const { profiles, currentIndex, isLoading, profileFilters, cachedProfileImages } = useSelector(state => state.like);
  const { advertisements } = useSelector(state => state.advertisement);
  const { isFetchAppeventLoading, appevent, lastAppEventLoadedAt, isSubscribeAppeventLoading, showAppeventModal } = useSelector(state => state.appevent);
  const currentProfile = profiles[currentIndex];

  const refetchProfiles = () => {
    const newFilters = { ...profileFilters, ["event"]: null }
    dispatch(updateProfileFilters(newFilters))
    dispatch(fetchRandomProfilesRequested({filters : newFilters}))
  }

  const checkIfShowProfile = () => {
    if (advertisements.length && currentIndex === parseInt(Math.ceil(profiles.length / 2))) {
      return false
    }
    return true
  }

  const convertAdvertisementToProfile = (advertisement) => {
    return {
      _id: advertisement._id,
      final_name: advertisement.title,
      college: [advertisement.advertiser.brand_name],
      age: null,
      photos: [advertisement.content_url],
      is_advertisement: true,
      advertisement: {
        cta_text: advertisement.cta_text,
        cta_bg_color: advertisement.cta_bg_color,
        redirect_link: advertisement.redirect_link
      },
      bio: advertisement.caption
    }
  }

  useEffect(() => {
    if (!profiles.length) {
      dispatch(fetchRandomProfilesRequested({filters : profileFilters}));
      dispatch(fetchAdvertisementsRequested());
    }

    dispatch(clearAppevents())

    // Loading new data only if more than 6 hour is passed 
    let shouldRequest = true
    if (lastAppEventLoadedAt) {
      const lastLoaded = (new Date(lastAppEventLoadedAt)).getTime()
      const now = Date.now()

      if ((now - lastLoaded) < (3 * 60 * 60 * 1000)) {
        shouldRequest = false
      }
    }

    if (shouldRequest) {
      setTimeout(() => {
        dispatch(fetchAppeventRequested())
      }, 3000)
    }


  }, [dispatch]);

  useEffect(() => {
    // Caching 5 profiles
    if (profiles[currentIndex]) {
      const currentProfile = profiles[currentIndex]

      // Check if current profile images are not cached
      let isCached = true
      for (let pic of currentProfile.photos) {
        if (!cachedProfileImages[pic]) {
          isCached = false
          break
        }
      }

      // Caching the next 5 profiles if not cached
      if (!isCached) {
        // Check if current image is cached
        let imagesArr = {}
        const cacheLimit = 5
        const profilesToBeCached = profiles.slice(currentIndex, currentIndex+cacheLimit)
        for (let profIndex in profilesToBeCached) {
          const prof = profilesToBeCached[profIndex]
          if (prof.photos) {
            for (const pic of prof.photos) {
              const img = new Image()
              img.src = `${process.env.REACT_APP_FILE_BASE_URL}${pic}`
              imagesArr[pic] = 1
            }
          }
        }

        // Append this to the global store
        dispatch(updateCachedProfileImages(imagesArr))
      }
    }
  }, [currentIndex, profiles])

  return (
    <div className="all-profile">
      <Header />

      {
        (!isFetchAppeventLoading && appevent) ? (<FullScreenDarkModal show={showAppeventModal} onHide={() => dispatch(updateAppeventModal(false))}>
          <div className="event-notification compliment-container">
            <p className="event-title">{appevent.title}</p>
            <img src={appevent.photos[0]} />
            <div className="event-description" dangerouslySetInnerHTML={{ __html: appevent.description }}></div>
            <div>
              <div style={{ padding: "10px 0" }} className="buttons">
                {
                  appevent.is_hide_button ? null : (
                    <Button
                      isLoading={isSubscribeAppeventLoading}
                      type="fill"
                      name="Yes I am in!"
                      padding="14px 72px"
                      onClick={() => dispatch(subscribeAppeventRequested({ event: appevent._id }))}
                    />
                  )
                }

              </div>
              {
                appevent.is_hide_button ? null : <small style={{ fontStyle: "italic", fontWeight: "bold" }}>Event Mode lets you find profiles who subscribed to the same event. What if your match is present in this event?</small>
              }


            </div>
          </div>
        </FullScreenDarkModal>) : null}

      {isLoading ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "80vh",
          }}
        >
          <Loader color="#e8649a" />
        </div>
      ) : (profiles.length) ? (
        <>
          {(profileFilters && profileFilters.event) ? <div style={{ display: "flex", padding: "0 25px" }}><span style={{ background: "#e8649a", padding: "3px 5px", borderRadius: 20, color: "#fff", marginBottom: 5, fontSize: 12, display: "flex", alignItems: "center" }}>Event Mode <img onClick={() => refetchProfiles()} style={{ marginLeft: 5, cursor: "pointer" }} src={crossIcon} height={12} /></span></div> : null}

          <ProfileDetails key={`${checkIfShowProfile() ? 'P' : 'A'}-${currentIndex}`} profile={checkIfShowProfile() ? currentProfile : convertAdvertisementToProfile(advertisements[0])} />
        </>

      ) : (
        <>
          {(profileFilters && profileFilters.event) ? <div style={{ display: "flex", padding: "0 25px" }}><span style={{ background: "#e8649a", padding: "3px 5px", borderRadius: 20, color: "#fff", marginBottom: 5, fontSize: 12, display: "flex", alignItems: "center" }}>Event Mode <img onClick={() => refetchProfiles()} style={{ marginLeft: 5, cursor: "pointer" }} src={crossIcon} height={12} /></span></div> : null}

          <div style={{ marginTop: "150px" }}>
            <EmptyState label={"Stack is empty ☹️"} />
          </div>
        </>

      )}
      <Navigation active="" />
    </div>
  );
};

export default AllProfiles;
