import {
  FETCH_CHAT_MSGS_REQUESTED,
  FETCH_CHAT_MSGS_FAILURE,
  FETCH_CHAT_MSGS_SUCCESS,
  APPEND_CHAT_MSG,
  UPDATE_CHAT_MSGS_READ_REQUESTED,
  UPDATE_CHAT_MSGS_READ_SUCCESS,
  UPDATE_CHAT_MSGS_READ_FAILURE,
  MARK_MSGS_READ,
  RESET_CHATS,
  SHOW_CHAT_LIMIT_EXCEEDED,
  EXTEND_CHAT_REQUESTED,
  EXTEND_CHAT_SUCCESS,
  EXTEND_CHAT_FAILURE
} from "../actionTypes";

export const showChatsLimitExceeded = (data) => {
  return {
    type: SHOW_CHAT_LIMIT_EXCEEDED,
    payload: data,
  };
}

export const resetChats = (data) => {
  return {
    type: RESET_CHATS,
    payload: [],
  };
};

export const fetchChatMsgsRequested = (data) => {
  return {
    type: FETCH_CHAT_MSGS_REQUESTED,
    payload: data,
  };
};

export const fetchChatMsgsSuccess = (data) => {
  return {
    type: FETCH_CHAT_MSGS_SUCCESS,
    payload: data,
  };
};

export const fetchChatMsgsFailure = (data) => {
  return {
    type: FETCH_CHAT_MSGS_FAILURE,
    payload: data,
  };
};

export const updateChatMsgsRequested = (data) => {
  return {
    type: UPDATE_CHAT_MSGS_READ_REQUESTED,
    payload: data,
  };
};

export const updateChatMsgsSuccess = (data) => {
  return {
    type: UPDATE_CHAT_MSGS_READ_SUCCESS,
    payload: data,
  };
};

export const updateChatMsgsFailure = (data) => {
  return {
    type: UPDATE_CHAT_MSGS_READ_FAILURE,
    payload: data,
  };
};

export const extendChatRequested = (data) => {
  return {
    type: EXTEND_CHAT_REQUESTED,
    payload: data,
  };
};

export const extendChatSuccess = (data) => {
  return {
    type: EXTEND_CHAT_SUCCESS,
    payload: data,
  };
};

export const extendChatFailure = (data) => {
  return {
    type: EXTEND_CHAT_FAILURE,
    payload: data,
  };
};

export const appendChatMessage = (data) => {
  return {
    type: APPEND_CHAT_MSG,
    payload: data,
  };
};

export const markMsgsRead = (data) => {
  return {
    type: MARK_MSGS_READ,
    payload: data
  }
}
