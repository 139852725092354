import {
    FETCH_SINGLE_PROFILE_REQUESTED,
    FETCH_SINGLE_PROFILE_SUCCESS,
    FETCH_SINGLE_PROFILE_FAILURE,
    SET_PROFILE_FILTER_MODAL_ACTIVE,
    RESTORE_PROFILE_PURCHASES_REQUESTED,
    RESTORE_PROFILE_PURCHASES_FAILURE,
    RESTORE_PROFILE_PURCHASES_SUCCESS,
    UPDATE_RESTORE_PURCHASES_MODAL_ACTIVE
} from "../actionTypes"

const initialState = {
    currentProfile: null,
    isVerifyUserLoading : false,
    isSingleProfileLoading: false,
    filterModalActive : false,
    isRestorePurchasesLoading : false,
    restorePurchasesModalActive : false
};

export default function profileReducer(state = initialState, action) {
    const { type } = action;

    switch (type) {

        case SET_PROFILE_FILTER_MODAL_ACTIVE: {
            return {
                ...state,
                filterModalActive: action.payload
            };
        }

        case FETCH_SINGLE_PROFILE_REQUESTED: {
            return {
                ...state,
                isSingleProfileLoading: true,
            }
        }

        case FETCH_SINGLE_PROFILE_SUCCESS: {
            return {
                ...state,
                isSingleProfileLoading: false,
                currentProfile: action.payload
            }
        }

        case FETCH_SINGLE_PROFILE_FAILURE: {
            return {
                ...state,
                isSingleProfileLoading: false
            }
        }

        case RESTORE_PROFILE_PURCHASES_REQUESTED : {
            return {
                ...state,
                isRestorePurchasesLoading : true
            }
        }

        case RESTORE_PROFILE_PURCHASES_SUCCESS : {
            return {
                ...state,
                isRestorePurchasesLoading : false,
                restorePurchasesModalActive : true
            }
        }

        case RESTORE_PROFILE_PURCHASES_FAILURE : {
            return {
                ...state,
                isRestorePurchasesLoading : false
            }
        }

        case UPDATE_RESTORE_PURCHASES_MODAL_ACTIVE : {
            return {
                ...state,
                restorePurchasesModalActive : action.payload
            }
        }

        default:
            return state;
    }
}
