import React from 'react';
import ReactDOM from 'react-dom/client';
import * as Sentry from "@sentry/react";
import { Provider } from "react-redux";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import store from "./store";
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import axios from "axios"
import { register } from 'swiper/element/bundle';

register()

// Sentry
if(process.env.REACT_APP_MODE === "production"){
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    tracesSampleRate:  10,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    integrations: [new Sentry.Replay()],
  });
}

// Axios
axios.defaults.baseURL = process.env.REACT_APP_BASE_URL

// React Query
const client = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <BrowserRouter>
    <QueryClientProvider client={client}>
      <App />
      </QueryClientProvider>
    </BrowserRouter>
  </Provider>
);

