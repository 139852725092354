import React, { useState, useEffect } from "react";
import { ReactComponent as Arrow } from "../../assets/img/icons/arrow.svg";
import { useDispatch, useSelector } from "react-redux";
import RangeSelector from "./RangeSelector";
import { fetchCollegesService } from "../../services/college";
import toast from "react-hot-toast";
import {
  fetchAppeventsRequested,
  fetchRandomProfilesRequested,
  updateProfileFilterModalActive,
  updateProfileFilters,
} from "../../store/actions";

import DropDown from "../../components/General/Form/DropDown";
import { PROFILE_PAGES } from "../../utils/profile";
import AsyncSelect from "react-select/async";
import "../../assets/css/allprofile.scss";

const whatToFindOptions = PROFILE_PAGES["what_to_find"].options;

function ProfileFilters() {
  const dispatch = useDispatch();
  const { appevents } = useSelector((state) => state.appevent);
  const { profileFilters } = useSelector((state) => state.like);
  const { filterModalActive } = useSelector((state) => state.profile);

  const [college, setCollege] = useState(profileFilters?.college || null);
  // const [fromHeight, setFromHeight] = useState(profileFilters?.fromHeight || 0)
  // const [toHeight, setToHeight] = useState(profileFilters?.toHeight || 250)
  const [fromAge, setFromAge] = useState(profileFilters?.fromAge || 18);
  const [toAge, setToAge] = useState(profileFilters?.toAge || 100);
  const [whatToFind, setWhatToFind] = useState(profileFilters?.whatToFind);
  const [event, setEvent] = useState(profileFilters?.event);

  const promiseOptions = async (inputValue) => {
    return await fetchCollegesService({ name: inputValue })
      .then((response) => {
        return response.data.map((row) => {
          return { value: row._id, label: row.name };
        });
      })
      .catch((err) => {
        return toast.error(err?.response?.data, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };

  const debounce = (f, interval) => {
    let timer = null;

    return (...args) => {
      clearTimeout(timer);
      return new Promise((resolve) => {
        timer = setTimeout(() => resolve(f(...args)), interval);
      });
    };
  };

  const saveFilters = () => {
    let filters = {
      college,
      // fromHeight,
      // toHeight,
      fromAge,
      toAge,
      whatToFind,
      event,
    };

    dispatch(updateProfileFilters(filters));
    dispatch(fetchRandomProfilesRequested({ filters }));
    dispatch(updateProfileFilterModalActive(false));
  };

  useEffect(() => {
    dispatch(fetchAppeventsRequested());
  }, [dispatch]);

  return (
    <div
      className={`z-50 flex justify-center items-center overflow-auto left-0 top-0 bottom-0 right-0 w-full h-full fixed transition-opacity duration-300 ${
        filterModalActive ? "opacity-100" : ""
      }`}
    >
      <div
        className={`z-50 flex flex-col relative border-2 border-[#F7544D] bg-white rounded-[22px] p-3 mx-auto my-0 max-w-md min-w-xs w-[80%] transform scale-0 transition duration-500 ease-in-out ${
          filterModalActive ? "scale-100" : ""
        }`}
      >
        <p className="flex py-3 px-2 text-[14px]  font-semibold text-[#181818]">
          Profile Filters
        </p>

        <AsyncSelect
          menuPlacement="bottom"
          isClearable={true}
          loadOptions={debounce(promiseOptions, 500)}
          onChange={(e) => {
            setCollege(e);
          }}
          defaultValue={college}
          className="login-form-selectInput relative w-full cursor-default rounded-[20px] bg-black text-left text-white shadow-sm text-xs sm:leading-6"
          placeholder="Select college"
          classNamePrefix="search-college-filter"
          noOptionsMessage={() => "Search college here"}
        />

        <div className="flex items-center gap-x-9 w-full px-2 py-3">
          <div className="flex flex-col w-1/2">
            <p className=" pb-2 font-medium text-[10px]">
              Age from {fromAge} years
            </p>
            <RangeSelector
              range={fromAge}
              min="18"
              max="100"
              handleRange={(data) => setFromAge(data)}
            />
          </div>

          <div className="flex flex-col w-1/2 ">
            <p className=" pb-2 font-medium text-[10px]">
              Age to {toAge} years
            </p>
            <RangeSelector
              range={toAge}
              min="18"
              max="100"
              handleRange={(data) => setToAge(data)}
            />
          </div>
        </div>

        <DropDown
          placeholder="What to Find?"
          selected={whatToFind}
          setSelected={(val) => setWhatToFind(val)}
          options={whatToFindOptions}
        />

        {appevents.length ? (
          <DropDown
            placeholder="Search People at Events"
            selected={event}
            setSelected={(val) => setEvent(val)}
            options={appevents}
          />
        ) : null}

        <div className="w-full flex justify-center">
          <button
            onClick={() => dispatch(updateProfileFilterModalActive(false))}
            style={{
              background:
                "linear-gradient(90deg, #FC5849 0%, #D1396B 33.85%, #C02E78 67.71%, #950F9A 100%)",
            }}
            className="my-3 mx-2  text-[12px] font-semibold text-white px-5 py-2  rounded-[29px] leading-[11px]"
          >
            Cancel
          </button>
          <button
            onClick={() => saveFilters()}
            style={{
              background:
                "linear-gradient(90deg, #FC5849 0%, #D1396B 33.85%, #C02E78 67.71%, #950F9A 100%)",
            }}
            className="my-3 mx-2  text-[12px] font-semibold text-white px-5 py-2  rounded-[29px] leading-[11px]"
          >
            Apply Filter
          </button>
        </div>
      </div>
      <div
        className={`z-40 overflow-auto left-0 top-0 bottom-0 right-0 w-full h-full fixed bg-black opacity-50 ${
          filterModalActive ? "visible" : "invisible"
        }`}
      ></div>
    </div>
  );
}

export default ProfileFilters;
