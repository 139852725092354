import React from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

export default function PublicRoute({children}) {
   const userToken = localStorage.getItem("token")
   
   if(userToken) return <Navigate to="/loginhandler" />
   //redirect if there is no user 
   return children
}