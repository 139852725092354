import React, { useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router";
import "../../../assets/css/name.scss";
import TopHeader from "../../../components/AllProfiles/TopHeader";
import ProfileProgressBar from "../../../components/ProfileScreens/ProfileProgressBar";
import Button from "../../../components/Buttons/Button";
import bottomCurve from "../../../assets/images/bottom-curve.png";
import { PROFILE_PAGES, checkIfEmail, profileSetupNextPage } from "../../../utils/profile";
// Actions
import { useUpdateMyProfile, useMyProfile } from "../../../react-query/profile/useProfile";

const DefaultInput = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { type } = useParams();
  const {data : profile} = useMyProfile({ enabled : false })
  const [val, setVal] = useState((profile && profile[type]) ? profile[type] : PROFILE_PAGES[type].initial_value);
  const [error, setError] = useState(false);

  const PROFILE_PAGES_SETUP_KEYS = (Object.keys(PROFILE_PAGES).filter(k => PROFILE_PAGES[k].isSetupScreen))
  const currentPageIndex = PROFILE_PAGES_SETUP_KEYS.findIndex(k => k === type)
  const item = PROFILE_PAGES[type];

  const nextPage = () => {
    return profileSetupNextPage(type, profile, location.state)
  };

  const onUpdateMyProfileSuccess = () => {
    navigate(nextPage())
  }

  const {isLoading : isProfileUpdateLoading, mutate : mutateUpdateProfile} = useUpdateMyProfile({ onSuccess : onUpdateMyProfileSuccess })

  const updateHandler = () => {
    if (((!val || !val.length) || ((type === "mobile" && (val.length !== 10 || !/^[0-9]{10}$/g.test(val))) || (type === "email" && !checkIfEmail(val))))) {
      setError(true);
      return;
    }

    mutateUpdateProfile({ [type]: val })
    setError(false);
  };

  const handleChange = (e) => {
    setVal(e.target.value);
    setError(false);
  };

  return (
    <div className="top-header-common name">
      {
        location.state !== "editprofile" ? <ProfileProgressBar currentPage={currentPageIndex + 1} totalPages={PROFILE_PAGES_SETUP_KEYS.length} /> : null
      }
      <div className="bg-image">
        <img src={bottomCurve} alt="" />
      </div>
      <div className="top">
        <TopHeader
          icon={item.icon}
          title={item.title}
          subtitle={item.subtitle}
        />

        <div className="input">
          {type === "bio" ? (
            <textarea
              onChange={handleChange}
              value={val}
              type="text"
              placeholder={item.placeholder}
              id="name"
              cols="30"
              rows="10"
              maxLength={200}
            ></textarea>
          ) : type === "dob" ? (
            <input
              onChange={handleChange}
              value={val}
              type="date"
              placeholder={item.placeholder}
              id="calendar"
            />
          ) : type === "height" ? (
            <div style={{ display: "flex" }}>
              <input
                className="height-input"
                onChange={handleChange}
                value={val}
                type="number"
                placeholder={item.placeholder}
                id="name"
              />
              <span className="height-label-box"><b>CM</b></span>
            </div>

          ) : (
            <input
              onChange={handleChange}
              value={val}
              type={item.is_number_input ? "number" : "text"}
              placeholder={item.placeholder}
              id="name"
            />
          )}
          {error && (
            <div className="error" style={{ padding: "10px 0px" }}>
              <span>
                Please enter valid {type === "dob" ? "Date of Birth" : type}
              </span>
            </div>
          )}
        </div>
      </div>
      <div className="bottom">
        <Button
          onClick={updateHandler}
          isLoading={isProfileUpdateLoading}
          name={(location.state === "editprofile") ? "Update" : "Next"}
          type="fill"
          padding="14px 29px"
        />
        {!item.required && (
          <Button
            onClick={() => navigate(nextPage())}
            name="Skip"
            type="outline"
            padding="14px 29px"
          />
        )}
      </div>
    </div>
  );
};

export default DefaultInput;
