import axios from "axios";
const { authHeader } = require("../utils/auth");

// data is match id
export const createPaymentLink = (data) => {
  return axios
    .post(
      `payment/create-order`,
      data,
      {
        headers: {
          ...authHeader(),
        },
      }
    )
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err;
    });
};
