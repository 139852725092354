import React, { useEffect } from "react";
import LikeMatchHeader from "../components/LikeMatchHeader";
import "../assets/css/events.scss";
import eventIcon from "../assets/images/events-icon.svg";
import Navigation from "../components/Navigation";
import EventCard from "../components/EventCard";
import { useDispatch, useSelector } from "react-redux";
import { fetchAppeventsRequested } from "../store/actions";

const Event = () => {
  const dispatch = useDispatch()
  const {appevents} = useSelector(state => state.appevent)

  useEffect(() => {
    dispatch(fetchAppeventsRequested())
  }, [])
  return (
    <div className="events">
      <LikeMatchHeader
        icon={eventIcon}
        title="Idyll Events"
        subtitle="Find your perfect match in events"
      />
      <div className="bottom-part">
        <div className="event_cards">
          {
            appevents.map((ae, aeIndex) => {
              return <EventCard key={`event-${aeIndex}`} event={ae} />
            })
          }

        </div>
      </div>
      <Navigation active="" />
    </div>
  );
};

export default Event;
