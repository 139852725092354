import {
    FETCH_APPEVENT_REQUESTED,
    FETCH_APPEVENT_SUCCESS,
    FETCH_APPEVENT_FAILURE,
    FETCH_APPEVENTS_REQUESTED,
    FETCH_APPEVENTS_SUCCESS,
    FETCH_APPEVENTS_FAILURE,
    FETCH_APPEVENT_DETAILS_REQUESTED,
    FETCH_APPEVENT_DETAILS_SUCCESS,
    FETCH_APPEVENT_DETAILS_FAILURE,
    CLEAR_APPEVENTS,
    SUBSCRIBE_APPEVENT_REQUESTED,
    SUBSCRIBE_APPEVENT_SUCCESS,
    SUBSCRIBE_APPEVENT_FAILURE,
    SET_APP_EVENT_MODAL
} from "../actionTypes";

const initialState = {
    isLoading: false,
    appevents : [],
    appevent: null,
    isFetchAppeventLoading: false,
    isFetchAppeventsLoading : false,
    isSubscribeAppeventLoading : false,
    isCurrentAppeventLoading : false,
    lastAppEventLoadedAt: localStorage.getItem("lastAppEventLoadedAt") ? localStorage.getItem("lastAppEventLoadedAt") : null,
    showAppeventModal : false
};

export default function chatReducer(state = initialState, action) {
    const { type } = action;

    switch (type) {
        
        case FETCH_APPEVENTS_REQUESTED: {
            return {
                ...state,
                isFetchAppeventsLoading: true,
                appevents : [],
            };
        }

        case FETCH_APPEVENTS_SUCCESS: {
            return {
                ...state,
                isFetchAppeventsLoading: false,
                appevents: action.payload,
            };
        }

        case FETCH_APPEVENTS_FAILURE: {
            return {
                ...state,
                isFetchAppeventsLoading: false,
            };
        }

        case FETCH_APPEVENT_DETAILS_REQUESTED: {
            return {
                ...state,
                isCurrentAppeventLoading: true,
                currentAppevent : null,
            };
        }

        case FETCH_APPEVENT_DETAILS_SUCCESS: {
            return {
                ...state,
                isCurrentAppeventLoading: false,
                currentAppevent: action.payload,
            };
        }

        case FETCH_APPEVENT_DETAILS_FAILURE: {
            return {
                ...state,
                isCurrentAppeventLoading: false,
            };
        }

        case FETCH_APPEVENT_REQUESTED: {
            return {
                ...state,
                isFetchAppeventLoading: true,
                appevent : null,
                showAppeventModal : false
            };
        }
        case FETCH_APPEVENT_SUCCESS: {
            const lastAppEventLoadedAt = (new Date()).toISOString()
            localStorage.setItem("lastAppEventLoadedAt", lastAppEventLoadedAt)
            return {
                ...state,
                isFetchAppeventLoading: false,
                appevent: action.payload,
                lastAppEventLoadedAt,
                showAppeventModal : true
            };
        }

        case FETCH_APPEVENT_FAILURE: {
            return {
                ...state,
                isFetchAppeventLoading: false,
                showAppeventModal : false
            };
        }

        case SUBSCRIBE_APPEVENT_REQUESTED: {
            return {
                ...state,
                isSubscribeAppeventLoading: true,
            };
        }
        case SUBSCRIBE_APPEVENT_SUCCESS: {
            return {
                ...state,
                isSubscribeAppeventLoading: false,
                showAppeventModal : false
            };
        }

        case SUBSCRIBE_APPEVENT_FAILURE: {
            return {
                ...state,
                isSubscribeAppeventLoading: false,
            };
        }

        case CLEAR_APPEVENTS : {
            return {
                ...state,
                appevent : null
            }
        }

        case SET_APP_EVENT_MODAL : {
            return {
                ...state,
                showAppeventModal : action.payload
            }
        }

        default:
            return state;
    }
}
