import React from 'react';
import { Navigate } from 'react-router-dom';

export default function PrivateRoute({children}) {

   const userToken = localStorage.getItem("token")
   
   if(!userToken) return <Navigate to="/login" />
   //redirect if there is no user 
   
   return children
}