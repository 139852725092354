import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import {
    fetchMyProfileService,
    loginUserService, 
    updateUniqueMobileIdService, 
    verifyOtpUserService, 
    fetchUserDescriptionRecommendationService,
    updateProfilePreferencesService,
    fetchMyRolesAndResponsibilitiesService,
    deleteUserService,
    updateUserPrivacySettingsService,
    profileRestorePurchaseService
} from "../../services/profile"
import { fetchPlansService } from "../../services/plan"

export const useMyProfile = (settings = {}) => {
    const enabled = settings.hasOwnProperty('enabled') ? settings['enabled'] : true
    const query = useQuery({ queryKey: ['my-profile'], enabled, queryFn: () => fetchMyProfileService() })
    return query
}

export const useMyRolesAndResponsibilities = (settings = {}) => {
    // Stale time of 5 minutes
    const enabled = settings.hasOwnProperty('enabled') ? settings['enabled'] : true
    const query = useQuery({ queryKey: ['my-roles-and-responsibilities'], staleTime : (5*60*1000), enabled, queryFn: () => fetchMyRolesAndResponsibilitiesService() })
    return query
}

export const useAllPlans = (settings = {}) => {
    // Stale time of 15 minutes
    const enabled = settings.hasOwnProperty('enabled') ? settings['enabled'] : true
    const query = useQuery({ queryKey: ['all-plans'], staleTime : (15*60*1000), enabled, queryFn: () => fetchPlansService() })
    return query
}

export const useGetBioRecommendation = (settings={}) => {
    const enabled = settings.hasOwnProperty('enabled') ? settings['enabled'] : true
    const query = useQuery({ queryKey: ['bio-recommendations'], enabled, queryFn: () => fetchUserDescriptionRecommendationService() })
    return query
}

export const useLoginUser = ({ onSuccess }) => {
    const query = useMutation({ queryKey: ['login-user'], onSuccess, mutationFn: (data) => loginUserService(data) })
    return query
}

export const useVerifyOtpUser = ({ onSuccess }) => {
    const query = useMutation({ queryKey: ['verify-otp'], onSuccess, mutationFn: (data) => verifyOtpUserService(data) })
    return query
}

export const useDeleteUser = ({ onSuccess }) => {
    const query = useMutation({ queryKey: ['delete-profile'], onSuccess, mutationFn: () => deleteUserService() })
    return query
}

export const useRestorePurchases = ({ onSuccess }) => {
    const query = useMutation({ queryKey: ['restore-purchases'], onSuccess, mutationFn: () => profileRestorePurchaseService() })
    return query
}

export const useUpdatePrivacySettingsUser = ({ onSuccess }) => {
    const queryClient = useQueryClient();

    const query = useMutation({ queryKey: ['delete-profile'], onSuccess : () => { onSuccess(); queryClient.invalidateQueries("my-profile") }, mutationFn: (data) => updateUserPrivacySettingsService(data) })
    return query
}

export const useUpdateUniqueDeviceId = () => {
    const query = useMutation({ queryKey: ['update-device-id'], mutationFn: (data) => updateUniqueMobileIdService(data) })
    return query
}

export const useUpdateMyProfile = ({ onSuccess }) => {
    const queryClient = useQueryClient()

    const successHandler = (data) => {
        onSuccess()
        queryClient.setQueryData(['my-profile'], (oldData) => {
            return oldData ? {...oldData, ...data} : oldData
        })
    }

    const query = useMutation({ queryKey: ['update-my-profile'], onSuccess : successHandler, mutationFn: (data) => updateProfilePreferencesService(data) })
    return query
}