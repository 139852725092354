import { call, put, takeEvery } from "redux-saga/effects";
import {
    FETCH_SINGLE_PROFILE_REQUESTED,
    RESTORE_PROFILE_PURCHASES_REQUESTED
} from "../actionTypes"

import {
    fetchSingleProfileService,
    profileRestorePurchaseService
} from "../../services/profile"

import {
    fetchSingleProfileSuccess,
    fetchSingleProfileFailure,
    restoreMyProfilePurchasesSuccess,
    restoreMyProfilePurchasesFailure,
} from "../actions";

function* fetchSingleProfileSaga(action) {
    try {
        const profile = yield call(fetchSingleProfileService, action.payload);
        yield put(fetchSingleProfileSuccess(profile.data))
    } catch (e) {
        yield put(fetchSingleProfileFailure(e));
    }
}

function* restoreProfilePurchasesSaga(action){
    try {
        const profile = yield call(profileRestorePurchaseService, action.payload);
        if(profile.success){
            yield put(restoreMyProfilePurchasesSuccess(profile.data))
        }
    } catch (e) {
        yield put(restoreMyProfilePurchasesFailure(e));
    }
}

function* profileSaga() {
    yield takeEvery(FETCH_SINGLE_PROFILE_REQUESTED, fetchSingleProfileSaga);  
    yield takeEvery(RESTORE_PROFILE_PURCHASES_REQUESTED, restoreProfilePurchasesSaga);    
    
}

export default profileSaga;
