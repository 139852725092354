import React from "react";
import "../../assets/css/top-header.scss";

const ProfileProgressBar = ({currentPage, totalPages}) => {
    const percent = (currentPage*100)/totalPages
    return (
        <div className="profile-update-progress-bar">
            <div className="container">
                <div style={{width : `${percent}%`}} className="label">{percent}%</div>
            </div>
        </div>
    );
};

export default ProfileProgressBar;
