import React from "react";
import "../assets/css/navigation.scss";

import { UserIcon, ChatBubbleOvalLeftEllipsisIcon, HeartIcon, ViewColumnsIcon } from '@heroicons/react/24/solid'

import { Link, useLocation } from "react-router-dom";

const Navigation = (props) => {
  const location = useLocation();

  return (
    <div className="navbar">
      <div className="nav-icons">
        <Link to="/allprofiles">
          <ViewColumnsIcon className={`icon ${location.pathname.includes("/allprofiles")
            ? "active"
            : ""}`} />
          {/* <img
            src={
              location.pathname.includes("/allprofiles")
                ? activeProfilesIcon
                : profilesIcon
            }
            
            alt=""
          /> */}
        </Link>
        <Link to="/likesme">
          <HeartIcon className={`icon ${location.pathname.includes("/likesme")
            ? "active"
            : ""}`} />
          {/* <img
            src={
              location.pathname.includes("/likesme")
                ? activeLikesIcon
                : likesIcon
            }
            className="btn"
            alt=""
          /> */}
        </Link>
        <Link to="/matches">
          <ChatBubbleOvalLeftEllipsisIcon className={`icon ${location.pathname.includes("/matches")
            ? "active"
            : ""}`} />
          {/* <img
            src={
              location.pathname.includes("/matches")
                ? activeMessagesIcon
                : messagesIcon
            }
            className="btn"
            alt=""
          /> */}
        </Link>
        <Link to="/myprofile">
          <UserIcon className={`icon ${location.pathname.includes("/myprofile")
            ? "active"
            : ""}`} />
          {/* <img
            src={
              location.pathname.includes("/myprofile")
                ? activeMyprofileIcon
                : myprofileIcon
            }
            className="btn"
            alt=""
          /> */}
        </Link>
      </div>
    </div>
  );
};

export default Navigation;
