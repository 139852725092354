import axios from "axios";
import toast from 'react-hot-toast';
const { authHeader } = require("../utils/auth");

export const fetchAppeventService = (data) => {
  
  return new Promise((resolve, reject) => {
    axios
    .get(`appevent/latest`, {
      headers: {
        ...authHeader(),
      },
    })
    .then((res) => {
      return resolve(res.data)
    })
    .catch((err) => {
      toast.error(err?.response?.data?.msg)
      return reject(err)
    });
  })
};

export const fetchAppeventsService = (data) => {
  
  return new Promise((resolve, reject) => {
    axios
    .get(`appevent`, {
      headers: {
        ...authHeader(),
      },
    })
    .then((res) => {
      return resolve(res.data)
    })
    .catch((err) => {
      toast.error(err?.response?.data?.msg)
      return reject(err)
    });
  })
};

export const subscribeAppeventService = (data) => {
  
  return new Promise((resolve, reject) => {
    axios
    .post(`appevent/subscribe`, data, {
      headers: {
        ...authHeader(),
      },
    })
    .then((res) => {
      return resolve(res.data)
    })
    .catch((err) => {
      toast.error(err?.response?.data?.msg)
      return reject(err)
    });
  })
};

export const fetchAppeventDetailsService = (data) => {
  return new Promise((resolve, reject) => {
    axios
    .get(`appevent/${data.id}`, {
      headers: {
        ...authHeader(),
      },
    })
    .then((res) => {
      return resolve(res.data)
    })
    .catch((err) => {
      toast.error(err?.response?.data?.msg)
      return reject(err)
    });
  })
}

export const fetchNextBlindDateEvent = () => {
  return new Promise((resolve, reject) => {
    axios
    .get(`appevent/blind-date/nextevent`, {
      headers: {
        ...authHeader(),
      },
    })
    .then((res) => {
      return resolve(res.data)
    })
    .catch((err) => {
      toast.error(err?.response?.data?.msg)
      return reject(err)
    });
  })
}