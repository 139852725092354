import { combineReducers } from "redux";
import profile from "./profile";
import like from "./like";
import chat from "./chat";
import payment from "./payment";
import college from "./college";
import event from "./event";
import appevent from "./appevent";
import advertisement from "./advertisement";
import boost from "./boost";
import general from "./general";

export default combineReducers({
  profile,
  like,
  chat,
  payment,
  college,
  event,
  appevent,
  advertisement,
  boost,
  general
});
