import axios from "axios";
import toast from 'react-hot-toast';
const { authHeader } = require("../utils/auth");

export const fetchPlansService = (data) => {
  return new Promise((resolve, reject) => {
    axios
    .get(
      `plan`,
      {
        headers: {
          ...authHeader(),
        },
      }
    )
    .then((res) => {
      if(!res.data.success){
        reject(res.data.data)
      }
      resolve(res.data.data);
    })
    .catch((err) => {
      toast.error(err?.response?.data?.msg || err?.response?.data || err?.response || err)
      reject(err)
    });
  }) 
};