import React, { useCallback, useState } from "react";
// import cameraBtn from "../../assets/images/btn_camera.png";
// import addPhotoBtn from "../../assets/images/btn_add_photo.png";
// import sendBtn from "../../assets/images/btn_send.png";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { debounce } from "../../utils/common";
import { useMyProfile } from "../../react-query/profile/useProfile";
import { appendChatMessage } from "../../store/actions";
import { v4 as uuidv4 } from 'uuid'
import toast from "react-hot-toast";

const InputMsg = ({ socket }) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { matchedUserDetails } = useSelector((state) => state.like);
  const [message, setMessage] = useState("");

  const {
    data: profile,
  } = useMyProfile();

  const sendMessage = () => {
    if(!message || !message.length) return toast.error("Blank message can not be sent")
    const frontend_uuid = uuidv4();

    console.log({
      message,
      to: matchedUserDetails._id,
      matchId: id,
    })
    socket.emit("send-message", {
      message,
      to: matchedUserDetails._id,
      matchId: id,
      frontend_uuid
    });

    dispatch(appendChatMessage({
      frontend_uuid,
      is_pending : true,
      from: profile._id,
      message,
      created_at: new Date(),
      is_read: false,
      to: matchedUserDetails._id,
      match: id,
    }));

    setMessage("");
  };

  const sendEventForTyping = () => {
    socket.emit("typing-msg", {
      matchId: id,
    });
  };

  const optimizedFunction = useCallback(debounce(sendEventForTyping, 2000), [socket]);

  const msgChangeHandler = (e) => {
    setMessage(e.target.value);
    optimizedFunction();
  };

  // const handleKeyPress = (e) => {
  //   if (e.key === "Enter") {
  //     sendMessage();
  //   }
  // };

  return (
    <div className="flex  items-center mx-2 gap-x-0.5 justify-center py-2">
      {/* <div className="flex w-[86%]"> */}
      <div className="flex w-[100%]">
        <span className="inline-flex items-center px-3 text-sm text-gray-900 bg-white rounded-l-[29px]">
          {/* <Plus /> */}
        </span>
        <input
          value={message}
          // onKeyDown={(e) => handleKeyPress(e)}
          onChange={msgChangeHandler}
          type="text"
          className="mr-2 focus:border-transparent border-transparent focus:ring-0 placeholder: text-sm focus:outline-none flex-1 placeholder:text-xs rounded-r-[29px] bg-white text-gray-900 px-2.5 py-2"
          placeholder="Type a message :)"
        />
        <SendIcon onClick={() => sendMessage()} />
      </div>

      {/* <div className="flex items-center justify-center h-[41px] w-[41px] bg-white rounded-full">
              <Mic className="h-[22px] w-[22px] " />
            </div> */}
    </div>
  );
};


const SendIcon = (props) => (
  <svg
    width={41}
    height={41}
    viewBox="0 0 41 41"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <circle cx={20.5} cy={20.5} r={20.5} fill="white" />
    <rect
      x={29}
      y={13}
      width={14}
      height={14}
      transform="rotate(90 29 13)"
      fill="url(#pattern0_1814_767)"
    />
    <defs>
      <pattern
        id="pattern0_1814_767"
        patternContentUnits="objectBoundingBox"
        width={1}
        height={1}
      >
        <use xlinkHref="#image0_1814_767" transform="scale(0.0078125)" />
      </pattern>
      <image
        id="image0_1814_767"
        width={128}
        height={128}
        xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAIAAAACACAMAAAD04JH5AAAAA3NCSVQICAjb4U/gAAAACXBIWXMAAAdbAAAHWwEVi0IEAAAAGXRFWHRTb2Z0d2FyZQB3d3cuaW5rc2NhcGUub3Jnm+48GgAAASBQTFRF////AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA7v9mSAAAAF90Uk5TAAoLDA0ODxAREhMUGUBER0xPW1xdXl9gYWJjZGVmZ2hpamtsbW5vcHFyc3R1dnd4eXp7fH1+f5CSmJ2fpaaoub6/wMHCw8TFxsfIycrLzM3Oz9DR0tbX2Ofs7/H8/f7Rve9dAAACrklEQVR42u2a11bbQBRFx4CLbAjV9N57rymAaaZ3EjDG+v+/yBBIQD5jWWXmKg93P1mjO7O3X7y8tCQEwzAMwzAMwzAMw4QjFovSntx8KBYfNpNR+Zse7T88NkXjbyna7xRbovC3vtj/eGmN1h9FQZvDLwvaaP3ZMj91AfplQZbO367wy4L2aP10BR0V/LKgg8LfWdEvCzqj9VMUdLn6ZUGXYX/JrkLJaEE3+O/voaDbnL8H/ZkMFvRQ+oWgK+gF/13mdT1zBwW9lH6qgj7w36b/3kvfQkGfbn+/i19Z0E/pN18wAP6btHMifQMFA/r8g1X9yoJBg34LpyxjBUPgv7ZUc9Y1FAxp8dve/KoCW0PBMBx6ZVWata5geDisfwT9qcrTKSwYofTrLxiF4y5T7jtSl7BlNLh/zLdfWTCm0e/hWURSW8E4HHTh6VlI8gI2jgfxTwT0KwsmdPjPPT8LSp6HL5gM4VcWTPrzT6E/4Wd/AgumwvnPEv6+QeIsTMF0aL+yYNrr3hnYeurbLwtO4ZgZSn/wgln0x4P9ksWxYLb6rjnYdBLQLwtO4LA5Sn+QgnnYcBzCLwuO4cB5t/kFzX5lwYIvf50ISZ2PgkUYzYf2y4I8HLuonlwy4lcWLHnzH2nxy4IjLwXLxvzKguXymRX01wpt1GLBSjX/oUa/LDh0L1g17FcWrH7cXYObB5r9suAAJGuUfreCdbixXyMMULMPovXX9SyRX1mQFaK+QOVXFRTqxUb52p4xvyzYK7dtiDyhX1GQF8/OhZxRvyzIOX3P4qfTb/x9hJiz4Jf4TusvL/ghvny62iV5HyO2+0nZKMRXYr+j4Ju8jG+/fS5tkb2PEtt6f/i6/faXtzlXsJ92GgQhDTtPdiHX/LGQFuREoGQYhmEYhmEYhmH+T34D/Sg0XzsvtokAAAAASUVORK5CYII="
      />
    </defs>
  </svg>
);

export default InputMsg;
