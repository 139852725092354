import {
    SET_SCREEN_WIDTH,
    SET_SCREEN_HEIGHT,
    SET_SCREEN_SIZE,
    UPDATE_IS_FREE_PRICING
} from "../actionTypes";

const initialState = {
    screenWidth: null,
    screenHeight: null,
    isFreePricing : false
};

export default function generalReducer(state = initialState, action) {
    const { type } = action;

    switch (type) {

        case SET_SCREEN_WIDTH: {
            return {
                ...state,
                screenWidth: action.payload,
            };
        }

        case SET_SCREEN_HEIGHT: {
            return {
                ...state,
                screenHeight: action.payload,
            };
        }

        case SET_SCREEN_SIZE: {
            return {
                ...state,
                screenWidth: action.payload.screenWidth,
                screenHeight: action.payload.screenHeight,
            };
        }

        case UPDATE_IS_FREE_PRICING : {
            return {
                ...state,
                isFreePricing : action.payload
            }
        }


        default:
            return state;
    }
}
