import React, { useEffect } from "react";
import "../assets/css/event-detail.scss";
import Tag from "../components/AllProfiles/Tag";
import calenderIcon from "../assets/images/calender.svg";
import backArrowIcon from "../assets/images/backArrow.svg";
import shareIcon from "../assets/images/shareIcon.svg";
import locationIcon from "../assets/images/location.svg";
import yellowTickIcon from "../assets/images/yellowtick.svg";
import { fetchAppeventDetailsRequested, subscribeAppeventRequested } from "../store/actions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";

const EventDetails = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { id } = useParams();

  const {currentAppevent} = useSelector(state => state.appevent)

  useEffect(() => {
    dispatch(fetchAppeventDetailsRequested({ id }))
  }, [])
  return (
    <div className="event-detail">
      <div className="top">
        <div className="buttons">
          <div className="back btn">
            <img src={backArrowIcon} onClick={() => navigate("/events")} alt="Back to Events" />
          </div>
          <div className="share btn">
            <img src={shareIcon} alt="" />
          </div>
        </div>
        <div className="img">
          <img src={currentAppevent?.photos[0]} alt="" />
        </div>
      </div>
      <div className="bottom">
        <div className="content">
          <div className="top_content">
            <Tag title="date night" />
            <div className="title">
              {currentAppevent?.title}
            </div>
            <div className="date_location">
              <div className="date">
                <img src={calenderIcon} alt="" />
                <p className="text">Thu 09 march 2023</p>
              </div>
              <div className="location">
                <img src={locationIcon} alt="" />
                <p className="text">Indian night club, Delhi</p>
              </div>
            </div>
            <a href="#" className="link view_location">
              View on maps
            </a>
          </div>
          <div className="line"></div>
          <div className="middle_content">
            <div className="title">About the event</div>
            <div className="subtitle">{currentAppevent?.description}</div>
          </div>
          <div className="line"></div>
          <div className="bottom_content">
            <div className="title">Features</div>
            <div className="points">
            <div className="point">
                    <img src={yellowTickIcon} alt="" />
                    <p className="text">Lorem ipsum dolor sit amet consectetur.</p>
                </div>
                <div className="point">
                    <img src={yellowTickIcon} alt="" />
                    <p className="text">Lorem ipsum dolor sit amet consectetur.</p>
                </div>
                <div className="point">
                    <img src={yellowTickIcon} alt="" />
                    <p className="text">Lorem ipsum dolor sit amet consectetur.</p>
                </div>
                <div className="point">
                    <img src={yellowTickIcon} alt="" />
                    <p className="text">Lorem ipsum dolor sit amet consectetur.</p>
                </div>
            </div>
          </div>
        </div>
      </div>
      <div onClick={() => dispatch(subscribeAppeventRequested({ event: id }))} className="event_button">Subscribe</div>
    </div>
  );
};

export default EventDetails;
