export const FETCH_CHAT_MSGS_REQUESTED = "FETCH_CHAT_MSGS_REQUESTED";

export const FETCH_CHAT_MSGS_FAILURE = "FETCH_CHAT_MSGS_FAILURE";

export const FETCH_CHAT_MSGS_SUCCESS = "FETCH_CHAT_MSGS_SUCCESS";

export const UPDATE_CHAT_MSGS_READ_REQUESTED =
  "UPDATE_CHAT_MSGS_READ_REQUESTED";

export const UPDATE_CHAT_MSGS_READ_SUCCESS = "UPDATE_CHAT_MSGS_READ_SUCCESS";

export const UPDATE_CHAT_MSGS_READ_FAILURE = "UPDATE_CHAT_MSGS_READ_FAILURE";

export const EXTEND_CHAT_REQUESTED = "EXTEND_CHAT_REQUESTED";

export const EXTEND_CHAT_SUCCESS = "EXTEND_CHAT_SUCCESS";

export const EXTEND_CHAT_FAILURE = "EXTEND_CHAT_FAILURE";

export const APPEND_CHAT_MSG = "APPEND_CHAT_MSG";

export const MARK_MSGS_READ = "MARK_MSGS_READ"

export const RESET_CHATS = "RESET_CHATS"

export const SHOW_CHAT_LIMIT_EXCEEDED = "SHOW_CHAT_LIMIT_EXCEEDED"