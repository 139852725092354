import React, { useCallback, useRef } from "react";
import Navigation from "../components_tailwindcss/Navigation";
import { useState } from "react";
import "../assets/css/bg-animation.scss";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  // updateProfileFilters,
  // fetchAdvertisementsRequested,
  updateCurrentProfileIndex,
  toggleShowErrorShowNextProfileModal,
} from "../store/actions";
import Loader from "../common/loader";
import EmptyState from "../common/emptyState";
import ProfileDetails from "../components_tailwindcss/Reels/RemotionProfileReel";
import AudioPlayer from "../components_tailwindcss/Reels/AudioPlayer";
import ProfileFilters from "../components_tailwindcss/Reels/ProfileFilters";
import ReportProfileModal from "../components_tailwindcss/Reels/ReportProfileModal";
import ErrorNextPageProfileModal from "../components_tailwindcss/Reels/ErrorNextPageProfileModal";
import { useLikesMe } from "../react-query/like/useLike";
import ReelsPageHeader from "../components_tailwindcss/Reels/ReelsPageHeader";
import FullPageLoader from "../components_tailwindcss/Reels/FullPageLoader";

export default function ReelsPage() {
  const dispatch = useDispatch();

  const [filter] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isFetchingMore, setIsFetchingMore] = useState(false);
  const [profiles, setProfiles] = useState([]);

  const loopRef = useRef(null);
  const loopSwiperContainerRef = useRef(null);
  const userRatingsRef = useRef({});

  const {
    profileFilters,
    currentIndex,
    reportProfileModalActiveId,
    showErrorShowNextProfileModal,
  } = useSelector((state) => state.like);

  const { filterModalActive } = useSelector((state) => state.profile);

  const { refetch: refetchRandomProfiles } = useLikesMe({
    enabled: false,
    filters: profileFilters,
  });

  const canMoveToNextProfile = useCallback(
    (activeIndex) => {
      // Move to the next profile only if all the previous profiles have been rated successfully
      console.log({ canMoveToNextProfile: activeIndex });
      return profiles
        .slice(0, activeIndex)
        .every((profile) => userRatingsRef.current[profile._id] !== undefined);
    },
    [profiles]
  );

  const fetchMoreProfiles = useCallback(async () => {
    if (isFetchingMore) return;

    try {
      setIsFetchingMore(() => true);

      const result = await refetchRandomProfiles();
      if (result.data && result.data.profiles) {
        setProfiles((prevProfiles) => {
          // Create a Set of existing profile IDs for efficient lookup
          const existingProfileIds = new Set(
            prevProfiles.map((profile) => profile._id)
          );

          // Filter out duplicates from the new profiles
          const uniqueNewProfiles = result.data.profiles.filter(
            (profile) => !existingProfileIds.has(profile._id)
          );

          // Combine previous profiles with unique new profiles
          const updatedProfiles = [...prevProfiles, ...uniqueNewProfiles];

          // Create a Set of all profiles to remove any potential duplicates
          const uniqueProfiles = Array.from(
            new Set(updatedProfiles.map(JSON.stringify))
          ).map(JSON.parse);

          return uniqueProfiles;
        });
      }
    } catch (error) {
      console.error("Error fetching more profiles:", error);
    } finally {
      setIsFetchingMore(() => false);
      setIsLoading(false);
    }
  }, []);

  const checkIfShowProfile = () => {
    return true;
  };

  const handleRating = useCallback((profile_id, rating) => {
    userRatingsRef.current = {
      ...userRatingsRef.current,
      [profile_id]: rating,
    };
  }, []);

  useEffect(() => {
    dispatch(updateCurrentProfileIndex(0));
    fetchMoreProfiles(); // Initial fetch
  }, [dispatch, fetchMoreProfiles]);

  useEffect(() => {
    const initializeSwiper = () => {
      if (
        loopSwiperContainerRef.current &&
        loopSwiperContainerRef.current.swiper
      ) {
        console.log("Swiper is initialized");
        const swiper = loopSwiperContainerRef.current.swiper;

        swiper.on("slideChange", (e) => {
          console.log(
            "Changed",
            e.activeIndex,
            canMoveToNextProfile(swiper.activeIndex)
          );

          if (!canMoveToNextProfile(swiper.activeIndex)) {
            console.log("Cannot move to the next profile, current index: ");
            swiper.slideTo(swiper.activeIndex - 1);
            dispatch(toggleShowErrorShowNextProfileModal(true));
            return;
          }

          dispatch(updateCurrentProfileIndex(e.activeIndex));

          if (e.activeIndex === profiles.length - 1) {
            fetchMoreProfiles();
          }
        });
      } else {
        console.log("Swiper is not initialized");
      }
    };

    console.log("Swiper is initialized UseEffect");
    const swiperElement = loopSwiperContainerRef.current;
    if (swiperElement) {
      // Check if the swiper is already initialized
      if (swiperElement.swiper) {
        initializeSwiper();
      } else {
        // If not, wait for the swiper:load event
        const handleSwiperLoad = () => {
          initializeSwiper();
          swiperElement.removeEventListener("swiper:load", handleSwiperLoad);
        };
        swiperElement.addEventListener("swiper:load", handleSwiperLoad);
      }
    }

    return () => {
      // Cleanup
      if (swiperElement) {
        swiperElement.removeEventListener("swiper:load", initializeSwiper);
      }
    };
  }, [dispatch, profiles]);

  useEffect(() => {
    // Whenver profile changes, reinitialize the swiper
    if (profiles.length && loopSwiperContainerRef.current) {
      console.log("Updating swiper");
      // loopSwiperContainerRef.current.swiper.update();
      loopSwiperContainerRef.current.swiper.virtual.update();
    }
  }, [profiles]);

  return (
    <>
      <div className="bg-animation flex-col bg-white w-full w-full h-screen h-max">
        {filterModalActive ? <ProfileFilters /> : null}

        <ReelsPageHeader type={"likedyou"} />

        {isLoading ? (
          <div
            style={{ height: "calc(100vh - 140px)" }}
            className={`flex flex-1 overflow-y-scroll py-3 flex-col justify-center items-center gap-y-5`}
          >
            <Loader color="#e8649a" />
          </div>
        ) : profiles && profiles.length ? (
          <>
            {reportProfileModalActiveId ? <ReportProfileModal /> : null}
            {showErrorShowNextProfileModal ? (
              <ErrorNextPageProfileModal />
            ) : null}

            <div
              ref={loopRef}
              style={{ height: "calc(100vh - 140px)" }}
              id="Reels"
              className={`flex flex-1 ${
                filter ? "blur" : ""
              } overflow-y-scroll flex-col gap-y-5`}
            >
              <swiper-container
                ref={loopSwiperContainerRef}
                direction="vertical"
                slider-per-view="1"
                virtual={{
                  enabled: true,
                  slides: profiles,
                }}
                lazy={{
                  loadPrevNext: true,
                  loadPrevNextAmount: 2,
                }}
              >
                {profiles.map((profile, profileIndex) => {
                  return (
                    <swiper-slide
                      key={`all-${checkIfShowProfile() ? "P" : "A"}-${
                        profile._id
                      }`}
                      virtualIndex={profileIndex}
                    >
                      <ProfileDetails
                        profile={profile}
                        profileIndex={profileIndex}
                        advertisements={[]}
                        onRate={(rating) => handleRating(profile._id, rating)}
                        active={currentIndex}
                      />
                    </swiper-slide>
                  );
                })}
              </swiper-container>
            </div>
            {isFetchingMore && <FullPageLoader />}
          </>
        ) : (
          <>
            <div
              className="flex items-center"
              style={{ height: "calc(100vh - 140px)" }}
            >
              <EmptyState label={"Stack is empty ☹️"} />
            </div>
          </>
        )}
        <Navigation />
      </div>
    </>
  );
}
