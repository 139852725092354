import React, { useEffect, useState, useCallback } from "react";
import { useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import Navigation from "../components_tailwindcss/Navigation";
import { ReactComponent as Match } from "../assets/img/Delivery/matches.svg";
import { showImage } from "../utils/profile";
import { useNavigate } from "react-router-dom";
import { formatDate } from "../utils/common";
import { useMyMatches } from "../react-query/like/useLike";
import { trackEventRequested } from "../store/actions";
import { useMyRolesAndResponsibilities } from "../react-query/profile/useProfile";
import Loader from "../common/loader";
import noPicIcon from "../assets/images/icons/matches/no-pic.webp";
import { getSocket } from "../utils/socket";
import { useQueryClient } from "@tanstack/react-query";

export default function Matches() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const socket = getSocket();
  const queryClient = useQueryClient();
  const [chatType, setChatType] = useState("MATCH"); // Can be GENERAL or MATCH

  const [searchParams, setSearchParams] = useSearchParams();
  const [typingUsers, setTypingUsers] = useState({});
  const {
    isLoading,
    data: matchesData,
    refetch: refetchMyMatches,
  } = useMyMatches(chatType);
  const {
    data: rolesAndResponsibilities,
    refetch: refetchMyRolesAndResponsibilities,
  } = useMyRolesAndResponsibilities();

  const updateRecentMessage = useCallback((matchId, message) => {
    queryClient.setQueryData(['my-matches', chatType], (oldData) => {
      if (!oldData) return oldData;

      console.log("message", message)
      const newMatches = oldData.matches.map(match => 
        match._id === matchId 
          ? { ...match, recent_message: { message: message.message, is_read: false, from: message.from } }
          : match
      );

      return {
        ...oldData,
        matches: newMatches,
      };
    });
  }, [chatType, queryClient])

  const openChat = (id) => {
    navigate(`/chat/${id}`);
  };

  const refreshPage = () => {
    dispatch(
      trackEventRequested({
        eventName: "REFRESH_PAGE",
        eventBody: { page: "myMatches" },
      })
    );
    refetchMyMatches();
    refetchMyRolesAndResponsibilities();
  };

  const updateTypingStatus = useCallback((matchId) => {
    setTypingUsers(prev => ({ ...prev, [matchId]: !prev[matchId] }));
  }, []);

  let numMatches = 0,
    matches = [];
  if (matchesData) {
    numMatches = matchesData.num_matches;
    matches = matchesData.matches;
  }

  useEffect(() => {
    if (searchParams.get("new")) {
      refetchMyMatches();
    }
  }, []);

  useEffect(() => {
    refetchMyMatches();
  }, [chatType]);

  useEffect(() => {
    if (!socket) return;

    // Join all match rooms
    matches.forEach((match) => {
      socket.emit("join-room", { matchId: match._id }, (data) => {
        console.log(`Match room joined successfully: ${match._id}`);
      });
    });

    // Listen for typing events
    socket.on("typing-event", ({ matchId }) => {
      updateTypingStatus(matchId);
    });

    socket.on("receive-message", ({ chat: message }) => {
      updateRecentMessage(message.match, message);
    });

    return () => {
      // Leave all match rooms when component unmounts
      matches.forEach((match) => {
        socket.emit("leave-room", { matchId: match._id });
      });
      socket.off("typing-event");
      socket.off("receive-message");
    };
  }, [socket, matches, updateTypingStatus, chatType, updateRecentMessage]);

  console.log("Typing Users", typingUsers)

  return (
    <div className="flex flex-col  bg-white w-full w-full h-screen h-max">
      <div className="px-5 mt-5 w-full flex justify-between items-center">
        <p className=" text-[#353D44] text-2xl font-bold">Chats</p>
        <div className="flex gap-4">
          {/* <InfoIcon className="cursor-pointer h-4 w-4" /> */}
          <RefreshIcon
            onClick={() => refreshPage()}
            className="cursor-pointer h-4 w-4"
          />
        </div>
      </div>

      {isLoading ? (
        <div className="overflow-scroll px-5 flex mt-10 flex-1 flex-col items-center justify-center">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "inherit",
            }}
          >
            <Loader color="#FF6584" />
          </div>
        </div>
      ) : !rolesAndResponsibilities?.gate?.can_chat ? (
        // Free User
        <div className="overflow-scroll px-5 flex mt-10 flex-1 flex-col items-center justify-center">
          <Match className="w-[167px] mb-3" />
          <p className="mb-2 text-[#353D44] text-3xl font-bold text-center">
            {numMatches
              ? "Maalik, aagey ka kya socha hai?"
              : "Haa thoda dard hua, par chalta hai!"}
          </p>

          <p className="text-[#353D44] text-xl font-semibold text-center mb-2">
            You can now chat with{" "}
            <span className="text-purple-800 font-bold">{numMatches}</span>{" "}
            people
          </p>
          <p className="text-center pb-4  font-bold text-[12px] text-[#b3b3b3]">
            Upgrade to start talking ❤️
            <br />
          </p>
          <button
            onClick={() => navigate("/plans/PRO")}
            className="text-white  bg-black font-bold rounded-full py-2 px-5 text-sm"
          >
            Try Premium
          </button>
        </div>
      ) : (
        <div className="overflow-scroll px-5 flex mt-10 flex-1 flex-col items-center">
          <div className="flex justify-around items-center w-full mb-3">
            <div className="flex w-full gap-2">
              <button
                onClick={() => setChatType("MATCH")}
                className={`inline-flex gap-2 items-center py-1 px-3 text-xs rounded-lg border border-black ${
                  chatType === "MATCH"
                    ? "bg-[#1A2F38] text-white"
                    : "border border-black"
                }`}
              >
                <CircleIcon
                  className={
                    chatType === "MATCH" ? "stroke-white" : "stroke-[#1A2F38]"
                  }
                />
                It's a Match
              </button>
              <button
                onClick={() => setChatType("GENERAL")}
                className={`inline-flex gap-2 items-center py-1 px-3 border text-xs rounded-lg border border-black ${
                  chatType === "GENERAL"
                    ? "bg-[#1A2F38] text-white"
                    : "border border-black"
                }`}
              >
                <CircleIcon
                  className={
                    chatType === "GENERAL" ? "stroke-white" : "stroke-[#1A2F38]"
                  }
                />
                General
              </button>
            </div>
          </div>

          {!numMatches ? (
            <div className="overflow-scroll px-5 flex mt-10 flex-1 flex-col items-center justify-center">
              <Match className="w-[167px] mb-3" />
              <p className="mb-2 text-[#353D44] text-3xl font-bold">
                No Chats Yet
              </p>
              <p className="text-center pb-4  font-bold text-[12px]">
                Kuch nhi badla yaar, aaj bhi sabkuch vaise ka vaisa hi hai
              </p>
            </div>
          ) : (
            <>
              {matches && matches.length
                ? matches.map((match) => {
                    const user = match[match.key];
                    return user._id !== searchParams.get("hideUser") ? (
                      <div
                        key={match._id}
                        onClick={() => openChat(match._id)}
                        className="cursor-pointer w-full my-2 flex items-center justify-center"
                      >
                        <div className="overflow-hidden w-[40px] h-[40px] rounded-lg">
                          <img
                            src={
                              match.is_blind_match
                                ? noPicIcon
                                : showImage(user.photos[0], "thumbnailsm")
                            }
                            alt="dp"
                            className="w-[40px] h-[40px] h-full w-full object-cover"
                          />
                        </div>
                        <div className="flex flex-1 pl-2 flex-col items-start justify-center">
                          <p className=" font-semibold text-md">
                            {user.final_name}
                          </p>
                          <p
                            className={` text-[#0000004d] text-[13px] overflow-hidden whitespace-nowrap text-ellipsis ${
                              !match.recent_message?.is_read &&
                              user._id === match.recent_message?.from
                                ? "font-bold"
                                : "font-medium"
                            }`}
                            style={{ width: "calc(100% - 20px)" }}
                          >
                            {typingUsers[match._id] ? (
                              <span className="italic">Typing...</span>
                            ) : match.recent_message ? (
                              match.recent_message.message
                            ) : (
                              "Start a conversation"
                            )}
                          </p>
                        </div>
                        {!match.recent_message?.is_read &&
                        user._id === match.recent_message?.from ? (
                          <p className="flex justify-center items-center bg-[#353D44] text-[14px]  font-medium text-white h-[27px] w-[27px] text-center rounded-full">
                            1
                          </p>
                        ) : null}
                      </div>
                    ) : null;
                  })
                : null}
            </>
          )}
        </div>
      )}

      <Navigation />
    </div>
  );
}

const InfoIcon = (props) => (
  <svg
    width={258}
    height={258}
    viewBox="0 0 258 258"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M129 0C57.7597 0 0 57.7597 0 129C0 200.24 57.7597 258 129 258C200.24 258 258 200.24 258 129C258 57.7597 200.24 0 129 0ZM139.75 193.5H118.25V118.25H139.75V193.5ZM129 91.375C120.099 91.375 112.875 84.151 112.875 75.25C112.875 66.349 120.099 59.125 129 59.125C137.901 59.125 145.125 66.349 145.125 75.25C145.125 84.151 137.901 91.375 129 91.375Z"
      fill="black"
    />
  </svg>
);

const RefreshIcon = (props) => (
  <svg
    width={212}
    height={212}
    viewBox="0 0 212 212"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M106.001 0C52.236 0 7.77329 40.0488 1.00066 91.8197C0.809296 92.9838 0.855008 94.1742 1.13508 95.3203C1.41514 96.4664 1.92384 97.5447 2.63092 98.4911C3.33799 99.4374 4.229 100.233 5.25094 100.829C6.27288 101.426 7.40488 101.811 8.5796 101.964C9.75432 102.116 10.9478 102.031 12.089 101.715C13.2301 101.398 14.2958 100.856 15.2224 100.122C16.1491 99.3867 16.9178 98.4739 17.483 97.4372C18.0481 96.4006 18.3981 95.2614 18.5122 94.0873C24.1593 50.9201 61.0257 17.5909 106.001 17.5909C130.442 17.5909 152.381 27.517 168.352 43.4619L150.168 61.5682L203.168 70.3636L194.335 17.5909L180.826 31.0418C161.657 11.9402 135.233 0 106.001 0ZM202.392 109.222C200.203 109.19 198.081 109.969 196.436 111.407C194.792 112.844 193.741 114.839 193.489 117.004C187.842 160.171 150.976 193.5 106.001 193.5C79.3053 193.5 55.6393 181.628 39.4397 163.025L53.0003 149.523L0 140.727L8.83339 193.5L26.9315 175.48C46.3335 197.218 74.519 211.091 106.001 211.091C159.765 211.091 204.228 171.042 211.001 119.271C211.18 118.034 211.092 116.773 210.743 115.573C210.394 114.372 209.792 113.259 208.978 112.308C208.163 111.358 207.155 110.59 206.019 110.058C204.883 109.526 203.647 109.241 202.392 109.222Z"
      fill="black"
    />
  </svg>
);

const CircleIcon = (props) => (
  <svg
    width={10}
    height={9}
    viewBox="0 0 10 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={5.23802} cy={4.48606} r={3.8513} />
  </svg>
);
