import {
    FETCH_RANDOM_PROFILES_REQUESTED,
    FETCH_RANDOM_PROFILES_SUCCESS,
    FETCH_RANDOM_PROFILES_FAILURE,
    UPDATE_PROFILE_DECISION_REQUESTED,
    UPDATE_PROFILE_DECISION_SUCCESS,
    UPDATE_PROFILE_DECISION_FAILURE,
    FETCH_MATCHED_USER_DETAILS_SUCCESS,
    UPDATE_MATCH_DETAILS,
    FETCH_MY_BLIND_MATCH_REQUESTED,
    FETCH_MY_BLIND_MATCH_FAILURE,
    FETCH_MY_BLIND_MATCH_SUCCESS,
    UPDATE_STREAK_MODAL_ACTIVE,
    UPDATE_CACHED_PROFILE_IMAGES,
    TOGGLE_REPORT_PROFILE_MODAL,
    UPDATE_CURRENT_PROFILE_INDEX,
    TOGGLE_MUTE_MUSIC,
    TOGGLE_SHOW_ERROR_SHOW_NEXT_PROFILE_MODAL,
} from "../actionTypes"

export const fetchRandomProfilesRequested = ({filters={}, isLoading=true}) => {
    return {
        type : FETCH_RANDOM_PROFILES_REQUESTED,
        payload : {filters, isLoading}
    }
}

export const fetchRandomProfilesSuccess = (data) => {
    return {
        type : FETCH_RANDOM_PROFILES_SUCCESS,
        payload : data
    }
}

export const fetchRandomProfilesFailure = (data) => {
    return {
        type : FETCH_RANDOM_PROFILES_FAILURE,
        payload : data
    }
}

export const updateProfileDecisionRequested = (data) => {
    return {
        type : UPDATE_PROFILE_DECISION_REQUESTED,
        payload : data
    }
}

export const updateProfileDecisionSuccess = (data) => {
    return {
        type : UPDATE_PROFILE_DECISION_SUCCESS,
        payload : data
    }
}

export const updateProfileDecisionFailure = (data) => {
    return {
        type : UPDATE_PROFILE_DECISION_FAILURE,
        payload : data
    }
}

export const fetchMatchedUserDetailsSuccess = (data) => {
    return {
        type : FETCH_MATCHED_USER_DETAILS_SUCCESS,
        payload : data
    }
}

export const updateMatchDetails = (data) => {
    return {
        type : UPDATE_MATCH_DETAILS,
        payload : data
    }
}

export const fetchMyBlindMatchRequested = (data) => {
    return {
        type : FETCH_MY_BLIND_MATCH_REQUESTED,
        payload : data
    }
}

export const fetchMyBlindMatchSuccess = (data) => {
    return {
        type : FETCH_MY_BLIND_MATCH_SUCCESS,
        payload : data
    }
}

export const fetchMyBlindMatchFailure = (data) => {
    return {
        type : FETCH_MY_BLIND_MATCH_FAILURE,
        payload : data
    }
}

export const updateStreakModalActive = (data) => {
    return {
        type : UPDATE_STREAK_MODAL_ACTIVE,
        payload : data
    }
}

export const updateCachedProfileImages = (data) => {
    return {
        type : UPDATE_CACHED_PROFILE_IMAGES,
        payload : data
    }
}

export const toggleReportProfileModal = (data) => {
    return {
        type : TOGGLE_REPORT_PROFILE_MODAL,
        payload : data
    }
}

export const updateCurrentProfileIndex = (data) => {
    return {
        type : UPDATE_CURRENT_PROFILE_INDEX,
        payload : data
    }
}

export const toggleMuteMusic = (data) => {
    return {
        type : TOGGLE_MUTE_MUSIC,
        payload : data
    }
}

export const toggleShowErrorShowNextProfileModal = (data) => {
    return {
        type : TOGGLE_SHOW_ERROR_SHOW_NEXT_PROFILE_MODAL,
        payload : data
    }
}