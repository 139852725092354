import React, { useState, useCallback } from "react";
import DotLoader from "../../components/General/Loaders/DotLoader";
import { ReactComponent as Plus } from "../../assets/img/icons/plus.svg";
import { uploadFile } from "../../services/upload";
import toast from "react-hot-toast";

function UploadPhoto({ index, url, setPhotos, photos, setIsFileUploading }) {
  const [isLoading, setIsLoading] = useState(false);

  const getFilePath = useCallback((path) => {
    return path ? `${process.env.REACT_APP_FILE_BASE_URL}${path}` : '';
  }, []);

  const toggleLoading = useCallback((stat) => {
    setIsFileUploading(stat);
    setIsLoading(stat);
  }, [setIsFileUploading]);

  const handleUpload = useCallback(async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    if (!file.type.includes("image/")) {
      return toast.error("We allow only images to be uploaded");
    }

    toggleLoading(true);

    try {
      const data = await uploadFile({ file });
      setPhotos(prevPhotos => {
        const newPhotos = [...prevPhotos];
        newPhotos[index] = data.filePath;
        return newPhotos;
      });
    } catch (error) {
      console.error('Upload error:', error);
      toast.error(error.message || "An error occurred while uploading the image");
    } finally {
      toggleLoading(false);
    }
  }, [index, setPhotos, toggleLoading]);

  return (
    <div
      style={{ backgroundImage: `url(${getFilePath(url)})` }}
      className="object-contain bg-cover bg-center bg-no-repeat flex rounded-[18px] aspect-9/16 h-[178px] bg-white relative justify-center items-center flex-1 flex-grow-1"
    >
      <input
        type="file"
        accept="image/png,image/jpeg,image/jpg"
        className="absolute top-0 left-0 w-full h-full opacity-0"
        onChange={handleUpload}
      />
      {isLoading ? (
        <DotLoader />
      ) : !url ? (
        <Plus />
      ) : null}
    </div>
  );
}

export default React.memo(UploadPhoto);