export const FETCH_APPEVENT_REQUESTED = "FETCH_APPEVENT_REQUESTED"

export const FETCH_APPEVENT_SUCCESS = "FETCH_APPEVENT_SUCCESS"

export const FETCH_APPEVENT_FAILURE = "FETCH_APPEVENT_FAILURE"

export const FETCH_APPEVENT_DETAILS_REQUESTED = "FETCH_APPEVENT_DETAILS_REQUESTED"

export const FETCH_APPEVENT_DETAILS_SUCCESS = "FETCH_APPEVENT_DETAILS_SUCCESS"

export const FETCH_APPEVENT_DETAILS_FAILURE = "FETCH_APPEVENT_DETAILS_FAILURE"

export const FETCH_APPEVENTS_REQUESTED = "FETCH_APPEVENTS_REQUESTED"

export const FETCH_APPEVENTS_SUCCESS = "FETCH_APPEVENTS_SUCCESS"

export const FETCH_APPEVENTS_FAILURE = "FETCH_APPEVENTS_FAILURE"

export const CLEAR_APPEVENTS = "CLEAR_APPEVENTS"

export const SUBSCRIBE_APPEVENT_REQUESTED = "SUBSCRIBE_APPEVENT_REQUESTED"

export const SUBSCRIBE_APPEVENT_SUCCESS = "SUBSCRIBE_APPEVENT_SUCCESS"

export const SUBSCRIBE_APPEVENT_FAILURE = "SUBSCRIBE_APPEVENT_REQUESTED"

export const SET_APP_EVENT_MODAL = "SET_APP_EVENT_MODAL"