import {
    CREATE_PAYMENT_ORDER_REQUESTED,
    CREATE_PAYMENT_ORDER_SUCCESS,
    CREATE_PAYMENT_ORDER_FAILURE,
    UPDATE_PLANS_AND_PACKAGES,
    TOGGLE_UPGRADE_PLAN_MODAL
} from "../actionTypes"
  
const initialState = {
    paymentLink : null,
    isOrderLoading : false,
    err : "",
    offerings : null,
    currentUpgradePlanModalType : null
};
  
export default function profileReducer(state = initialState, action) {
    const { type } = action;
  
    switch (type) {
        case CREATE_PAYMENT_ORDER_REQUESTED: {
            return {
                ...state,
                isOrderLoading : true,
                paymentLink : null
            };
        }

        case CREATE_PAYMENT_ORDER_SUCCESS: {
            return {
                ...state,
                isOrderLoading : false,
                paymentLink : action.payload
            };
        }

        case CREATE_PAYMENT_ORDER_FAILURE: {
            return {
                ...state,
                isOrderLoading : false,
                err : action.payload
            };
        }

        case UPDATE_PLANS_AND_PACKAGES: {
            return {
                ...state,
                offerings : action.payload
            }
        }

        case TOGGLE_UPGRADE_PLAN_MODAL : {
            return {
                ...state,
                currentUpgradePlanModalType : action.payload
            }
        }

      default:
        return state;
    }
}
  