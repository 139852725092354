import React, { useEffect, useState, useCallback } from 'react';
import { useCurrentFrame, useVideoConfig, Audio } from 'remotion';

const RobustAudioLoader = ({ src, muted = false }) => {
  const [audioBuffer, setAudioBuffer] = useState(null);
  const [error, setError] = useState(null);
  const frame = useCurrentFrame();
  const { fps } = useVideoConfig();

  const loadAudio = useCallback(async () => {
    try {
      const response = await fetch(src);
      const arrayBuffer = await response.arrayBuffer();
      const audioContext = new (window.AudioContext || window.webkitAudioContext)();
      const decodedAudio = await audioContext.decodeAudioData(arrayBuffer);
      setAudioBuffer(decodedAudio);
    } catch (err) {
      console.error('Failed to load audio:', err);
      setError(err);
    }
  }, [src]);

  useEffect(() => {
    loadAudio();
  }, [loadAudio]);

  if (error) {
    console.error('Audio loading error:', error);
    return null;
  }

  if (!audioBuffer) {
    return null;
  }

  // Convert current frame to seconds
  const startFrom = frame / fps;

  return (
    <Audio
      src={src}
      startFrom={startFrom}
      muted={muted}
    />
  );
};

export default React.memo(RobustAudioLoader);